import React, { createContext, useState } from 'react'
import { ModeloQRCode } from '../service/QrCode'
import { IFormStore } from './interfaces/IFormStore'

const initalState = {
  modeloQrCode: null,
  setModeloQrCode: () => { },
  qrCodeInfo: null,
  setQrCodeInfo: () => { },
}


export const FormContext = createContext<IFormStore>(initalState)
export default function FormProvider({ children }: { children: React.ReactNode }) {
  const [modeloQrCode, setModeloQrCode] = useState<ModeloQRCode | null>(null)
  const [qrCodeInfo, setQrCodeInfo] = useState<ModeloQRCode | null>(null)

  const store = {
    modeloQrCode,
    setModeloQrCode,
    qrCodeInfo,
    setQrCodeInfo
  }

  return (
    <FormContext.Provider value={store}>
      {children}
    </FormContext.Provider>
  )
}