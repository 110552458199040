import { useEffect, useState } from "react";
import { StyledDiv } from "../TableHelpers/TableStyle";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { tableOptions, getMuiTheme } from "../TableHelpers/options";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import Tickets, { TicketsType } from "../../service/Tickets";
import { ThemeProvider } from '@mui/material/styles';
import { AxiosError } from "axios";
import Table from "../Skeletons/Table";

const ticketsService = new Tickets();

export default function AllTickets() {
  const [tickets, setTickets] = useState<TicketsType[]>();
  const { t } = useTranslation('translation');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['GET_ALL_TICKETS_GESTOR'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await ticketsService.getAllGestor(token)
        return response.todosChamados
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  })

    const translatedTextLabels: MUIDataTableOptions = {
      textLabels: {
        body: {
          noMatch: `${t('table.noMatch')}`,
          toolTip: `${t('table.toolTip')}`,
          columnHeaderTooltip: column =>
            `${t('table.columnHeaderTooltip')} ${column.label}`
        },
        pagination: {
          next: `${t('table.next')}`,
          previous: `${t('table.previous')}`,
          rowsPerPage: `${t('table.rowsPerPage')}`,
          displayRows: `${t('table.displayRows')}`
        },
        toolbar: {
          search: `${t('table.search')}`,
          filterTable: `${t('table.filterTable')}`,
          viewColumns: 'Mostrar/Ocultar colunas'
        },
        filter: {
          title: `${t('table.title')}`,
          reset: `${t('table.reset')}`,
        },
        viewColumns: {
          title: `${t('table.viewColumnsTitle')}`,
        }
      }
    }

  useEffect(() => {
    if (data) {
      setTickets(data)
    }
  }, [data])

  const columns = [
    {
      name: "inicio",
      label: t('notifications.ticket.createdAt'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => (
          new Date(value).toLocaleString('pt-BR')
        ),
      }
    },
    {
      name: 'usuarioNome',
      label: t('notifications.unread.user'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => (
          value.startsWith('anonimo') ? `${t('notifications.ticket.anonymous')}` :
            value
        ),
      }
    },
    {
      name: 'nome',
      label: t('notifications.ticket.tickets'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: "dataPrevisao",
      label: t('notifications.ticket.limitDate'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => (
          value ?
            new Date(value).toLocaleString('pt-BR') : ''
        ),
      }
    },
    {
      name: "status",
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => {
          switch (value) {
            case 'emandamento':
              return (
                <span className="tag is-info">{t('notifications.statusProgress')}</span>
              )
            case 'pendente':
              return (
                <span className="tag is-warning">{t('notifications.statusBad')}</span>
              )
            case 'concluido':
              return (
                <span className="tag is-success">{t('notifications.statusOk')}</span>
              )
            case 'recusado':
              return (
                <span className="tag is-danger">{t('notifications.ticket.refused')}</span>
              )
            case 'cancelado':
              return (
                <span className="tag is-danger">{t('notifications.ticket.canceled')}</span>
              )
            default:
              return (
                <></>
              )
          }
        }
      }
    },
  ];

  return (
    <>
      <StyledDiv>
        {isLoading || isFetching ? <Table /> :
          <>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={t('notifications.all.allcalls')}
                data={tickets as TicketsType[]}
                columns={columns}
                options={{ ...tableOptions, ...translatedTextLabels }}
              />
            </ThemeProvider>
          </>
        }
      </StyledDiv>
    </>
  )
}
