import { Container } from "@material-ui/core";
import { HeaderText } from "../../StyledComponents/pages/pages";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { ButtonsContainer, Column, ContainerBase, Paragraph, ParagraphMsg } from "./style/SingleNotificationStyle";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { ChecklistReportType, ChecklistSubitemReport } from "../../service/Reports";
import { useMutation, useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import Tickets, { novaMensagem } from "../../service/Tickets";
import { FileInput2 } from "../Users/style/UserRegisterStyle";
import ModalDeny from "./ModalDeny";
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import ModalImagePreview from "../Reports/ModalDetailPrint/ModalImagePreview";
import { AxiosError } from "axios";
import ModalDetailPrint from "../Reports/ModalDetailPrint/ModalDetailPrint";
import ModalImageOnTicket from "./ModalImageOnTicket";
import RedirectModal from "./Modals/RedirectModal";
import { Skeleton } from "@mui/material";
import ImagesCmp from "../ImagesCmp";
import PicturePreview from "../PicturePreview";
import { ItemChecklist } from "../../service/Checklists";
import { useParams } from "react-router-dom";
import { trackEventMatomo } from "../../helpers/matomo";
import Grid from '@mui/material/Unstable_Grid2';

const ticketsService = new Tickets();

export default function SingleNotification() {
  const { t } = useTranslation('translation');

  const idParam = useParams()

  const actualDate = new Date().getTime();

  const { hierarchyLabel, ticket, openModal, setTicket, setPopUp, userData } = useContext(UserContext);

  const [selected, setSelected] = useState<ChecklistReportType>();
  const [image, setImage] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);
  const [openRefuse, setOpenRefuse] = useState(false);
  const [openImgPreview, setOpenImgPreview] = useState(false);
  const [openRedirect, setOpenRedirect] = useState(false)
  const [imgPreview] = useState('')
  const [objectStatus, setObjectStatus] = useState<novaMensagem>({
    comentario: '',
    dataAcompanhamento: new Date(actualDate - (actualDate % 86400000) + 10800000),
    status: 'emandamento',
    referenciaFoto: ''
  })
  const [disable, setDisable] = useState(false);

  const item = ticket?.execucaoChecklist?.itens?.filter((item: ItemChecklist) => item.id === ticket.itemId)[0]
  const subitem = ticket?.execucaoChecklist?.itens?.find((item: ItemChecklist) => item.id === ticket.itemId)
    ?.subitens?.filter((subItem) => subItem.id === ticket?.subitemId)[0] as unknown as ChecklistSubitemReport

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['TicketById'],
    queryFn: async () => {
      try {
        const token = await AttToken()
        if (token && idParam) {
          const response = await ticketsService.getTicketById(token, idParam.id as string)
          return response
        }
      } catch (err) {
        console.log(err)
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
      return toast.error(t('notifications.errorFetchingNotifications') as string)
    }
  })

  useEffect(() => {
    if (data) {
      setTicket(data)
      setSelected(data.execucaoChecklist)
    }
  }, [data, setTicket])

  const verificaResponsavel = () => {
    if (ticket && ticket.responsavelNome && ticket.responsavelNome !== userData.userName) {
      return false
    } else return true
  }

  const closeAndAtt = async () => {
    setOpen(false);
  }

  const closeImgPreview = async () => {
    setOpenImgPreview(false);
  }

  const closeRedirectModal = async () => {
    setOpenRedirect(false);
  }

  const onHandleClick = () => {
    setPopUp(true);
    setOpenModalPrint(true)
  }

  const { mutate: remove } = useMutation({
    mutationKey: ['UpdateStatusChamado'],
    mutationFn: async (value: string) => {
      setDisable(true)
      const token = await AttToken()
      if (token && idParam) {
        const novaMensagemObj: novaMensagem = {
          comentario: objectStatus.comentario,
          dataAcompanhamento: objectStatus.dataAcompanhamento,
          status: value,
          referenciaFoto: image,
        }
        const response = await ticketsService.update(token, String(idParam.id), { novaMensagem: novaMensagemObj })
        toast.warn(`${t('notifications.ticket.tickets')} ${objectStatus.status === 'recusado' ? `${t('notifications.ticket.refused')}` : `${t('notifications.ticket.canceled')}`}`)
        openModal(false);
        refetch()
        return response
      }
    },
    onSuccess: () => {
      setDisable(false)
    }
  })

  const renderSwitch = (param: ChecklistSubitemReport) => {
    switch (param.tipo) {
      case 'multiple': case 'unique':
        return (
          <>
            {param.selecao ? param.selecao.map((e, i) => {
              if ((param.selecionados && param.selecionados[i])) {
                return (<li key={i} style={{
                  fontWeight: `${(param.selecionados && param.selecionados[i]) ? '900' : '250'}`,
                  marginLeft: `${(param.selecionados && param.selecionados[i]) ? '30px' : '2px'}`
                }}>
                  {e}
                </li>)
              } else return <></>
            }) : <li>a</li>}
            {param.img && (
              <ImagesCmp images={param.img} selected={selected as ChecklistReportType} />
            )}
            {param.ocorrencia && <p>{`${t('Filling.occurrence')} ${param.ocorrencia}`}</p>}
          </>
        )

      case 'avaliacao':
        return (
          param.avaliacao && param.avaliacao.map((ele, i) => (
            <>
              <ol
                style={{
                  fontWeight: `${(param.avaliacao && param.avaliacao[i].valor === param.nota) ? '900' : '250'}`
                }}
              >{ele.valor} - {ele.nome}
              </ol>
            </>
          )
          )
        )

      case 'list':
        return (
          <>
            {param.padraoLista ? param.padraoLista.map((e, i) => {
              if ((param.selecionados && param.selecionados[i].texto === e)) {
                return (<li key={i} style={{
                  fontWeight: `${(param.selecionados && param.selecionados[i].texto === e) ? '900' : '250'}`,
                  marginLeft: `${(param.selecionados && param.selecionados[i].texto === e) ? '30px' : '2px'}`
                }}>
                  {e}
                </li>)
              } else return <></>
            }) : <li>a</li>}
            {param.ocorrencia && <p>{`${t('Filling.response')} ${param.ocorrencia}`}</p>}
            {param.img && (
              <ImagesCmp images={param.img} selected={selected as ChecklistReportType} />
            )}
          </>
        )

      case 'data': case 'hora': case 'assinatura': case 'email': case 'telefone': case 'rg': case 'cpfcnpj': case 'cep': case 'placa':
        return (
          <>
            {param.ocorrencia && <p>{`${t('Filling.response')} ${param.ocorrencia}`}</p>}
            {param.img && (
              <ImagesCmp images={param.img} selected={selected as ChecklistReportType} />
            )}
          </>
        )

      default:
        return (
          <div>
            {param.ocorrencia && <li>{`${t('Filling.response')} ${param.ocorrencia}`}</li>}
            {param.img && (
              <ImagesCmp images={param.img} selected={selected as ChecklistReportType} />
            )}
          </div>
        )
    }
  }

  const { mutate: accept } = useMutation({
    mutationKey: ['UpdateStatusChamado'],
    mutationFn: async (value: string) => {
      setDisable(true)
      const token = await AttToken()
      if (token && idParam) {
        const novaMensagemObj: novaMensagem = {
          comentario: objectStatus.comentario,
          dataAcompanhamento: objectStatus.dataAcompanhamento,
          status: value,
          referenciaFoto: image,
        }
        const response = await ticketsService.update(token, String(idParam.id), { novaMensagem: novaMensagemObj })
        toast.warn(`${t('notifications.ticket.tickets')} ${objectStatus.status === 'emandamento' ? t('Filling.inProgress') : t('Filling.completed')}`)
        setImage('')
        refetch()
        return response
      }
    },
    onSuccess: () => {
      setDisable(false)
    }
  })

  function getBase64(file: File, cb: (s: string) => void) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const renderImages = (File: FileList | null) => {
    if (File) {
      Array.from(File).map(e => getBase64(e, (i) => setImage(i)));
    }
  }

  const BasicTicket = ({ el }: { el: string }) => {
    const { ticket } = useContext(UserContext);
    return (
      <div className="column">
        <Paragraph>
          {ticket && ticket.historicoChamado ? ticket.historicoChamado[el].nomeUsuarioAcompanhamento : ''}:
          &nbsp;
          <span>
            {ticket && ticket.historicoChamado ? ticket?.historicoChamado[el]?.data : ''}
          </span>
        </Paragraph>
        <Paragraph>
          {t('notifications.ticket.Data_de _atendimento')}:
          &nbsp;
          <span>
            {ticket && ticket.historicoChamado ? ticket.historicoChamado[el].dataMensagemFormatada : ''}
          </span>
        </Paragraph>
        <Paragraph>
          {t('notifications.ticket.Comentario')}&nbsp;<span>{ticket && ticket.historicoChamado ? ticket.historicoChamado[el].comentario : ''}</span>
        </Paragraph>
      </div>
    )
  }

  const getStatus = (value: string) => {
    switch (value) {
      case 'emandamento':
        return (
          ticket && ticket.historicoChamado ? Object.keys(ticket.historicoChamado).map((e) => (
            <>
              <div className="columns" style={{ display: "flex" }}>
                <BasicTicket el={e} />
                {ticket.historicoChamado && ticket.historicoChamado[e].foto && (
                  <PicturePreview el={e} />
                )}
              </div><hr />
            </>
          )) : <span style={{ fontStyle: 'italic' }}>{t('notifications.ticket.chamado_sem_msg')}</span>
        )
      case 'pendente':
        return (
          <>
            <span style={{ fontStyle: 'italic' }}>{t('notifications.ticket.chamado_pendente')}</span><hr />
          </>
        )
      case 'concluido':
        return (
          <div>
            {ticket && ticket.historicoChamado ? Object.keys(ticket.historicoChamado).map((e) => (
              <>
                <div className="columns">
                  <BasicTicket el={e} />
                  <div className="column">
                    {!!ticket && ticket.historicoChamado && ticket.historicoChamado[e].status === 'concluido' ?
                      <span className="tag is-success">{t('notifications.statusOk')}</span> :
                      <span className="tag is-info">{t('notifications.statusProgress')}</span>}
                  </div>
                  {ticket.historicoChamado && ticket.historicoChamado[e].foto && (
                    <PicturePreview el={e} />
                  )}
                </div><hr />
              </>
            )) : <span style={{ fontStyle: 'italic' }}>{t('notifications.ticket.chamado_sem_msg')}</span>}
          </div>
        )
      case 'recusado':
        return (
          <>
            <span style={{ fontStyle: 'italic' }}>{t('notifications.ticket.Chamado_recusado')}</span><hr />
          </>
        )
      case 'cancelado':
        return (
          <div>
            {ticket && ticket.historicoChamado ? Object.keys(ticket.historicoChamado).map((e) => (
              <>
                <div className="columns">
                  <BasicTicket el={e} />
                  <div className="column">
                    {!!ticket && ticket.historicoChamado && ticket.historicoChamado[e].status === 'cancelado' ?
                      <span className="tag is-danger">{t('notifications.ticket.canceled')}</span> :
                      <span className="tag is-info">{t('notifications.statusProgress')}</span>}
                  </div>
                  {ticket.historicoChamado && ticket.historicoChamado[e].foto && (
                    <PicturePreview el={e} />
                  )}
                </div><hr />
              </>
            )) : <span style={{ fontStyle: 'italic' }}>{t('notifications.ticket.chamado_sem_msg')}</span>}
          </div>
        )
      default:
        return (
          <></>
        )
    }
  }

  const tipoExibido = (value: string) => {
    switch (value) {
      case 'avaliacao':
        return '(Avaliação)'
      case 'multiple':
        return '(Multiplo)'
      case 'unique':
        return '(Unico)'
      case 'textoCurto':
        return '(Texto curto)'
      case 'textoLongo':
        return '(Texto longo)'
      case 'list':
        return '(Lista de textos)'
      case 'email':
        return '(E-mail)'
      case 'placa':
        return '(Placa)'
      case 'cpfcnpj':
        return '(CPF / CNPJ)'
      case 'cep':
        return '(CEP)'
      case 'assinatura':
        return '(Assinatura)'
      case 'rg':
        return '(RG)'
      case 'data':
        return '(Data)'
      case 'hora':
        return '(Hora)'
      case 'telefone':
        return '(Telefone)'
      case 'numero':
        return '(Numero)'
      default:
        return (<div>AAAAAAA</div>)
    }
  }

  useEffect(() => {
    console.log(selected)
  }, [])

  return (
    <>
      <div>
        <header className="modal-card-head">
          <p className="modal-card-title">{t('ticketDetail.detailHeader')} - {isLoading || isFetching ? `${t('notifications.ticket.loading')}` : ticket?.nome}</p>
        </header>
        <section>
          {isLoading || isFetching ? (
            <div style={{ padding: '25px' }}>
              <Skeleton variant="rounded" width={100} height={'35px'} />
              <div className="columns">
                <div className="column">
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={200} />
                </div>
                <div className="column">
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={200} />
                </div>
              </div>
              <Skeleton variant="rounded" height={100} />
            </div>

          ) : <Container>
            {openRedirect &&
              <RedirectModal
                openRedirect={openRedirect}
                setOpenRedirect={closeRedirectModal}
                chamadoId={String(idParam) as string}
              />}
            {openModalPrint &&
              <ModalDetailPrint
                openModal={openModalPrint}
                setOpenModal={setOpenModalPrint}
                selected={selected as ChecklistReportType} />}
            {openImgPreview &&
              <ModalImageOnTicket
                image={imgPreview}
                open={openImgPreview}
                setOpen={closeImgPreview}
              />}
            {open &&
              <ModalImagePreview
                image={image}
                open={open}
                setOpen={closeAndAtt}
              />}
            {openRefuse && <ModalDeny
              openRefuse={openRefuse}
              setOpenRefuse={setOpenRefuse}
              status={objectStatus.status}
              mutate={remove}
            />}
            <button
              className="button is-small is-info"
              onClick={() => {
                trackEventMatomo('tela de chamado', 'click', 'button', 'abre form')
                onHandleClick()
                setDisable(true)
              }}>{t('ticketDetail.showForm')}
            </button>
            <Grid container spacing={4}>
              <Grid xs={6}>
                <Paragraph>
                  {t('ticketDetail.dateIn')}
                  &nbsp;
                  <span>
                    {ticket && new Date(ticket.inicio).toLocaleString('pt-BR')}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('ticketDetail.dateLimit')}
                  &nbsp;
                  <span
                    style={{ color: (ticket) && ticket.dataPrevisao > Number(objectStatus.dataAcompanhamento) ? '' : 'red' }}
                  >
                    {ticket && new Date(ticket.dataPrevisao).toLocaleString('pt-BR')}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('ticketDetail.fromName')}
                  &nbsp;
                  <span>
                    {selected ? selected?.formulario
                      : 'Formulário não econtrado, pode não existir ou ainda estar em upload.'}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('ticketDetail.initial')}
                  &nbsp;
                  <span>
                    {selected && new Date(selected.inicio).toLocaleString('pt-BR')}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('ticketDetail.final')}&nbsp;
                  <span>
                    {!!selected && new Date(selected.fim).toLocaleString('pt-BR')}
                  </span>
                </Paragraph>
                <Paragraph>
                {t('notifications.ticket.responsible')}&nbsp;&nbsp;
                  <span>
                    {ticket && (ticket.responsavelNome || '-')}
                  </span>
                </Paragraph>
              </Grid>
              <Grid xs={6}>
                <Paragraph>
                  {t('ticketDetail.user')}&nbsp;<span>{ticket && ticket.usuarioNome?.startsWith('anonimo') ? 'Anonimo' : ticket?.usuarioNome}</span>
                </Paragraph>
                <Paragraph>
                  {t('ticketDetail.manage')}&nbsp;<span>{ticket && ticket?.nomeGerencia}</span>
                </Paragraph>
                <Paragraph>
                  {hierarchyLabel.cliente}:&nbsp;<span>{ticket && ticket?.nomeCliente}</span>
                </Paragraph>
                <Paragraph>
                  {hierarchyLabel.local}:&nbsp;<span>{ticket && ticket?.nomeLocal}</span>
                </Paragraph>
                <Paragraph>
                  {hierarchyLabel.conjunto}:&nbsp;<span>{ticket && ticket?.nomeConjunto}</span>
                </Paragraph>
                <Paragraph>
                  {hierarchyLabel.unidade}:&nbsp;<span>{ticket && ticket?.nomeUnidade}</span>
                </Paragraph>
              </Grid>
            </Grid>
            {ticket && ticket.itemId && <ContainerBase className="columns" style={{ border: '1px solid lightgray', margin: '5px' }}>
              <div className="column" style={{ display: 'flex', flexDirection: 'column' }}>
                <Paragraph>
                {t('notifications.ticket.item')}&nbsp;
                  <span>
                    {item?.nome}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('notifications.ticket.Subitem_chamado')}:&nbsp;
                  <span>
                    {subitem?.nome}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('notifications.ticket.Resposta_subtitem')}:&nbsp;
                  <span>
                    {ticket.execucaoChecklist?.itens?.find((item: ItemChecklist) => item.id === ticket.itemId)?.subitens.filter((ele) => ele.id === ticket.subitemId).map((subItem) => (
                      <div>
                        <ul>
                          {renderSwitch(subItem as unknown as ChecklistSubitemReport)}
                        </ul>
                      </div>
                    ))}
                  </span>
                </Paragraph>
              </div>
              <div className="column">
                <Paragraph>
                  {t('notifications.ticket.Tipo_subitem')}:&nbsp;
                  <span>
                    {subitem?.tipo ? tipoExibido(subitem?.tipo) : ''}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('notifications.ticket.Fotos_subitem')}:&nbsp;
                </Paragraph>
                <span>
                  <ImagesCmp images={subitem?.img as string[]} selected={selected as ChecklistReportType} />
                </span>
              </div>
            </ContainerBase>}
            <ParagraphMsg>
              {t('ticketDetail.message')}&nbsp;&nbsp;<span>{!!ticket && ticket.instrucao}</span>
            </ParagraphMsg>
            {!!ticket && ticket.status === 'pendente' && <ContainerBase style={{ display: 'flex', justifyContent: 'space-around', padding: '10px 0px 10px 0px' }}>
              <button
                disabled={ticket?.block}
                onClick={() => {
                  trackEventMatomo('meus chamados', 'click', 'button', 'muda status chamado')
                  accept('emandamento')
                }}
                className="button is-success"
              >
                {t('notifications.ticket.Aceitar')}
              </button>
              <button
                disabled={ticket?.block}
                onClick={() => {
                  trackEventMatomo('meus chamados', 'click', 'button', 'muda status chamado')
                  remove('recusado')
                }}
                className="button is-danger"
              >
                {t('notifications.ticket.Recusar')}
              </button>
              {ticket.encaminha && <button
                disabled={ticket?.block}
                onClick={() => {
                  trackEventMatomo('meus chamados', 'click', 'button', 'abre modal')
                  setOpenRedirect(true)
                }}
                className="button is-info">
                {t('notifications.ticket.Redirecionar')}
              </button>}
            </ContainerBase>}
            {!!ticket && ticket.historicoChamado && <ContainerBase>
              <ParagraphMsg>
                {t('notifications.ticket.Lista_acoes')}
              </ParagraphMsg>
            </ContainerBase>}
            <ContainerBase style={{ padding: '25px' }}>
              <div style={{ border: '1px solid lightgray', padding: '30px', margin: '5px' }}>
                <p style={{ color: 'gray', fontSize: '1em', fontWeight: 500, textAlign: 'center' }}>
                  {!!ticket && getStatus(ticket.status)}
                </p>
              </div>
              {ticket?.status === 'emandamento' &&
                <>
                  <Column>
                    <HeaderText style={{ textAlign: 'center' }}>
                      {t('ticketDetail.doAction')}
                    </HeaderText>
                    <div className="columns">
                      <div className="column">
                        <Paragraph>
                          {t('ticketDetail.dateConclusion')}
                        </Paragraph>
                        <ReactDatePicker
                          showMonthDropdown
                          showYearDropdown
                          selected={objectStatus.dataAcompanhamento as Date}
                          onChange={(date: Date) => {
                            trackEventMatomo('meus chamados', 'click', 'datepicker', 'edita data')
                            setObjectStatus({ ...objectStatus, dataAcompanhamento: (date.setHours(23, 59, 59)) })
                          }}
                          locale="pt"
                          dateFormat='dd/MM/yyyy'
                          className='input is-small'
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                      <div className="column">
                        <Paragraph>
                          {t('ticketDetail.action')}
                        </Paragraph>
                        {!verificaResponsavel() && (
                          <span className="tag is-warning">{t('notifications.ticket.onlyResponsibleCanComplete')} "{ticket.responsavelNome || `${t('notifications.ticket.nameNotFound')}`}" {t('notifications.ticket.canCompleteThisTicket')}</span>
                        )}
                        <select onChange={(e) => setObjectStatus({ ...objectStatus, status: e.target.value })} className="select is-small is-fullwidth">
                          <option value={'emandamento'}>
                            {t('ticketDetail.proceed')}
                          </option>
                          <option value={'concluido'} disabled={!verificaResponsavel()}>
                            {t('ticketDetail.conclude')}
                          </option>
                          <option value={'cancelado'}>
                            {t('ticketDetail.cancel')}
                          </option>
                        </select>
                      </div>
                    </div>
                  </Column>
                  <div className="columns">
                    <div className="column">
                      <Paragraph>
                        {t('ticketDetail.comment')}
                      </Paragraph>
                      <textarea
                        onClick={() => {
                          trackEventMatomo('meus chamados', 'click', 'textarea', 'edita valor')
                        }}
                        onChange={(e) => setObjectStatus({ ...objectStatus, comentario: e.target.value })}
                        value={objectStatus.comentario}
                        className="textarea"
                        placeholder={t("ticketDetail.enterComment")}
                      />

                    </div>
                    <ButtonsContainer className="column">
                      <button
                        disabled={disable || ticket?.block}
                        onClick={() => {
                          trackEventMatomo('meus chamados', 'click', 'button', 'edita chamado')
                          objectStatus.status === 'recusado' || objectStatus.status === 'cancelado' ? remove(objectStatus.status) : accept(objectStatus.status)
                        }}
                        className="button is-small is-success"
                      >
                        {t('ticketDetail.confirm')}
                      </button>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FileInput2
                          disabled={ticket?.block}
                          value={undefined}
                          type="file"
                          id="img"
                          name="img"
                          accept="image/*"
                          multiple={true}
                          onChange={(e) => {
                            trackEventMatomo('meus chamados', 'click', 'input', 'add imagem')
                            renderImages(e.target.files)
                          }}
                          theme={t('users.userRegister.selectMessage')}
                        />
                        {image && <div className="columns" style={{ border: '1px solid lightgray', margin: '5px', height: '10em', padding: '5px' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', margin: '5px' }}>
                            <button
                              disabled={ticket?.block}
                              value={image}
                              onClick={() => {
                                setImage('')
                                trackEventMatomo('meus chamados', 'click', 'button', 'remove imagem')
                              }}
                              className="button is-small is-danger"
                            >
                              {t('notifications.ticket.Remover')} <DeleteIcon />
                            </button>
                            <img
                              onClick={() => {
                                trackEventMatomo('meus chamados', 'click', 'img', 'abre imagem')
                                setOpen(true)
                              }}
                              style={{ height: '75%', width: '150px', padding: '20px', backgroundColor: 'lightgray' }}
                              alt='foto'
                              src={image}
                              width={100}
                            />
                          </div>
                        </div>}
                      </div>
                    </ButtonsContainer>
                  </div>
                </>
              }
            </ContainerBase>
          </Container>}
        </section>
      </div>
    </>
  )
}
