import { useTranslation } from "react-i18next"
import { ChecklistReportType, ChecklistSubitemReport } from "../../../../service/Reports"
import ImagesCmp from "../../../ImagesCmp"
import ImagesCmpAssinatura from "../../../ImagesCmpAssinatura"
import { ThStyle } from "../../Style"

const RenderSwitch = (param: ChecklistSubitemReport, showNotSelected: boolean, selected: ChecklistReportType) => {
  const { t } = useTranslation('translation');
  switch (param.tipo) {
    case 'multiple': case 'unique':
      return (
        <div style={{ pageBreakInside: 'avoid' }}>
          <ThStyle>
            {param?.selecao?.map((elem, index) => {
              return (
                <li
                  key={index}
                  style={{
                    display: `${!showNotSelected && param.selecionados && !param.selecionados[index] ? 'none' : ''}`,
                    wordBreak: 'break-word',
                    color: 'gray',
                    listStyleType: `${(param.selecionados && param.selecionados[index]) ? 'circle' : 'square'}`,
                    fontWeight: `${(param.selecionados && param.selecionados[index]) ? '900' : '250'}`,
                    marginLeft: `${(param.selecionados && param.selecionados[index]) ? '20px' : '10px'}`,

                  }}>
                  {elem}
                </li>
              )
            })}
          </ThStyle>
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
          {param.ocorrencia && <p>{`Ocorrencia: ${param.ocorrencia}`}</p>}
        </div>
      )
    case 'avaliacao':
      return (
        <div style={{ pageBreakInside: 'avoid' }}>
          <ThStyle>
            {param?.avaliacao?.map((elem, index, arr) => {
              if (showNotSelected || ((param.nota || param.nota === 0) && param.nota === index)) {
                return (
                  <div key={index} style={{ display: 'flex' }}>
                    <li style={{
                      color: 'gray',
                      listStyleType: `${((param.nota || param.nota === 0) && param.nota === index) || (param.nota === -1 && elem === arr[arr.length - 1]) ? 'circle' : 'square'}`,
                      marginRight: '5px', fontWeight: `${((param.nota || param.nota === 0) && param.nota === index) || (param.nota === -1 && elem === arr[arr.length - 1]) ? '900' : '250'}`,
                      marginLeft: `${((param.nota || param.nota === 0) && param.nota === index) || (param.nota === -1 && elem === arr[arr.length - 1]) ? '20px' : '10px'}`,
                      wordBreak: 'break-word'
                    }}>
                      {`${elem.valor}`}
                    </li>
                    <li style={{ color: 'gray', wordBreak: 'break-word', fontWeight: `${(param.nota === index) ? '900' : '250'}` }}>
                      {elem.nome}
                    </li>
                  </div>)
              } else return <></>
            })}
          </ThStyle>
          {param.ocorrencia && <p>{`Resposta: ${param.ocorrencia}`}</p>}
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
        </div>
      )
    case 'list':
      return (
        <div style={{ pageBreakInside: 'avoid' }}>
          <ThStyle>
            {(param?.selecionados as { texto: string }[])?.map((elem, index) => {
              return (
                <li
                  key={index}
                  style={{
                    color: 'gray',
                    fontWeight: `${(param.selecionados && param.selecionados[index].texto === elem.texto) ? '700' : '250'}`,
                    wordBreak: 'break-word'
                  }}>
                  {elem.texto}
                </li>
              )
            })}
          </ThStyle>
          {param.obs && <p>{`Resposta: ${param.obs}`}</p>}
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
        </div>
      )
    case 'textoLongo': case 'hora': case 'email': case 'telefone': case 'rg': case 'cpfcnpj': case 'cep': case 'placa': case 'textoCurto': case 'numero':
      return (
        <div style={{ pageBreakInside: 'avoid' }}>
          {param.ocorrencia && <p style={{ color: 'gray' }}>{`Resposta: ${param.ocorrencia}`}</p>}
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
          {param.obs && <p>{`Ocorrencia: ${param?.obs}`}</p>}
        </div>
      )
    case 'data':
      return (
        <div style={{ pageBreakInside: 'avoid' }}>
          {param.ocorrencia && <p style={{ color: 'gray', pageBreakInside: 'avoid' }}>{`Resposta: 
          ${typeof param.ocorrencia === 'number'
              ? new Date(param.ocorrencia).toLocaleDateString('pt-BR')
              : param.ocorrencia}`
          }</p>}
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
          {param.obs && <p>{`Ocorrencia: ${param?.obs}`}</p>}
        </div>
      )
    case 'assinatura':
      return (
        <div style={{ pageBreakInside: 'avoid' }}>
          {param.assinatura &&
            <ImagesCmpAssinatura selected={selected} images={[param.assinatura]} />}
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
          {param.obs && <p style={{ color: 'gray', }}>{`Ocorrencia: ${param?.obs}`}</p>}
        </div>
      )
    default:
      return (
        <>
        </>
      )
  }
}

export default RenderSwitch;  