import { useEffect, useState } from 'react';
import { MainContainerMulti } from './Multiple/MultipleStyle';
import { Container, Input } from './Unique/UniqueStyle';
import { SubItemProps } from './Avaliacao/Avaliacao';
import SubItemComentario from './SubItemComentario';
import SubItemPhoto from './SubItemPhoto';
import { trackEventMatomo } from '../../../../helpers/matomo';
import { useTranslation } from 'react-i18next';

export default function Numero({ subItem, indexItem, onChange }: SubItemProps) {
  const [numero, setNumero] = useState<number>(subItem.padrao ? parseInt(subItem.padrao) : 0);
  const { t } = useTranslation('translation');

  const showMinNumberVal = (value: number) => {
    if ((value && typeof value === 'number') || value === 0) return <p>{t('Filling.fillSubitens.minValue')}{value}</p>
  }

  const showMaxNumberVal = (value: number) => {
    if ((value && typeof value === 'number') || value === 0) return <p>{t('Filling.fillSubitens.maxValue')}{value}</p>
  }

  const borderChecker = () => {
    if (Number(numero) < Number(subItem.minNumberVal)) return '0.3em #FB8E27 solid'
    if (Number(numero) > Number(subItem.maxNumberVal)) return '0.3em #FB8E27 solid'
    if (subItem?.obrigatoriedade?.preenchimento && !numero) return '0.3em red solid'
    if (!subItem?.obrigatoriedade?.preenchimento && numero === null) return '0.3em #2196f3 solid'
    else return '0.3em #4caf50 solid'
  }

  useEffect(() => {
    if (subItem.padrao) {
      setNumero(parseInt(subItem.padrao))
      subItem.ocorrencia = parseInt(subItem.padrao)
    }
  }, [subItem]);

  useEffect(() => {
    onChange && onChange()
    console.log(subItem)
  }, [numero]);

  return (
    <MainContainerMulti>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '.5em' }}>
          <Input
            style={{
              borderBottom:
                `${borderChecker()}`
            }}
            value={numero as number}
            type="number"
            onClick={() => trackEventMatomo('numero', 'click', 'input', 'edita valor')}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setNumero(value);
              subItem.ocorrencia = value;
            }}
            className="inputDate input"
          />
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div style={{ fontSize: '.8em', color: 'gray', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {showMinNumberVal(subItem.minNumberVal as number)}
            {showMaxNumberVal(subItem.maxNumberVal as number)}
          </div>
        </div>
        {numero as number > -1 && subItem.comentario &&
          <SubItemComentario onChange={onChange} subItem={subItem} indexItem={indexItem} />}
        {numero as number > -1  && subItem.foto &&
          <SubItemPhoto onChange={onChange} subItem={subItem} indexItem={indexItem} />}
      </Container>
    </MainContainerMulti>
  );
}
