import { Autocomplete, TextField } from '@mui/material'

export default function AutocompleteSApi({
  arrayObj,
  setValue,
  noOptionsLabel,
  loadingText,
  label,
}: {
  arrayObj: any[],
  setValue: Function,
  noOptionsLabel: string,
  loadingText: string,
  label: string
}) {
  return (
    <Autocomplete
      getOptionLabel={option => option.label}
      getOptionKey={option => option.value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      className='column'
      noOptionsText={noOptionsLabel}
      loading
      loadingText={loadingText}
      size="small"
      style={{ fontSize: '10px !important' }}
      filterSelectedOptions
      options={arrayObj
        ?.sort((a: {nome: string, id: string}, b: {nome: string, id: string}) => 
          { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
        ?.map((ele: {nome: string, id: string}) => ({
          label: ele.nome,
          value: ele.id,
        }))}
      onChange={(event: any, newValue: { label: string, value: string } | null) => {
        setValue(newValue?.value)
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.value}>
          <span>{option.label}</span>
        </li>)}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  )
}
