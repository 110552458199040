import { Dispatch, SetStateAction, useState } from 'react';
import { FilterAprovationsType } from '../../service/Aprovations';
import { Accordion, AccordionSummary, Autocomplete, AutocompleteChangeReason, Skeleton, TextField } from '@mui/material';
import { Label } from '../../StyledComponents/Input/generic';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import AttToken from '../../helpers/attToken';
import Clients from '../../service/Clients';
import { AxiosError } from 'axios';
import { IoFilter } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { trackEventMatomo } from '../../helpers/matomo';

type props = {
  filter: FilterAprovationsType,
  setFilter: Dispatch<SetStateAction<FilterAprovationsType>>,
  refetch: Function,
  dates: { dataInicio: number, dataFim: number },
  setDates: Function,
  commingFromHistory: boolean
}

const clientService = new Clients();

interface nomeIdSons {
  nome: string;
  id: string;
  locais: string[] | null;
  conjuntos: string[] | null;
  unidades: string[] | null;
}

type keyOptions = 'clientes' | 'locais' | 'conjuntos' | 'unidades';

export default function FiltersAprovationComponent({ filter, setFilter, refetch, dates, setDates, commingFromHistory }: props) {
  const { t } = useTranslation('translation');

  const [values, setValues] = useState<{ [key: string]: { label: string, value: string }[] }>({
    clientes: [],
    locais: [],
    conjuntos: [],
    unidades: []
  });

  async function getHierarchyFilters() {
    const response = await toast.promise(async () => {
      const token = await AttToken();
      if (token) {
        const data: {
          clientes: nomeIdSons[],
          locais: nomeIdSons[],
          conjuntos: nomeIdSons[],
          unidades: nomeIdSons[]
        } = await clientService.getAllHierarchy(token);
        return data;
      }
    }, {
      success: `${t('Aprovations.filtersFound')}`,
      error: `${t('Aprovations.errorFetching')}`,
      pending: `${t('Aprovations.fetchingFilters')}`
    })
    return response
  }

  const {
    data,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ['GET_FULL_HIEARCHY'],
    queryFn: () => getHierarchyFilters(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onError: () => {
      console.log(AxiosError)
    }
  });

  const verifySons = (values: { label: string, value: string }[], keyFather: keyOptions, idSon: string, keySons: 'locais' | 'conjuntos' | 'unidades') => {
    return values?.some(val => {
      if (data) {
        const current = data[keyFather]?.find(el => el.id === val.value);
        const sons = current ? current[keySons] || [] : []
        if (sons && sons.length) {
          return sons?.includes(idSon);
        }
      } else return false;
    })
  }

  const removeSons = (fatherIds: string[], keyFather: keyOptions, keySons: 'locais' | 'conjuntos' | 'unidades', keyFilter: 'cliente' | 'local' | 'conjunto' | 'unidade') => {
    if (data) {
      const result: string[] = [];
      fatherIds?.forEach(id => {
        const current = data[keyFather]?.find(el => el.id === id);
        const sons = current ? current[keySons] || [] : [];
        if (sons && sons.length) {
          sons?.forEach(sonId => {
            setValues((prev) => {
              if (prev[keySons] && prev[keySons]?.some(el => el.value === sonId)) {
                return { ...prev, [keySons]: prev[keySons]?.filter(el => el.value !== sonId) }
              } else return prev
            });
            setFilter((prev) => {
              const filters = prev[keyFilter]
              if (filters && filters.some(ind => ind === sonId)) {
                return { ...prev, [keyFilter]: filters?.filter(ind => ind !== sonId) }
              } else return prev
            })
            result.push(sonId);
          })
        }
      })
      return result;
    } else return []
  }

  return (
    <Accordion style={{ marginBottom: '10px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() =>
        trackEventMatomo('Aprovações', 'click', 'accordeon', 'abre/fecha')}>
        <span style={{ width: '100%', textAlign: 'center', fontSize: '20px', fontWeight: '600', color: 'gray' }}>
        {t('Aprovations.filters')}
        </span>
      </AccordionSummary>
      <div style={{ padding: '10px' }}>
        {isLoading || isFetching ?
          <>
            <div style={{ display: 'flex' }}>
              <Skeleton variant='rounded' height={30} width={150} />
              <Skeleton variant='rounded' height={30} width={150} />
            </div>
            <div className='columns' style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <div className='column'>
                <Skeleton variant='rounded' height={40} />
              </div>
              <div className='column'>
                <Skeleton variant='rounded' height={40} />
              </div>
              <div className='column'>
                <Skeleton variant='rounded' height={40} />
              </div>
              <div className='column'>
                <Skeleton variant='rounded' height={40} />
              </div>
              <div className='column'>
                <Skeleton variant='rounded' height={40} />
              </div>
            </div>
          </> : <div>
            <div className="columns" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', paddingLeft: '10px' }}>
              <div className="column" style={{ display: 'flex' }}>
                <div style={{ display: 'flex', width: 'fit-content', flexDirection: 'column' }}>
                  <Label>{t('notifications.unread.startAt')}</Label>
                  <ReactDatePicker
                    showMonthDropdown
                    showYearDropdown
                    selected={new Date(dates.dataInicio)}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(date: Date) => {
                      trackEventMatomo('Aprovações', 'click', 'datePicker', 'altera data')
                      setDates({ ...dates, dataInicio: new Date(date && date.setHours(0, 0, 0, 0)).getTime() })
                    }
                    }
                    locale="pt"
                    maxDate={new Date(dates.dataFim)}
                    dateFormat='dd/MM/yyyy'
                    className='input is-small'
                  />
                </div>
                <div style={{ display: 'flex', width: 'fit-content', flexDirection: 'column' }}>
                  <Label>{t('notifications.unread.until')}</Label>
                  <ReactDatePicker
                    showMonthDropdown
                    showYearDropdown
                    selected={new Date(dates.dataFim)}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(date: Date) => {
                      trackEventMatomo('Aprovações', 'click', 'datePicker', 'altera data')
                      setDates({ ...dates, dataFim: new Date(date && date.setHours(23, 59, 59, 59)).getTime() })
                    }
                    }
                    maxDate={new Date(Date.now())}
                    locale="pt"
                    dateFormat='dd/MM/yyyy'
                    className='input is-small'
                  />
                </div>
              </div>
            </div>
            <Autocomplete
              className='column'
              noOptionsText={`${t('Aprovations.noClientsFound')}`}
              size="small"
              multiple
              options={(data?.clientes as nomeIdSons[])
                ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                ?.map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              value={values['clientes']}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              onClick={() => trackEventMatomo('Aprovações', 'click', 'select', 'seleciona clientes')}
              onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: AutocompleteChangeReason, deatails: any) => {
                if (reason === 'clear' && filter.cliente && filter.cliente.length) {
                  const removedLocais = removeSons(filter.cliente, 'clientes', 'locais', 'local');
                  if (removedLocais && removedLocais.length) {
                    const removedConjuntos = removeSons(removedLocais, 'locais', 'conjuntos', 'conjunto');
                    if (removedConjuntos && removedConjuntos.length) {
                      removeSons(removedConjuntos, 'conjuntos', 'unidades', 'unidade');
                    }
                  }
                }
                if (reason === 'removeOption') {
                  const removedCliente = deatails.option.value as string;
                  const removedLocais = removeSons([removedCliente], 'clientes', 'locais', 'local');
                  if (removedLocais && removedLocais.length) {
                    const removedConjuntos = removeSons(removedLocais, 'locais', 'conjuntos', 'conjunto');
                    if (removedConjuntos && removedConjuntos.length) {
                      removeSons(removedConjuntos, 'conjuntos', 'unidades', 'unidade');
                    }
                  }
                }
                if (reason === 'clear') {
                  setFilter((prev) => {
                    if (prev) {
                      delete prev.cliente
                      return prev ? { ...prev } : {}
                    } else return {}
                  })
                  setValues((prev) => {
                    if (prev) {
                      prev.clientes = [];
                      return prev ? { ...prev } : {}
                    } else return {}
                  })
                } else if (newValue && newValue.length >= 0) {
                  const values = newValue.map(val => val.value);
                  setValues((prev) => prev ? { ...prev, clientes: newValue } : { clientes: newValue })
                  setFilter((prev) => prev ? { ...prev, cliente: values } : { cliente: values });
                }
              }}
              renderInput={(params) => <TextField {...params} label={`${t('Aprovations.selectClients')}`} />}
            />
            <Autocomplete
              className='column'
              noOptionsText={`${t('Aprovations.noMatchingLocations')}`}
              size="small"
              multiple
              options={(data?.locais as nomeIdSons[])
                ?.filter(lcl => values.clientes.length && verifySons(values.clientes, 'clientes', lcl.id, 'locais'))
                ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                ?.map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              value={values['locais']}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              onClick={() => trackEventMatomo('Aprovações', 'click', 'select', 'seleciona locais')}
              onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: AutocompleteChangeReason, deatails: any) => {
                if (reason === 'clear' && filter.local && filter.local.length) {
                  const removedConjuntos = removeSons(filter.local, 'locais', 'conjuntos', 'conjunto');
                  if (removedConjuntos && removedConjuntos.length) {
                    removeSons(removedConjuntos, 'conjuntos', 'unidades', 'unidade');
                  }
                }
                if (reason === 'removeOption') {
                  const removedLocal = deatails.option.value as string;
                  const removedConjuntos = removeSons([removedLocal], 'locais', 'conjuntos', 'conjunto');
                  if (removedConjuntos && removedConjuntos.length) {
                    removeSons(removedConjuntos, 'conjuntos', 'unidades', 'unidade');
                  }
                }
                if (reason === 'clear') {
                  setFilter((prev) => {
                    if (prev) {
                      delete prev.local
                      return prev ? { ...prev } : {}
                    } else return {}
                  })
                  setValues((prev) => {
                    if (prev) {
                      prev.locais = [];
                      return prev ? { ...prev } : {}
                    } else return {}
                  })
                } else if (newValue && newValue.length >= 0) {
                  const values = newValue.map(val => val.value);
                  setValues((prev) => prev ? { ...prev, locais: newValue } : { locais: newValue })
                  setFilter((prev) => prev ? { ...prev, local: values } : { local: values });
                }
              }}
              renderInput={(params) => <TextField {...params} label={`${t('Aprovations.selectLocations')}`} />}
            />
            <Autocomplete
              className='column'
              noOptionsText={`${t('Aprovations.noMatchingSets')}`}
              size="small"
              multiple
              options={(data?.conjuntos as nomeIdSons[])
                .filter(cnj => values.locais.length && verifySons(values.locais, 'locais', cnj.id, 'conjuntos'))
                .sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                .map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              value={values['conjuntos']}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              onClick={() => trackEventMatomo('Aprovações', 'click', 'select', 'seleciona conjuntos')}
              onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: AutocompleteChangeReason, deatails: any) => {
                if (reason === 'clear' && filter.conjunto && filter.conjunto.length) {
                  removeSons(filter.conjunto, 'conjuntos', 'unidades', 'unidade');
                }
                if (reason === 'removeOption') {
                  const removedConjunto = deatails.option.value as string;
                  removeSons([removedConjunto], 'conjuntos', 'unidades', 'unidade');
                }
                if (reason === 'clear') {
                  setFilter((prev) => {
                    if (prev) {
                      delete prev.conjunto
                      return prev ? { ...prev } : {}
                    } else return {}
                  })
                  setValues((prev) => {
                    if (prev) {
                      prev.conjuntos = [];
                      return prev ? { ...prev } : {}
                    } else return {}
                  })
                } else if (newValue && newValue.length >= 0) {
                  const values = newValue.map(val => val.value);
                  setValues((prev) => prev ? { ...prev, conjuntos: newValue } : { conjuntos: newValue })
                  setFilter((prev) => prev ? { ...prev, conjunto: values } : { conjunto: values });
                }
              }}
              renderInput={(params) => <TextField {...params} label={`${t('Aprovations.selectSets')}`} />}
            />
            <Autocomplete
              className='column'
              noOptionsText={`${t('Aprovations.noMatchingUnits')}`}
              size="small"
              multiple
              options={(data?.unidades as nomeIdSons[])
                .filter(uni => values.conjuntos.length && verifySons(values.conjuntos, 'conjuntos', uni.id, 'unidades'))
                .sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                .map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              value={values['unidades']}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              onClick={() => trackEventMatomo('Aprovações', 'click', 'select', 'seleciona unidades')}
              onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: AutocompleteChangeReason, deatails: any) => {
                if (reason === 'clear' && filter.conjunto && filter.conjunto.length) {
                  setFilter((prev) => prev && prev.unidade ? { ...prev, unidade: undefined } : {})
                }
                if (reason === 'clear') {
                  setFilter((prev) => {
                    if (prev) {
                      delete prev.unidade
                      return prev ? { ...prev } : {}
                    } else return {}
                  })
                  setValues((prev) => {
                    if (prev) {
                      prev.unidades = [];
                      return prev ? { ...prev } : {}
                    } else return {}
                  })
                } else if (newValue && newValue.length >= 0) {
                  const values = newValue.map(val => val.value);
                  setValues((prev) => prev ? { ...prev, unidades: newValue } : { unidades: newValue })
                  setFilter((prev) => prev ? { ...prev, unidade: values } : { unidade: values });
                }
              }}
              renderInput={(params) => <TextField {...params} label={`${t('Aprovations.selectUnits')}`} />}
            />
            {commingFromHistory && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '10px' }}>
                <Label className='label'>
                  <input
                    value={'aprovados'}
                    name={'aprov'}
                    type="radio"
                    onClick={() => trackEventMatomo('Aprovações', 'click', 'radio', 'seleciona aprovados')}
                    onChange={(e) => {
                      setFilter((prev) => prev
                        ? { ...prev, reprovados: undefined, aprovados: true }
                        : { reprovados: undefined, aprovados: true })
                    }
                    }
                  />
                  <span className='tag is-success'>{t('Aprovations.showOnlyApproved')}</span>
                </Label>
                <Label className='label'>
                  <input
                    value={'reprovados'}
                    name={'aprov'}
                    type="radio"
                    onClick={() => trackEventMatomo('Aprovações', 'click', 'radio', 'seleciona reprovados')}
                    onChange={(e) => {
                      setFilter((prev) => prev ? { ...prev, reprovados: true, aprovados: undefined } : { reprovados: true, aprovados: undefined })
                    }
                    }
                  />
                  <span className='tag is-danger'>{t('Aprovations.showOnlyRejected')}</span>
                </Label>
                <Label className='label'>
                  <input
                    value={'all'}
                    name={'aprov'}
                    type="radio"
                    defaultChecked
                    onClick={() => trackEventMatomo('Aprovações', 'click', 'radio', 'seleciona todos')}
                    onChange={(e) => {
                      setFilter((prev) => prev
                        ? { ...prev, reprovados: undefined, aprovados: undefined }
                        : { reprovados: undefined, aprovados: undefined })
                    }
                    }
                  />
                  <span className='tag is-info'>{t('Aprovations.showAll')}</span>
                </Label>
              </div>
            )}
            <button onClick={() => {
              refetch()
              trackEventMatomo('Aprovações', 'click', 'button', 'filtra')
            }} className='button is-fullwidth is-success' type="button">
              {t('filter')}&nbsp;<IoFilter />
            </button>
          </div>}
      </div>
    </Accordion>
  )
}