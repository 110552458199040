import React, { useContext } from 'react';
import Alert from '@mui/material/Alert';
import { Box, Collapse, IconButton } from '@material-ui/core';
import { IoCloseCircleOutline, IoWarning } from 'react-icons/io5';
import { StyledStrong, StyledWarningDiv } from './Style';
import { UserContext } from '../../context/UserContext';
import callAssociateAll from '../../helpers/callAssociateAll';
import { useTranslation } from 'react-i18next';

export default function ChangeAlert() {
  const { t } = useTranslation('translation')
  const [open, setOpen] = React.useState(true);
  const { setChanged } = useContext(UserContext);

  return open ? (
    <div style={{ position: 'absolute', zIndex: 100, width: '80%', top: '3em', right: '4em'}}>
      <Box sx={{ width: '100%' }}>
        <Collapse in={open}>
          <Alert
            severity='warning'
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false)
                }}
              >
                <IoCloseCircleOutline fontSize="inherit" />
              </IconButton>
            }
          >
            <span>
              {t('ChangeAlert.changes')}
            </span>
            <StyledStrong onClick={() => {
              localStorage.setItem('changedHirarquia', 'false');
              setChanged(false);
              callAssociateAll();
            }}> {t('notifications.all.clickHere')} </StyledStrong>
            <span>
              {t('ChangeAlert.changesEnd')}
            </span>
          </Alert>
        </Collapse>
      </Box>
    </div>
  ) : (
    <StyledWarningDiv onClick={() => setOpen(true)}>
      <IoWarning size={40} style={{ alignSelf: 'center' }}/>
    </StyledWarningDiv>
  )
}
