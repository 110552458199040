import { useEffect, useState } from 'react';
import { MainContainerMulti } from './Multiple/MultipleStyle';
import { Container, Input } from './Unique/UniqueStyle';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { SubItemProps } from './Avaliacao/Avaliacao';
import SubItemComentario from './SubItemComentario';
import SubItemPhoto from './SubItemPhoto';
import { trackEventMatomo } from '../../../../helpers/matomo';
import { useTranslation } from 'react-i18next';

export default function TextList({ subItem, indexItem, onChange }: SubItemProps) {
  const { t } = useTranslation('translation')
  const [selecionadosState, setSelecionadosState] = useState<{ texto: string }[]>(subItem.selecionados as { texto: string }[] || [{ texto: '' }]);

  useEffect(() => {
    if (subItem.padraoLista) {
      setSelecionadosState(subItem.padraoLista.map(e => ({ texto: e })));
      subItem.selecionados = subItem.padraoLista.map(e => ({ texto: e }));
    }
  }, [subItem])

  useEffect(() => {
    subItem.selecionados = selecionadosState
    onChange && onChange()
  }, [selecionadosState])

  useEffect(() => {
    console.log(subItem)
  },[selecionadosState])

  return (
    <MainContainerMulti>
      <Container>
        {selecionadosState && selecionadosState.map((e: { texto: string }, i: number) => (
          <div key={`${'index'[i]}`} style={{ display: "flex", width: '100%', alignItems: 'center' }}>
            <Input
              onClick={() => trackEventMatomo('lista de textos', 'click', 'input', 'edita valor')}
              style={{
                marginTop: `${selecionadosState.length > 0 && '.5em'}`,
                borderBottom: `${(selecionadosState.length > 1 && e.texto === '') || (subItem?.obrigatoriedade?.preenchimento && selecionadosState[i].texto === '') ? '0.3em red solid' :
                  selecionadosState[i].texto !== '' ? '0.3em #4caf50 solid' : '0.3em #2196f3 solid'}`
              }}
              value={e.texto}
              onChange={async (event) => {
                e.texto = event.target.value
                setSelecionadosState([...selecionadosState]);
                subItem.selecionados = selecionadosState
              }}
              className="input"
              placeholder={t('Filling.fillSubitens.tapToType')} />
            {i > 0 &&
              <button
                style={{ borderRadius: '5px', padding: '1px', marginLeft: '.5em', height: '100%' }}
                className="button is-danger"
                onClick={() => { setSelecionadosState(selecionadosState.filter((_el, index) => index !== i)) }}
              >
                <DeleteIcon />
              </button>}
          </div>
        ))}
        <button
          disabled={
            selecionadosState.some(e => e.texto === '')
          }
          onClick={(el) => {
            trackEventMatomo('lista de textos', 'click', 'button', 'adiciona nova linha')
            el.preventDefault()
            setSelecionadosState((prev) => prev ? [...prev, { texto: '' }] : [{ texto: '' }])
          }}
          className='button is-small'
          style={{ width: 'fit-content', marginTop: '.5em', border: 'none', borderRadius: '10px 10px 30px 30px', color: 'white', backgroundColor: '#A400CF' }}>
          <AddIcon />
        </button>
        {(subItem.selecionados as { texto: string }[])?.some((el) => el.texto.length > 0) &&
          <>
            {subItem.comentario &&
              <SubItemComentario onChange={onChange} subItem={subItem} indexItem={indexItem} />}
            {subItem.foto &&
              <SubItemPhoto onChange={onChange} subItem={subItem} indexItem={indexItem} />}
          </>
        }
      </Container>
    </MainContainerMulti>
  )
}