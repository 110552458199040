import { useState } from 'react'
import PlanoDeAcao, { planoDeAcaoType } from '../../../service/PlanoDeAcao';
import AttToken from '../../../helpers/attToken';
import { toast } from 'react-toastify';
import queryClient from '../../../service/query';
import { Title } from '../style/stylesPlano';
import AccordionDetailPlan from './AccordionDetailPlan';
import { trackEventMatomo } from '../../../helpers/matomo';
import { useTranslation } from 'react-i18next';

type props = {
  openModal: boolean,
  setOpenModal: Function,
  plano: planoDeAcaoType,
  setSelectedPlano: Function,
  tab: 'pendentes' | 'finalizadas'
}

const PlanoDeAcaoService = new PlanoDeAcao();

type avaliacaoOptions = "pendente" | "malSucedido" | "bemSucedido"

export default function ModalAvalia({ openModal, setOpenModal, plano, setSelectedPlano, tab }: props) {
  const [avaliacao, setAvalicao] = useState(tab === 'pendentes' ? 'pendente' : plano.avaliacao || 'pendente');
  const [detalhes, setDetalhes] = useState(tab === 'pendentes' ? '' : plano.detalhesAvaliacao || '');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('translation');

  return (
    <div className={`modal ${openModal ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card" style={{ width: '80%' }}>
        <header className="modal-card-head">
          <h1 className="modal-card-title">{tab !== 'pendentes' ? `${t('ActionPlan.Modal.planEvaluationDetails')} "${plano.nome}"` : `${t('ActionPlan.Modal.evaluatePlanEffectiveness')} "${plano.nome}"`}</h1>
          <button onClick={() => {
            trackEventMatomo('modal avalia PA', 'click', 'button', 'abre modal')
            setOpenModal(!openModal)
            setSelectedPlano(undefined);
          }} className="delete" aria-label="close" disabled={loading} />
        </header>
        <section className="modal-card-body">
          <h1 style={{ wordBreak: 'break-all' }}>{`${t('ActionPlan.Modal.planWas')} "${plano.nome}" ${t('ActionPlan.Modal.was')}`}</h1>
          <div className='avaliaBox'>
            <label className='radio' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: tab !== 'pendentes' ? 'not-allowed' : 'pointer' }}>
              <input
                type="radio"
                name="answer"
                checked={avaliacao === "malSucedido"}
                value="malSucedido"
                onChange={(e) => {
                  trackEventMatomo('analise PA', 'click', 'radio', 'seleciona valor')
                  setAvalicao(e.target.value as avaliacaoOptions)
                }}
                readOnly
                disabled={tab !== 'pendentes'}
              />
              &nbsp;<span className='tag is-danger'>{t('ActionPlan.Modal.unsuccessful')}</span>
            </label>
            <label className='radio' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: tab !== 'pendentes' ? 'not-allowed' : 'pointer' }}>
              <input
                type="radio"
                name="answer"
                checked={avaliacao === "bemSucedido"}
                value="bemSucedido"
                onChange={(e) => {
                  trackEventMatomo('analise PA', 'click', 'radio', 'seleciona valor')
                  setAvalicao(e.target.value as avaliacaoOptions)
                }}
                readOnly
                disabled={tab !== 'pendentes'}
              />
              &nbsp;<span className='tag is-success'>{t('ActionPlan.Modal.successful')}</span>
            </label>
          </div>
        </section>
        <section className="modal-card-body">
          <h1>{t('ActionPlan.Modal.feelFreeToDetail')}</h1>
          <textarea
            value={detalhes}
            className='textarea has-fixed-size'
            onClick={() => trackEventMatomo('analise PA', 'click', 'textarea', 'edita valor')}
            onChange={(e) => setDetalhes(e.target.value)}
            disabled={tab !== 'pendentes'}
          />
        </section>
        <section className="modal-card-body">
          <Title>{t('ActionPlan.Modal.planDetails')}</Title>
          <AccordionDetailPlan plano={plano} />
        </section>
        <footer className="modal-card-foot">
          <div className="buttons">
            <button
              className={`button ${avaliacao === 'pendente' ? 'is-warning' : avaliacao === 'malSucedido' ? 'is-danger' : 'is-success'} is-fullwidth`}
              disabled={tab !== 'pendentes' || loading || avaliacao === 'pendente'}
              onClick={async () => {
                trackEventMatomo('analise PA', 'click', 'button', 'avalia PA')
                setLoading(true);
                const token = await AttToken();
                if (token) {
                  await toast.promise(
                    PlanoDeAcaoService.evaluatePlan(token, plano.id as string, avaliacao, detalhes),
                    {
                      pending: t('ActionPlan.Modal.evaluatingPlan'),
                      success: t('ActionPlan.Modal.planEvaluated'),
                      error: t('ActionPlan.Modal.failedToChangeStatus')
                    }
                  )
                }
                queryClient.resetQueries(['GET_HISTORICO']);
                setLoading(false);
                setOpenModal(!openModal);
                setSelectedPlano(undefined);
              }}
            >
              {tab !== 'pendentes' ? `${t('ActionPlan.Modal.planAlreadyEvaluated')}` : avaliacao === 'pendente' ? `${t('ActionPlan.Modal.selectEffectiveness')}` : `${t('ActionPlan.Modal.evaluateAs')} ${avaliacao === 'malSucedido' ? `${t('ActionPlan.Modal.unsuccessful')}` : `${t('ActionPlan.Modal.successful')}`}`}
            </button>
          </div>
        </footer>
      </div>
    </div>
  )
}
