import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { EditButton, StyledDiv } from '../TableHelpers/TableStyle';
import { RiPencilFill } from 'react-icons/ri';
import { useContext, useState, useMemo, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from "react-i18next";
import Clients, { ClientType } from "../../service/Clients";
import ChangeCustomerModal from "./ChangeCustomerModal";
import AttToken from "../../helpers/attToken";
import { useQuery } from "@tanstack/react-query";
import { ThemeProvider } from '@mui/material/styles';
import CustomerTypeAssoci from "../ServicesManagement/Modals/AssociationsModals/CustomerTypeAssoci";
import { FaNetworkWired } from "react-icons/fa";
import Table from "../Skeletons/Table";
import { tableOptions, getMuiTheme } from "../TableHelpers/options";
import { trackEventMatomo } from "../../helpers/matomo";

export default function RegisteredCustomers() {
  const { openModal, popUp } = useContext(UserContext);
  const [selectedCustomer, setSelectedCustomer] = useState<string>('');
  const customerService = useMemo(() => new Clients(), []);
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState<ClientType[]>([])
  const [modalAssoci, setModalAssoci] = useState(false);

  const { t } = useTranslation('translation');

  const {
    isFetching,
    isError: errorCustomers,
    data,
  } = useQuery({
    queryKey: ['GET_CUSTOMERS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await customerService.getAllClients(body, true);
        return result.clientList
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { isError: errorTag, data: customerTags } = useQuery({
    queryKey: ['GET_NOTIFICATIONS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await customerService.getClientTag(token);
        return response.tags
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const columns = useMemo(() => [
    {
      name: "nome",
      label: t('settingsPage.hierarchy.client'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'contato',
      label: t('tel'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'responsavel',
      label: t('customerPage.responsible'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "status",
      label: t('status'),
      options: {
        customBodyRender: (value: string) => (
          value.toLocaleLowerCase() === 'ativo' ? <span className="tag is-primary">{t('active')}</span> : <span className="tag is-danger">{t('inactive')}</span>
        ),
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "id",
      label: t('edit'),
      options: {
        customBodyRender: (value: string) => {
          return (
            <EditButton
              className="button is-small is-light"
              onClick={() => {
                trackEventMatomo('lista de cliente', 'click', 'td', 'abre modal')
                setSelectedCustomer(value)
                openModal();
              }}>
              <RiPencilFill />
              <span>{t('edit')}</span>
            </EditButton>
          )
        },
        filter: false,
        sort: false,
      }
    },
    {
      name: "id",
      label: `${t('notifications.ticket.tipoServico')}`,
      options: {
        customBodyRender: (value: string) => {
          return (
            <EditButton
              className="button is-small is-light"
              onClick={() => {
                trackEventMatomo('lista de cliente', 'click', 'td', 'abre modal')
                setSelectedCustomer(value)
                setModalAssoci(true);
              }}>
              <FaNetworkWired />
              <span>{t('notifications.ticket.AssociarTiposServico')}</span>
            </EditButton>
          )
        },
        filter: false,
        sort: false,
      }
    },
  ], [openModal, t]);

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  useEffect(() => {
    if (data && customerTags) {
      setCustomers(data)
      setLoading(false);
    }
  }, [data, customerTags])

  if (errorCustomers && errorTag) {
    return <span>{t('notifications.unread.errorLoading')}</span>
  }

  return (
    (loading || isFetching) ? (<Table />) : (
      <>
        <StyledDiv>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={t('customerPage.regCustomers')}
              data={customers}
              columns={columns}
              options={{ ...tableOptions, ...translatedTextLabels }}
            />
          </ThemeProvider>
          {popUp &&
            <ChangeCustomerModal
              selectedCustomer={selectedCustomer}
              customers={customers}
              customerTags={customerTags}
            />}
          {modalAssoci &&
            (<CustomerTypeAssoci
              open={modalAssoci}
              setOpen={setModalAssoci}
              selectedCustomer={selectedCustomer}
              customers={customers}
            />)}
        </StyledDiv>
      </>
    )
  )
}