import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import ptBR from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { createTheme, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function DatePickerInicio({ inicio, setInicio, maxDate }: { inicio: number | Date, setInicio: Function, maxDate: number | Date }) {
  const getMuiTheme = () => createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            textAlign: 'center',
            width: 110,
            maxWidth: 110,
            margin: 50
          }
        }
      },
    },
  })

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <DesktopDatePicker
          sx={{ margin: '10px !important' }}
          format='dd/MM/yyyy'
          label="Inicio"
          value={new Date(inicio ? (Number(inicio)) : Date.now())}
          maxDate={maxDate as Date}
          onChange={(newValue) => setInicio(newValue?.setHours(0, 0, 0, 0))}
          slotProps={{
            field: {
              readOnly: true,
            },
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  )
}
