import { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext'
import { H1, H2, UserCardContainer } from './UserCardStyle'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export default function UserCard() {
  const { userData, isHidden } = useContext(UserContext);
  const { t } = useTranslation('translation');

  return (
    <UserCardContainer>
      <Stack direction="row">
        <Avatar
          alt="pic profile"
          src={userData.profilePhoto}
          sx={{ width: 56, height: 56 }}
        />
      </Stack>
      {!isHidden && (
        <>
          <H1>{userData.userName}</H1>
          <H2>{userData.email}</H2>
          <H2>{`${t('profile')}: ${userData.role}`}</H2>
          <H2>{`${t('company')}: ${userData.companyName}`}</H2>
        </>
      )}
    </UserCardContainer>
  )
}
