import { useContext, useState } from "react";
import { Form } from "../../StyledComponents/Input/generic";
import Companies from "../../service/Companies";
import { useMutation } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import { trackEventMatomo } from "../../helpers/matomo";
import { useTranslation } from "react-i18next";

const empresaService = new Companies();

export default function Permissions() {
  const { userData, setUserData } = useContext(UserContext);
  const [autoriza, setAutoriza] = useState<boolean>(userData.concedeAcesso)
  const { t } = useTranslation('translation');

  const { mutate, isLoading } = useMutation({
    mutationKey: ['SET_PERMISSION'],
    mutationFn: async () => {
      toast.warning(`${t('settingsPage.saving')}`)
      const token = await AttToken()
      if (token) {
        await empresaService.concedeRetiraPermissaoLogin(token, token.userEmpresa, autoriza)
        setUserData({ ...userData, concedeAcesso: autoriza })
        const atualizado = {
          ...userData, concedeAcesso: autoriza
        }
        localStorage.setItem('userDataLocal', JSON.stringify(atualizado))
        toast.success(`${t('settingsPage.saved')}`)
      }
    },
    onError: () => toast.error(`${t('settingsPage.errorSaving')}`)
  })

  return (
    <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'gray', fontWeight: 600, fontSize: '12px' }}>
      <button
        disabled={isLoading}
        onClick={(event) => {
          trackEventMatomo('permissões', 'click', 'button', 'concede acesso suporte')
          event.preventDefault()
          setAutoriza(!autoriza)
          mutate()
        }}
        className="button is-small is-success"
      >
        {autoriza ? t('settingsPage.revokeAccess') : t('settingsPage.grantAccess')}
      </button>
      {!autoriza ? t('settingsPage.grantLoginAccessToSupport') : t('settingsPage.revokeLoginAccessToSupport')}
    </Form>
  )
}
