import { memo } from "react"
import { useTranslation } from "react-i18next"

function ModalImageOnTicket({
  image,
  open,
  setOpen,
}: {
  image: string,
  open: Boolean,
  setOpen: Function,
}) {
  const { t } = useTranslation('translation');
  return (
    <div className={`modal ${open ? "modal is-active" : "modal"}`}>
      <div
        className="modal-background"
        onClick={() => {
          setOpen()
        }}></div>
      <div className="modal-content">
        <p style={{ color: 'white' }}>
        {t('notifications.ticket.clickOutsideToClose')}
        </p>
        <img style={{ maxHeight: '75svh' }} src={image} alt={image} />
      </div>
    </div>
  )
}

export default memo(ModalImageOnTicket)