import { useRef, useState } from 'react'
import { Label } from '../StyledComponents/Input/generic';
import Teams, { SimpleTeam } from '../service/Teams';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import AttToken from '../helpers/attToken';
import Users, { userType } from '../service/Users';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { trackEventMatomo } from '../helpers/matomo';
import { useTranslation } from 'react-i18next';

type props = {
  callback: Function,
  userLabel: string,
  defaultSelected: string | null
}

type stateProps = {
  value: string,
  label: string
}

const TeamsService = new Teams();
const UsersService = new Users();

export default function UserSelector({ callback, userLabel, defaultSelected }: props) {
  const [selectingByTeam, setSlectingByTeam] = useState(false);
  const [selectedTeam, setSeletedTeam] = useState<SimpleTeam>();
  const [selectedUser, setSelectedUser] = useState<stateProps | null>(null)
  const { t } = useTranslation('translation');

  const { data: teamsData, isLoading: isLoadingTeams } = useQuery({
    queryKey: ['TeamsList'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response: SimpleTeam[] = await TeamsService.getSimpleTeams(token);
        return response;
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      toast.error(t('users.userSelector.errorSearchingTeams') as string)
    }
  });

  const { data: users, isLoading: isLoadingUsers } = useQuery({
    queryKey: ['UsersList'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result: { users: userType[] } = await UsersService.getAllUsers(body);
        return result.users
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const userSelectByTeamRef = useRef<HTMLSelectElement>(null);
  const userSelectRef = useRef<HTMLSelectElement>(null);

  if (isLoadingTeams || isLoadingUsers) return (<Skeleton height={60} width={'100%'} style={{ marginTop: '-1em' }} />)

  return (
    <div style={{ width: '100%', backgroundColor: 'white', padding: '6px', borderRadius: '10px', marginBottom: '15px' }}>
      <Label className="checkbox">
        <input
          onClick={() => trackEventMatomo('seleção de usuários', 'click', 'input', 'edita valor')}
          type="checkbox"
          onChange={(e) => {
            callback('');
            if (userSelectByTeamRef && userSelectByTeamRef.current) {
              userSelectByTeamRef.current.value = '';
            }
            if (userSelectRef && userSelectRef.current) {
              userSelectRef.current.value = '';
            }
            setSeletedTeam(undefined);
            setSelectedUser(null)
            setSlectingByTeam(e.target.checked)
          }}
          checked={selectingByTeam}
          style={{ marginRight: '5px' }}
        />
        {t('users.userSelector.filterTeams')}
      </Label>
      <div style={{ display: 'flex', flexDirection: 'column', borderLeft: '1px solid lightgrey', paddingLeft: '5px' }}>
        <Autocomplete
          onClick={() => trackEventMatomo('seleção de usuários', 'click', 'select', 'seleciona valor')}
          getOptionLabel={(option) => option.label || ''}
          value={selectingByTeam ? { value: selectedTeam?.id, label: selectedTeam?.nome } : { value: '', label: '' }}
          sx={{ marginTop: '10px' }}
          fullWidth
          onChange={(event, newValue) => {
            callback('');
            if (newValue) {
              setSeletedTeam(teamsData?.find(team => team.id === newValue.value));
            } else {
              setSeletedTeam(undefined);
            }
          }}
          options={
            (teamsData as SimpleTeam[])
              ?.sort((a, b) => a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : -1)
              ?.map((team) => ({
                label: team?.nome,
                value: team?.id,
              }))
          }
          isOptionEqualToValue={(option, value) => option === value}
          disableClearable={!selectingByTeam}
          disabled={!selectingByTeam}
          renderInput={(params) => (
            <TextField
              {...params}
              label={selectingByTeam
                ? selectedTeam
                  ? `${t('team')} ${selectedTeam?.nome} ${t('selected')}`
                  : t('users.userSelector.selectATeam')
                : t('users.userSelector.notFilteringTeams')}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.value}>
              <span>{option.label}</span>
            </li>)}
        />
        <Autocomplete
          onClick={() => trackEventMatomo('seleção de usuários', 'click', 'select', 'seleciona valor')}
          sx={{ marginTop: '10px' }}
          fullWidth
          onChange={(event, newValue) => {
            callback(newValue ? newValue.value : '');
            setSelectedUser({
              value: newValue?.value,
              label: newValue?.label
            })
          }}
          value={selectingByTeam && selectedUser?.label ? { value: selectedUser?.value, label: selectedUser?.label } : null}
          options={
            selectedTeam && selectedTeam.membros && selectedTeam.membros.length > 0
              ? selectedTeam.membros
                ?.sort((a, b) => (a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : -1))
                ?.map(
                  (membro: any) => ({
                    label: membro?.nome,
                    value: membro?.id,
                  })
                )
              : []
          }
          disableClearable={!selectingByTeam}
          isOptionEqualToValue={(option, value) => option === value}
          disabled={!selectingByTeam || !selectedTeam}
          renderInput={(params) => (
            <TextField
              {...params}
              label={selectingByTeam
                ? selectedTeam
                  ? userLabel
                  : t('users.userSelector.selectTeam')
                : t('users.userSelector.notFilteringTeams')}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.value}>
              <span>{option.label}</span>
            </li>)}
        />
        <Autocomplete
          onClick={() => trackEventMatomo('seleção de usuários', 'click', 'select', 'seleciona valor')}
          sx={{ marginTop: '10px' }}
          value={selectedUser?.value ? users?.find((ele) => ele.id === selectedUser.value) : null}
          fullWidth
          options={users?.sort((a, b) => (a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : -1)).filter((ele) => ele.status === 'ativo') || []}
          getOptionLabel={(user) => user.nome || ''}
          onChange={(event, value) => {
            if (value) {
              callback(value ? value.id : '')
              setSelectedUser({
                value: value?.id || '',
                label: users?.find((ele) => ele.id === value.id)?.nome || ''
              })
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          disabled={selectingByTeam}
          renderInput={(params) => (
            <TextField
              {...params}
              label={selectingByTeam ? t('users.userSelector.filteringTeams') : userLabel}
              variant="outlined"
              required
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <span>{option.nome}</span>
            </li>)}
          defaultValue={users?.find(user => user.id === defaultSelected) || null}
        />

      </div>
    </div>
  )
}
