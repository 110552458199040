const OpenPrintBase64: (data: string) => void = (data: string) => {
  const printWindow = window.open("", "_blank") as WindowProxy;
  printWindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Impressão de QR Code</title>
      <style>
        body {
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background-color: white;
        }
        img {
          max-width: 100%;
          max-height: 100%;
        }
      </style>
    </head>
    <body>
      <img src="${data}" alt="Imagem para impressão">
    </body>
    </html>
  `);

  // Aguarda o conteúdo carregar e chama o comando de impressão
  printWindow.document.close();
  printWindow.onload = function () {
    printWindow.print();
    printWindow.close();
  };
}

export default OpenPrintBase64;
