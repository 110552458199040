import { useState } from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import TicketsComp from "./TicketsComp";
import Notifications from "./Notifications";
import { useTranslation } from "react-i18next";
import NewTickets from "./NewTickets";
import NewNotifications from "./NewNotifications";
import NotificationOldConfigs from "./NotificationOldConfigs";
import { trackEventMatomo } from "../../helpers/matomo";

export default function TicketsTabs() {
  const { t } = useTranslation('translation');

  const mapNames = {
    tickets: t('notificationsAndTickets.tickets.title'),
    notifications: t('notificationsAndTickets.notifications.title'),
    newTickets: t('notifications.ticket.chamado2'),
    newNotifications: t('notifications.ticket.notifications2'),
    notificationConfigs: t('checklists.legacyNotificationSettings')
  }

  const [tab, setTab] = useState({
    isActiveObject: 'tickets',
    object: [
      'tickets',
      'notifications',
      'newTickets',
      'newNotifications',
      'notificationConfigs'
    ]
  });

  const toggleClass = (index: number) => {
    setTab({ ...tab, isActiveObject: tab.object[index] })
    trackEventMatomo('abas gestão de chamados', 'click', 'tab', 'muda aba')
  }

  const toggleActive = (index: number) => {
    if (tab.object[index] === tab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div style={{ width: '95%' }}>
      <div className="tabs is-boxed">
        <ul>
          {tab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {tab.isActiveObject === 'tickets' && (
        <TicketsComp />
      )}
      {tab.isActiveObject === 'notifications' && (
        <Notifications />
      )}
      {tab.isActiveObject === 'newTickets' && (
        <NewTickets />
      )}
      {tab.isActiveObject === 'newNotifications' && (
        <NewNotifications />
      )}
      {tab.isActiveObject === 'notificationConfigs' && (
        <NotificationOldConfigs />
      )}
    </div>

  )
}