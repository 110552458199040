import { Accordion, AccordionSummary, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import { logPlanoType, planoDeAcaoType } from '../../../service/PlanoDeAcao';
import { DetailsContainer } from '../style/stylesPlano';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import ImgsForDatails from './ImgsForDetails';
import { Label } from '../../Notifications/style/SingleNotificationStyle';
import moment from 'moment';
import { ImageComponent } from './ModalLogs';
import { useTranslation } from 'react-i18next';
import ModalEditPlan from './ModalEditPlan';
import { trackEventMatomo } from '../../../helpers/matomo';

type props = {
  plano: planoDeAcaoType
}

export default function AccordionDetailPlan({ plano } : props) {
  const [expanded, setExpanded] = useState(false);
  const [showDetails, setShowDetails] = useState<number | null>(null);
  const [openLogs, setOpenLogs] = useState<boolean>(false);
  const [logs, setLogs] = useState<logPlanoType[]>([
    {
      acao: '',
      user: '',
      data: 0,
      foto: '',
    }
  ]);
  const [selectedPlano, setSelectedPlano] = useState<planoDeAcaoType | null>(null);
  
  const { t } = useTranslation('translation');

  const getTagExpirationLabel = (expiration: number) => {
    if (Date.now() > expiration) {
      const now = moment(new Date());
      const limit = moment(new Date(expiration));
      const diff = Number(moment(now.diff(limit, 'milliseconds')).format('D'));
      if (diff === 0) {
        return 'is-light'
      } else if (diff > 0 && diff < 5) {
        return 'is-warning'
      } else if (diff >= 5) {
        return 'is-danger'
      } else {
        return 'is-success'
      }
    } else {
      const difference = expiration - Date.now();
      const resto = difference % 86400000;
      const diff = Number(moment(difference - resto).format('D'));
      if (diff <= 2 || difference < 86400000) {
        return 'is-warning'
      } else {
        return 'is-info'
      }
    }
  }

  const getTaskStatus = (value: string) => {
    switch (value) {
      case 'pendente':
        return (
          <span className="tag is-warning"><b>{t('ActionPlan.PlanosDeAcaoComp.pending')}</b></span>
        )
      case 'emExecucao':
        return (
          <span className="tag is-info"><b>{t('ActionPlan.PlanosDeAcaoComp.inExecution')}</b></span>
        )
      case 'concluido':
        return (
          <span className="tag is-success is-light"><b>{t('ActionPlan.PlanosDeAcaoComp.completed')}</b></span>
        )
      case 'aprovado':
        return (
          <span className="tag is-success"><b>{t('ActionPlan.PlanosDeAcaoComp.approved')}</b></span>
        )
      case 'rejeitado':
        return (
          <span className="tag is-danger"><b>{t('ActionPlan.PlanosDeAcaoComp.rejected')}</b></span>
        )
      default: return <></>
    }
  }

  const calculateTime = (limit: number) => {
    if (Date.now() > limit) {
      const a = moment(new Date())
      const b = moment(new Date(limit))
      const dias = Number(moment(a.diff(b, 'milliseconds')).format('D'));
      return `${t('ActionPlan.PlanosDeAcaoComp.ago')} ${dias} ${dias <= 1 ? t('ActionPlan.PlanosDeAcaoComp.day') : t('ActionPlan.PlanosDeAcaoComp.days')}`
    } else {
      const test = moment(limit).endOf('day').fromNow()
      return test
    }
  }

  return (
    <>
      {
        selectedPlano && (
          <ModalEditPlan
            plano={selectedPlano}
            setPlano={setSelectedPlano}
            restarting={false}
          />
        )
      }
      <Accordion key={plano.id} expanded={expanded} className="accordeon">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={() => {
              trackEventMatomo('modal detalhe plano de ação', 'click', 'acordeon', 'abre/fecha')
              setExpanded(!expanded)
            }}
          />}>
          <div className="divItensTitle">
            <div className="divTitleAcordeon">
              <span className="titleAccordeon">
                {plano.nome}
              </span>
              {plano.dataEdicao && (
                <span className="spanAcordeon">
                  {`${t('ActionPlan.PlanosDeAcaoComp.edited')} - ${new Date(plano.dataEdicao).toLocaleString('pt-BR')}`}
                </span>
              )}
              {plano.dataReinicio && (
                <span className="spanAcordeon">
                  {`${t('ActionPlan.PlanosDeAcaoComp.reopened')} - ${new Date(plano.dataReinicio).toLocaleString('pt-BR')}`}
                </span>
              )}
              <span className="spanAcordeon">
              {t('ActionPlan.PlanosDeAcaoComp.createdBy')} <b>{plano.criadorNome ? plano.criadorNome : '-'}</b> {t('ActionPlan.PlanosDeAcaoComp.on')} {new Date(Number(plano?.dataCriacao))?.toLocaleString('pt-BR')} {t('ActionPlan.PlanosDeAcaoComp.fromChecklist')} <b>{plano.idExecucao}</b>
              </span>
              <span className="spanAcordeon">{t('ActionPlan.PlanosDeAcaoComp.planResponsible')} <b>{plano.responsavelNome ? plano.responsavelNome : plano.responsavel}</b></span>
              <span className="spanAcordeon">{t('ActionPlan.PlanosDeAcaoComp.planDeadline')} <b>{new Date(plano.dataLimite).toLocaleDateString('pt-BR')}</b></span>
            </div>
            {plano.status === 'aberto' && (
              <button
                onClick={() => {
                  setSelectedPlano(plano);
                }}
                style={{ marginRight: '10px' }}
                className="button is-small is-info">
                Editar Plano
              </button>
            )}
          </div>
        </AccordionSummary>
        <div className="acordeonDetail">
          <ol>
            <span>
              <b>
              {t('ActionPlan.PlanosDeAcaoComp.nonConformitiesOfThisPlan')}
              </b>
            </span>
            {plano.naoConformidades.map((naoConf, i) => (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div className="itensList" style={{ marginBottom: '2px', paddingTop: '2px', paddingBottom: '2px', marginTop: '2px' }}>
                  <li>
                    {naoConf.ocorrencia && naoConf.ocorrencia} {naoConf.ocorrencia && <br />}
                    {naoConf.nome}
                    <button
                      onClick={() => {
                        trackEventMatomo('modal detalhe plano de ação', 'click', 'button', 'mostra detalhes subitem')
                        setShowDetails((prev) => prev !== null && prev === i ? null : i)
                      }}
                      className="button is-small is-white">
                      {showDetails !== null && showDetails === i ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </li>
                </div>
                {showDetails !== null && showDetails === i && (
                  <DetailsContainer>
                    <span>{t('ActionPlan.PlanosDeAcaoComp.evaluation')}</span>
                    {naoConf.avaliacao && naoConf.avaliacao.map(ava => (
                      <p style={{ fontWeight: `${naoConf.nota === ava.valor ? 'bold' : '100'}` }}>{ava.nome}</p>
                    ))}
                    {naoConf.ocorrencia && <span>{`${t('ActionPlan.PlanosDeAcaoComp.comment')} ${naoConf.ocorrencia}`}</span>}
                    {naoConf.img &&
                      <>
                        <span>{t('ActionPlan.PlanosDeAcaoComp.images')}</span>
                        <ImgsForDatails images={naoConf.img} />
                      </>
                    }
                  </DetailsContainer>
                )}
              </div>
            ))}
          </ol>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', borderBottom: '1px solid lightgrey', paddingBottom: '10px' }}>
            <span>
              <b>
              {t('ActionPlan.PlanosDeAcaoComp.tasks')}
              </b>
            </span>
          </div>
          <ol>
            {plano.tasks?.map((task, taskIndex) =>
              <div key={taskIndex} style={{ margin: '10px' }}>
                <div>
                  <div className="itemDetail">
                    <div className="divtitleBtn">
                      <Tooltip title={task.log ? t('ActionPlan.PlanosDeAcaoComp.viewLogs') : t('ActionPlan.PlanosDeAcaoComp.noLogsAvailable')}>
                        <button
                          onClick={() => {
                            trackEventMatomo('modal detalhe plano de ação', 'click', 'button', 'mostra detalhes logs')
                            setLogs([...task.log as logPlanoType[]])
                            setOpenLogs(!openLogs)
                          }}
                          disabled={!task.log}
                          className="button is-small is-white buttonEye">
                          <FaEye />
                        </button>
                      </Tooltip>
                      {openLogs && (
                        <div className={`modal ${openLogs ? 'is-active' : ''}`}>
                          <div className="modal-background"></div>
                          <div className="modal-card">
                            <header className="modal-card-head">
                              <p className="modal-card-title">Logs</p>
                              <button 
                              onClick={() => {
                                trackEventMatomo('modal detalhe plano de ação', 'click', 'button', 'fecha logs')
                                setOpenLogs(!openLogs)}} 
                                className="delete" 
                                aria-label={t('close')} />
                            </header>
                            <section className="modal-card-body">
                              <ol>
                                {logs?.map((log: logPlanoType, i: number) =>
                                  <li key={i} className="li">
                                    {log.acao}
                                    {log.foto && <ImageComponent linkFoto={log.foto} />}
                                    <hr />
                                  </li>
                                )}
                              </ol>
                            </section>
                          </div>
                        </div>
                      )}
                      <li>
                        <span className="spanItemTitle">{task.nome}</span><br />
                        ({t('ActionPlan.PlanosDeAcaoComp.responsible')} <b>{task.responsavelNome ? task.responsavelNome : task.responsavel}</b>)<br />
                        {task.detalhes &&
                          <>
                            <Label>{t('ActionPlan.PlanosDeAcaoComp.details')}</Label><span>{task.detalhes}</span>
                          </>}
                      </li>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '30%' }}>
                      <span className="tag is-white">{t('ActionPlan.PlanosDeAcaoComp.status')} </span>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {getTaskStatus(task.status)}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px', marginBottom: '5px' }}>
                        <span className="tag is-white">{t('Reports.ticketList.deadline')} </span>
                        <span className={`tag ${getTagExpirationLabel(task.dataLimite)}`}>{new Date(task.dataLimite).toLocaleDateString('pt-BR')}</span>
                      </div>
                      {Date.now() > task.dataLimite && (
                        <div>
                          <span className={`tag ${getTagExpirationLabel(task.dataLimite)}`}>{t('ActionPlan.PlanosDeAcaoComp.expiredTask')} {calculateTime(task.dataLimite)}</span>
                        </div>
                      )}
                      {Date.now() <= task.dataLimite && (
                        <div>
                          <span className={`tag ${getTagExpirationLabel(task.dataLimite)}`}>{t('ActionPlan.PlanosDeAcaoComp.taskExpires')} {calculateTime(task.dataLimite)}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ol>
        </div>
      </Accordion>
    </>
  )
}
