//import { useState } from 'react'
import InputMask from 'react-input-mask';
import { SubItemProps } from './Avaliacao/Avaliacao';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SubItemInputMaskProps extends SubItemProps {
  mask: string
  type?: string,
}

const SubItemInputMask = ({ subItem, mask, type = 'text', onChange }: SubItemInputMaskProps) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation('translation');
  useEffect(() => {
    if (subItem.padrao) {
      setValue(subItem.padrao)
      subItem.ocorrencia = subItem.padrao
    }
    onChange && onChange()
  }, [])

  const borderCheckerComent = () => {
    const comentario = subItem?.ocorrencia === '' || !subItem?.ocorrencia;
    if (!subItem.obrigatoriedade?.preenchimento && comentario) return '0.3em #2196f3 solid'
    if (subItem?.obrigatoriedade?.preenchimento && comentario) return '0.3em red solid'
    if (subItem.obrigatoriedade?.preenchimento && comentario) return '0.3em red solid'
    if (subItem.tipo === 'cep') {
      if (value?.length !== mask?.length) return '0.3em #FB8E27 solid'
      if (value?.length === mask?.length) return '0.3em #4caf50 solid'
    }
    if (subItem.tipo === 'rg') {
      if (value?.length !== mask?.length) return '0.3em #FB8E27 solid'
      if (value?.length === mask?.length) return '0.3em #4caf50 solid'
    }
  }

  return (
    <InputMask
      mask={mask}
      maskChar=''
      alwaysShowMask={false}
      className="input"
      type={type}
      placeholder={t('Filling.fillSubitens.tapToType')}
      style={{ borderBottom: `${borderCheckerComent()}` }}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        subItem.ocorrencia = e.target.value;
      }}
    />
  )
}

export default SubItemInputMask