import React, { useEffect, useState } from 'react'
import PlanoDeAcao, { planoDeAcaoType, taskType } from '../../../service/PlanoDeAcao';
import { PlanoDatailContainer, SpanBelongs, Title } from '../style/stylesPlano';
import { Label } from '../../Notifications/style/SingleNotificationStyle';
import AttToken from '../../../helpers/attToken';
import Loading from '../../Loading/Loading';
import queryClient from '../../../service/query';
import { toast } from 'react-toastify';
import getEndOfToday from '../../../helpers/getEndOfToday';
import UserSelector from '../../UserSelector';
import { TextField, Tooltip } from '@material-ui/core';
import { BiArrowFromBottom, BiArrowFromTop, BiPlus, BiTrash } from 'react-icons/bi';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import ImgsForDatails from './ImgsForDetails';
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { trackEventMatomo } from '../../../helpers/matomo';
import { useTranslation } from 'react-i18next';


type props = {
  openModal: boolean,
  setOpenModal: Function,
  planos: planoDeAcaoType[],
  setPlanos: Function,
  closeFatherModal: Function,
  analiseId: string
}

type FormPaProps = {
  plano: planoDeAcaoType,
  setPlanos: Function,
  planos: planoDeAcaoType[],
  i: number,
}

type TaskCompProps = {
  index: number,
  task: taskType
  setPlanos: Function,
  planos: planoDeAcaoType[],
  plano: planoDeAcaoType,
  setMinDateValue: Function
}

const planoService = new PlanoDeAcao();

const TaskComp = ({ task, index, planos, setPlanos, plano, setMinDateValue }: TaskCompProps) => {
  const { t } = useTranslation('translation');
  const handleAddTaskResponsible = (uid: string) => {
    task.responsavel = uid;
    setPlanos([...planos]);
  }

  return (
    <PlanoDatailContainer key={index}>

      <div className='tasks'>
        <TextField
          onClick={() => trackEventMatomo('modal abre PA', 'click', 'textarea', 'edita valor')}
          fullWidth
          style={{ marginBottom: '10px' }}
          label='Nome da tarefa'
          value={task.nome}
          onChange={(e) => {
            task.nome = e.target.value;
            setPlanos([...planos]);
          }}
          required
        />
        <div style={{ color: 'gray', fontSize: '12px', fontWeight: '600' }}>
          <span>{t('ActionPlan.Modal.responsible')}</span>
          <UserSelector
            callback={handleAddTaskResponsible}
            userLabel={'Selecione o usuário responsável pela Tarefa'}
            defaultSelected={task.responsavel || null}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <DesktopDatePicker
            label="Realizar tarefa até"
            minDate={getEndOfToday().endDate}
            maxDate={plano.dataLimite ? new Date(plano.dataLimite) : undefined}
            value={task.dataLimite ? new Date(task.dataLimite) : new Date()}
            onChange={(date) => {
              trackEventMatomo('modal abre PA', 'click', 'datepicker', 'edita data')
              if (!date) return;

              const thisMilisseconds = new Date(date).getTime();
              let minDate = thisMilisseconds;
              plano.tasks.forEach((task, i) => {
                if (i !== index && task.dataLimite > minDate) {
                  minDate = task.dataLimite;
                }
              });

              setMinDateValue(new Date(minDate));
              task.dataLimite = new Date(date).getTime();
              setPlanos([...planos]);
            }}
            disableFuture={false}
            slotProps={{
              textField: {
                required: true,
                onKeyDown: (e) => e.preventDefault(),
                sx: {
                  maxWidth: 200,
                  marginTop: '10px',
                  backgroundColor: 'white',
                  '& .MuiInputBase-input': {
                    height: 'calc(1em + 10px)',
                    display: 'flex',
                    padding: '10px',
                    alignItems: 'center',
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
        <Label style={{ display: 'flex', alignItems: 'center' }} className='checkbox'>
          <span style={{ width: '27%' }}>{t('ActionPlan.Modal.evidenceRequired')}&nbsp;</span>
          <input
            onClick={() => trackEventMatomo('modal abre PA', 'click', 'input', 'edita valor')}
            className='checkbox'
            type='checkbox'
            checked={task.obrigatorioEvidencia}
            onChange={(e) => {
              task.obrigatorioEvidencia = e.target.checked;
              setPlanos([...planos]);
            }}
          />
        </Label>
        <Label style={{ display: 'flex', alignItems: 'center' }} className='checkbox'>
          <textarea
            onClick={() => trackEventMatomo('modal abre PA', 'click', 'input', 'edita valor')}
            className='textarea has-fixed-size'
            onChange={(e) => {
              task.detalhes = e.target.value;
              setPlanos([...planos]);
            }}
            placeholder={t('ActionPlan.Modal.detailTaskOptional')}
            maxLength={1000}
          />
        </Label>
        <button
          onClick={() => {
            trackEventMatomo('modal abre PA', 'click', 'button', 'remove task')
            const newTasks = plano.tasks.filter((el, ind) => ind !== index);
            plano.tasks = newTasks;
            setPlanos([...planos]);
          }}
          className='button is-danger'
        >
          <BiTrash />
        </button>
      </div>
    </PlanoDatailContainer>
  )
}

const FormPA = ({ plano, setPlanos, planos, i }: FormPaProps,) => {
  const [minDateValue, setMinDateValue] = useState<Date>(getEndOfToday().endDate);
  const [inverteOrdem, setInverteOrdem] = useState(true);
  const [showSubDetail, setShowSubDetail] = useState<boolean>(false);
  const { t } = useTranslation('translation');

  const addTask = () => {
    const defaultTask: taskType = {
      nome: 'Nova Tarefa',
      status: 'pendente',
      responsavel: '',
      obrigatorioEvidencia: false,
      detalhes: '',
      dataLimite: getEndOfToday().endDate.getTime(),
    }
    if (!plano.tasks || !plano.tasks.length) {
      plano.tasks = [defaultTask]
    } else { plano.tasks.push(defaultTask) }
    setPlanos([...planos])
  }

  const handleAddPlanResponsible = (uid: string) => {
    plano.responsavel = uid;
    setPlanos([...planos]);
  }

  useEffect(() => {
    console.log(plano.naoConformidades)
  }, [])

  return (
    <PlanoDatailContainer key={i}>
      <Title>{t('ActionPlan.Modal.planOptions')}</Title>
      <TextField
        onClick={() => trackEventMatomo('modal abre PA', 'click', 'input', 'edita valor')}
        fullWidth
        label={t('ActionPlan.Modal.actionPlanName')}
        value={plano.nome}
        onChange={(e) => {
          plano.nome = e.target.value;
          setPlanos([...planos])
        }}
        required
        style={{ marginBottom: '10px' }}
      />
      <div>
        <span style={{ color: 'gray', fontSize: '12px', fontWeight: '600' }}>{t('ActionPlan.Modal.actionPlanResponsible')}&nbsp;</span>
        <UserSelector
          callback={handleAddPlanResponsible}
          userLabel={t('ActionPlan.Modal.selectPlanResponsible')}
          defaultSelected={plano.responsavel || null}
        />
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <DesktopDatePicker
          label={t('ActionPlan.Modal.finishPlanBy')}
          minDate={minDateValue}
          value={plano.dataLimite ? new Date(plano.dataLimite) : new Date()}
          onChange={(date) => {
            trackEventMatomo('modal abre PA', 'click', 'datepicker', 'edita data')
            if (!date) return;
            plano.dataLimite = new Date(date).getTime();
            setPlanos([...planos]);
          }}
          slotProps={{
            textField: {
              required: true,
              onKeyDown: (e) => e.preventDefault(),
              sx: {
                maxWidth: 200,
                marginTop: '10px',
                backgroundColor: 'white',
                '& .MuiInputBase-input': {
                  height: 'calc(1em + 10px)',
                  display: 'flex',
                  padding: '10px',
                  alignItems: 'center',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
      <Title>{t('Reports.columns.notAccording')}:</Title>
      <div className='naoConfs'>
        {plano.naoConformidades.map((sub, index) => (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span key={index} style={{ wordBreak: 'break-all', maxWidth: '95%' }}>{index + 1} - {sub.nome}</span>
              <button
                onClick={(event) => {
                  trackEventMatomo('modal abre PA', 'click', 'button', 'exibe nConf')
                  event.preventDefault()
                  setShowSubDetail(!showSubDetail)
                }}
                className='button is-small is-ghost'>
                {!showSubDetail
                  ? <FaPlus />
                  : <FaMinus />}
              </button>
            </div>
            {showSubDetail && <div style={{ marginTop: '10px', fontSize: '15px' }}>
              <span>{t('ActionPlan.Modal.evaluation')}</span>
              {sub.avaliacao && sub.avaliacao.map(ava => (
                <p style={{ fontSize: '14px', fontWeight: `${sub.nota === ava.valor ? 'bold' : '100'}` }}>{ava.nome}</p>
              ))}
              {sub.ocorrencia && <span>{`${t('ActionPlan.Modal.comment')} ${sub.ocorrencia}`}</span>}
              {sub.img &&
                <>
                  <span>{t('ActionPlan.Modal.images')}</span>
                  <ImgsForDatails images={sub.img} />
                </>
              }
            </div>}
          </>
        ))}
      </div>
      <Title>{t('ActionPlan.Modal.defineTasksForThisPlan')}</Title>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-around', marginTop: '15px' }}>
        <Tooltip title="Adicionar Tarefa" style={{ width: '25%' }}>
          <button
            type='button'
            className='button is-success is-small'
            onClick={() => {
              trackEventMatomo('modal abre PA', 'click', 'button', 'add task')
              addTask()
            }}
          >
            <BiPlus />
          </button>
        </Tooltip>
        <Tooltip title={`${t('ActionPlan.Modal.viewTasks')} ${inverteOrdem ? `${t('ActionPlan.Modal.oldestFirst')}` : `${t('ActionPlan.Modal.newestFirst')}`} ${t('ActionPlan.Modal.creationOrder')}`} style={{ width: '25%' }}>
          <button
            onClick={() => {
              trackEventMatomo('modal abre PA', 'click', 'button', 'ordena tasks')
              setInverteOrdem(!inverteOrdem)
            }}
            className='button is-small is-info'
            type='button'
          >
            {inverteOrdem ? <BiArrowFromBottom /> : <BiArrowFromTop />}
          </button>
        </Tooltip>
      </div>
      {(!plano.tasks || !plano.tasks.length) && <SpanBelongs>{t('ActionPlan.Modal.noTasksCreatedYet')}</SpanBelongs>}
      <div style={{ display: 'flex', flexDirection: inverteOrdem ? 'column-reverse' : 'column' }}>
        {plano.tasks && plano.tasks.map((task, index) => (
          <TaskComp
            task={task}
            index={index}
            planos={planos}
            setPlanos={setPlanos}
            plano={plano}
            setMinDateValue={setMinDateValue}
          />
        ))}
      </div>
    </PlanoDatailContainer>
  )
}

export default function OpenPlanoModal({
  openModal,
  setOpenModal,
  planos,
  setPlanos,
  closeFatherModal,
  analiseId
}: props) {
  const [sending, setSending] = useState(false);
  const { t } = useTranslation('translation');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSending(true);
    const token = await AttToken();
    if (token) {
      await planoService.startPlano(token, analiseId, planos);
      queryClient.resetQueries(['GET_ANALISES_PENDING']);
      queryClient.resetQueries(['GET_ANALISES_FINALIZADAS']);
      setSending(false);
      setOpenModal();
      closeFatherModal();
      toast.success(`${planos.length} ${planos.length > 1 ? `${t('ActionPlan.Modal.openPlans')} ` : `${t('ActionPlan.Modal.planOpened')} `}`);
    } else {
      setSending(false);
      toast.error(`${t('ActionPlan.Modal.failedToOpenPlans')} `);
    }
  }

  return (
    <div className={`modal ${openModal ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{`Abrindo ${planos.length} ${planos.length > 1 ? `${t('ActionPlan.Modal.plans')} ` : `${t('ActionPlan.Modal.plan')} `}`}</p>
          <button
            onClick={() => {
              trackEventMatomo('modal abre PA', 'click', 'button', 'fecha modal')
              setOpenModal()
            }}
            className="delete"
            aria-label="fechar"
            type='button'
            disabled={sending}
          />
        </header>
        <section className="modal-card-body">
          {!sending ? (
            <form
              onSubmit={(e) => {
                trackEventMatomo('modal abre PA', 'click', 'button', 'salva')
                handleSubmit(e)
              }}>
              {planos.length && planos.map((plan, index) => (
                <FormPA
                  plano={plan}
                  planos={planos}
                  setPlanos={setPlanos}
                  i={index}
                />
              ))}
              <button
                className='button is-success is-fullwidth'
                disabled={planos.some(plano => !plano.tasks || !plano.tasks.length)}
                type='submit'
              >
                {`${t('ActionPlan.Modal.finishAndOpen')} ${planos.length > 1 ? `${t('ActionPlan.Modal.actionPlans')}` : `${t('ActionPlan.Modal.actionPlan')}`}`}
              </button>
            </form>
          ) : (
            <div>
              <h1>{t('ActionPlan.Modal.openingPlans')}</h1>
              <Loading />
            </div>
          )}
        </section>
      </div>
    </div>
  )
}
