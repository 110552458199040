import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserContext } from '../../context/UserContext';
import Companies from '../../service/Companies';
import { Form } from '../../StyledComponents/Input/generic';
import AttToken from '../../helpers/attToken';
import { Skeleton, TextField } from '@mui/material';
import { trackEventMatomo } from '../../helpers/matomo';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation } from '@tanstack/react-query';

const companiesSrvc = new Companies();

export type HierarchyLabel = {
  cliente: string,
  local: string,
  conjunto: string,
  unidade: string,
  gerencia: string,
}

const initialHierarchy = {
  cliente: '',
  local: '',
  conjunto: '',
  unidade: '',
  gerencia: '',
}

export default function HierarchyConfig() {
  const { hierarchyLabel, setHierarchyLabel } = useContext(UserContext);
  const [hierarchy, setHierarchy] = useState(initialHierarchy);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('translation');

  const editLabels = async (value: HierarchyLabel) => {
    setLoading(true);
    try {
      const token = await AttToken()
      if (token) {
        const { message } = await companiesSrvc.editHierarchyLabel({
          ...token, empresa: token.userEmpresa,
          labels: { ...value, default: false }
        });
        message && toast.success(message);
        setHierarchyLabel(hierarchy);
        setHierarchy(initialHierarchy);
      }
    } catch (err) {
      return console.log(err)
    }
    setLoading(false);
  }

  const getAllFunc = useCallback(async () => {
    try {
      const token = await AttToken();
      if (token) {
        const result = await companiesSrvc.getHierarchyLabels({ ...token, empresa: token.userEmpresa });
        setHierarchy(result);
      }
    } catch (err) {
      return console.log(err)
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getAllFunc();
  }, [loading, getAllFunc])

  return (
    <div>
      <Form>
        <h1>{t('settingsPage.hierarchy.chooseLabel')}</h1>
        {loading
          ? <>
            <Skeleton variant='rounded' height={60} sx={{ marginTop: '15px' }} />
            <Skeleton variant='rounded' height={60} sx={{ marginTop: '15px' }} />
            <Skeleton variant='rounded' height={60} sx={{ marginTop: '15px' }} />
            <Skeleton variant='rounded' height={60} sx={{ marginTop: '15px' }} />
            <Skeleton variant='rounded' height={60} sx={{ marginTop: '15px' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
              <Skeleton variant='rounded' height={40} width={200} />
              <Skeleton variant='rounded' height={40} width={200} />
            </div>
          </>
          : <>
            <TextField
              onClick={() => trackEventMatomo('tela de label de hierarquia', 'click', 'textfield', 'edita valor')}
              sx={{ width: '100%', marginTop: '15px' }}
              label={`${hierarchyLabel.cliente} - (${t('settingsPage.hierarchy.client')})`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHierarchy({ ...hierarchy, cliente: e.target.value })
              }}
              value={hierarchy.cliente}
            />
            <TextField
              onClick={() => trackEventMatomo('tela de label de hierarquia', 'click', 'textfield', 'edita valor')}
              sx={{ width: '100%', marginTop: '15px' }}
              label={`${hierarchyLabel.local} - (${t('settingsPage.hierarchy.place')})`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHierarchy({ ...hierarchy, local: e.target.value })
              }}
              value={hierarchy.local}
            />
            <TextField
              onClick={() => trackEventMatomo('tela de label de hierarquia', 'click', 'textfield', 'edita valor')}
              sx={{ width: '100%', marginTop: '15px' }}
              label={`${hierarchyLabel.conjunto} - (${t('settingsPage.hierarchy.set')})`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHierarchy({ ...hierarchy, conjunto: e.target.value })
              }}
              value={hierarchy.conjunto}
            />
            <TextField
              onClick={() => trackEventMatomo('tela de label de hierarquia', 'click', 'textfield', 'edita valor')}
              sx={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}
              label={`${hierarchyLabel.unidade} - (${t('settingsPage.hierarchy.unity')})`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHierarchy({ ...hierarchy, unidade: e.target.value })
              }}
              value={hierarchy.unidade}
            />
            <TextField
              onClick={() => trackEventMatomo('tela de label de hierarquia', 'click', 'textfield', 'edita valor')}
              sx={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}
              label={`${hierarchyLabel.gerencia ? hierarchyLabel.gerencia : ''} - (${t('Reports.columns.manegement')})`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHierarchy({ ...hierarchy, gerencia: e.target.value })
              }}
              value={hierarchy.gerencia}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Button
                onClick={(e) => {
                  trackEventMatomo('tela de configurações', 'click', 'form', 'edita hierarquia')
                  e.preventDefault();
                  editLabels(hierarchy);
                  setHierarchyLabel(hierarchy);
                  setHierarchy(initialHierarchy);
                }}
                color='success'
                disabled={
                  hierarchy.cliente?.length < 3 ||
                  hierarchy.local?.length < 3 ||
                  hierarchy.conjunto?.length < 3 ||
                  hierarchy.unidade?.length < 3 ||
                  hierarchy.gerencia?.length < 3
                }
                className="button is-success is-small"
              >
                {t('settingsPage.hierarchy.changeBtn')}&nbsp;<EditIcon />
              </Button>
              <Button onClick={(e) => {
                e.preventDefault()
                editLabels({
                  cliente: 'Cliente',
                  local: 'Local',
                  conjunto: 'Conjunto',
                  unidade: 'Unidade',
                  gerencia: 'Gerência',
                })
              }} >{t('settingsPage.hierarchy.resetValues')}&nbsp;<RestartAltIcon /></Button>
            </div>
          </>
        }
      </Form>
    </div>
  )
}
