import { useMutation } from '@tanstack/react-query'
import { GenericHeader } from '../../../StyledComponents/Modal/generic'
import AttToken from '../../../helpers/attToken'
import { useState } from 'react';
import Aprovations from '../../../service/Aprovations';
import { toast } from 'react-toastify';
import queryClient from '../../../service/query';
import { AxiosError } from 'axios';
import { ChecklistReportType } from '../../../service/Reports';
import { trackEventMatomo } from '../../../helpers/matomo';
import { useTranslation } from 'react-i18next';

const aprovationService = new Aprovations();

export default function ModalAprovation({
  openAprovation,
  setOpenAprovation,
  aprove,
  setAprove,
  checklist,
  usuarioExecutor,
  setOpenModal,
  selected
}: {
  openAprovation?: boolean,
  setOpenAprovation?: Function,
  aprove: string,
  setAprove?: Function,
  checklist: number,
  usuarioExecutor: string,
  setOpenModal?: Function,
  selected?: ChecklistReportType
}) {

  const [comment, setComment] = useState('');

  const { t } = useTranslation('translation');

  const { mutate } = useMutation({
    mutationKey: ['UPDATE_APROVATION'],
    mutationFn: async () => {
      try {
        toast.warn(`${t('toast.pleaseWaitSaving')}`)
        const token = await AttToken();
        if (token) {
          const response = await aprovationService.updateAprovation(
            token,
            comment,
            aprove,
            usuarioExecutor,
            checklist,
          )
          if (response.error) {
            return `${t('Aprovations.errorApprovingForm')}`
          }
          return response
        }
      } catch (err) {
        console.log(err)
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_APROVATIONS'])
      setOpenModal && setOpenModal(false)
      toast.success(`${t('Aprovations.form')} ${aprove}`)
    },
    onError: () => {
      console.log(AxiosError)
    }
  })

  return (
    <div className={`modal ${openAprovation ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header style={{ display: 'flex', justifyContent: 'center' }} className="modal-card-head">
          <GenericHeader>
            {aprove === 'aprovado' ? `${t('Aprovations.approve')}` : `${t('Aprovations.reject')}`} {t('Aprovations.form')}
          </GenericHeader>
        </header>
        <section className="modal-card-body">
          <span style={{ fontSize: '.7em' }}>
            {t("Aprovations.attention")}<br />
            {t("Aprovations.observation")} {aprove === 'aprovado' ? t("Aprovations.aprovation") : t("Aprovations.disapproval")}.
          </span>
          <input
            value={comment}
            onClick={() =>  trackEventMatomo('Modal de aprovação', 'click', 'input', 'adiciona comentário')}
            onChange={(e) => {
              setComment(e.target.value)
            }
            }
            className='input' placeholder={t('Aprovations.addObservation')} />
        </section>
        <footer className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type="button"
            disabled={selected?.mandatory ? comment.length ? false : true : false}
            onClick={(e) => {
              e.preventDefault()
              mutate()
              trackEventMatomo('Modal de aprovação', 'click', 'button', 'aprova')
            }}
            className="button is-success">
            {t("yes")}</button>
          <button className="button is-danger" type="button"
            onClick={() => {
              trackEventMatomo('Modal de aprovação', 'click', 'button', 'reprova')
              setOpenAprovation && setOpenAprovation(false)
              setAprove && setAprove('')
            }}>{t("no")}</button>
        </footer>
      </div>
    </div>
  )
}
