import { MainContainerMulti } from './Multiple/MultipleStyle';
import { Container } from './Unique/UniqueStyle';
import { SubItemProps } from './Avaliacao/Avaliacao';
import SubItemPhoto from './SubItemPhoto';
import { useEffect, useState } from 'react';
import { useMask } from '@react-input/mask';
import { useTranslation } from 'react-i18next';

export default function Telefone({ subItem, indexItem, onChange }: SubItemProps) {
  const inputRef = useMask({ mask: '(__) ____-_____', replacement: '_' });
  const [value, setValue] = useState<string | null>(null);
  const { t } = useTranslation('translation');

  useEffect(() => {
    onChange && onChange()
  }, [value])  

  const borderCheckerComent = () => {
    const comentario = subItem?.ocorrencia === '' || !subItem?.ocorrencia;
    if (!subItem.obrigatoriedade?.preenchimento && comentario) return '0.3em #2196f3 solid'
    if (subItem?.obrigatoriedade?.preenchimento && comentario) return '0.3em red solid'
    if (subItem.obrigatoriedade?.preenchimento && comentario) return '0.3em red solid'
    if (value) {
      if (value?.length < 14) return '0.3em #FB8E27 solid'
      if (value?.length >= 14) return '0.3em #4caf50 solid'
    }
  }


  return (
    <MainContainerMulti>
      <Container>
        <input
          ref={inputRef}
          className="input"
          type='tel'
          placeholder={t('Filling.fillSubitens.tapToType')}
          style={{ borderBottom: `${borderCheckerComent()}` }}
          value={value as string}
          onChange={(e) => {
            setValue(e.target.value);
            subItem.ocorrencia = e.target.value;
          }}
        />
        {subItem.ocorrencia && subItem.foto &&
          <SubItemPhoto subItem={subItem} indexItem={indexItem} onChange={onChange} />}
      </Container>
    </MainContainerMulti>
  )
}