import { useCallback, useEffect, useState } from 'react';
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { ChecklistReportType } from '../../../service/Reports';
import { trackEventMatomo } from '../../../helpers/matomo';
import Grid from '@mui/material/Grid';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';


type props = {
  openModal: boolean,
  setOpenModal: Function,
  form: ChecklistReportType
}

export default function ModalExportCSV({ openModal, setOpenModal, form }: props) {
  const { t } = useTranslation('translation');

  const escape = useCallback((e: any): void => {
    if (e.key === 'Escape' && openModal) {
      setOpenModal && setOpenModal(false);
    }
  }, [openModal, setOpenModal])

  useEffect(() => {
    document.addEventListener('keydown', escape, true)
  }, [escape]);

  const [headerOptions, setHeaderOptions] = useState({
    [t("csv.setName")]: {
      active: true,
      where: 'form',
      key: 'conjunto'
    },
    [t("csv.clientName")]: {
      active: true,
      where: 'form',
      key: 'cliente'
    },
    [t("csv.unityName")]: {
      active: true,
      where: 'form',
      key: 'unidade'
    },
    [t("csv.userName")]: {
      active: true,
      where: 'form',
      key: 'user'
    },
    [t("csv.placeName")]: {
      active: true,
      where: 'form',
      key: 'local'
    },
    [t("csv.manegementName")]: {
      active: true,
      where: 'form',
      key: 'gerencia'
    },
    [t("csv.start")]: {
      active: true,
      where: 'form',
      key: 'inicio'
    },
    [t("csv.end")]: {
      active: true,
      where: 'form',
      key: 'fim'
    },
    [t("csv.formName")]: {
      active: true,
      where: 'form',
      key: 'formulario'
    },
    [t("csv.subType")]: {
      active: true,
      where: 'sub',
      key: 'tipo'
    },
    [t("csv.ocurrence")]: {
      active: true,
      where: 'sub',
      key: 'ocorrencia'
    },
    [t("csv.itemName")]: {
      active: true,
      where: 'item',
      key: 'nome'
    },
    [t("csv.teams")]: {
      active: true,
      where: 'form',
      key: 'equipes'
    },
    [t("csv.range")]: {
      active: true,
      where: 'form',
      key: 'range'
    },
    [t("csv.aprovation")]: {
      active: true,
      where: 'form',
      key: 'aprovaco'
    },
    [t("csv.scoring")]: {
      active: true,
      where: 'form',
      key: 'scorePorcentagem'
    },
    [t("csv.subName")]: {
      active: true,
      where: 'sub',
      key: 'nome'
    },
    [t("csv.distance")]: {
      active: true,
      where: 'form',
      key: 'distancia'
    },
    [t("csv.evaluationItens")]: {
      active: true,
      where: 'sub',
      key: 'avaliacao'
    },
    [t("csv.grade")]: {
      active: true,
      where: 'sub',
      key: 'nota'
    },
    [t("csv.subitemWeight")]: {
      active: true,
      where: 'sub',
      key: 'peso'
    },
    [t("csv.itemWeight")]: {
      active: true,
      where: 'item',
      key: 'peso'
    },
    [t("csv.subScoring")]: {
      active: true,
      where: 'sub',
      key: 'scorePorcentagem'
    },
    [t("csv.itemScoring")]: {
      active: true,
      where: 'item',
      key: 'scorePorcentagem'
    },
    [t('Reports.columns.notAccording')]: {
      active: true,
      where: 'sub',
      key: 'naoConforme',
    },
    [t('Reports.columns.according')]: {
      active: true,
      where: 'sub',
      key: 'conforme',
    },
    [t('Reports.columns.notAccording')]: {
      active: true,
      where: 'form',
      key: 'naoConforme',
    },
    [t('Reports.columns.according')]: {
      active: true,
      where: 'form',
      key: 'conforme',
    },
    'Lista de textos': {
      active: true,
      where: 'item',
      key: 'list'
    },
    'Selecionado': {
      active: true,
      where: 'sub',
      key: "unique"
    },
    'Selecionados': {
      active: true,
      where: 'sub',
      key: 'multiple'
    }
  });
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [firstRender, setFirstRender] = useState(true);

  const prapareCSVDATA = useCallback(() => {
    const totalData: string[][] = [];
    const headers: (keyof typeof headerOptions)[] = [];
    Object?.keys(headerOptions)?.forEach(e => {
      if (headerOptions[e].active) {
        headers?.push(e)
      }
    })
    totalData.push(headers as string[]);
    if (Array.isArray(form)) {
      form?.forEach((elem) => {
        elem.itens?.forEach((item: any) => {
          item.subitens?.forEach((sub: any) => {
            if (sub.checked) {
              const thisRow: string[] = [];
              headers.length && headers?.forEach(e => {
                if (headerOptions[e].where === 'form') {
                  if (headerOptions[e].key === 'inicio' || headerOptions[e].key === 'fim') {
                    const data = elem[headerOptions[e].key as unknown as keyof typeof form];
                    const newValue = data ? new Date(data as number).toLocaleString('pt-BR') : '';
                    thisRow.push(newValue);
                  } else if (headerOptions[e].key === 'equipes') {
                    let teamNames = '';
                    Object?.keys(elem.equipes)?.forEach((e, i) => {
                      const equipe = elem.equipes[e].nome;
                      Object?.keys(elem.equipes).length - 1 === i ? teamNames += equipe : teamNames += `${equipe}, `
                    });
                    thisRow.push(teamNames);
                  } else thisRow.push(elem[headerOptions[e].key as unknown as keyof typeof form] as string)
                } else if (headerOptions[e].where === 'item') {
                  thisRow.push(item[headerOptions[e].key as unknown as keyof typeof item] as string)
                } else {
                  switch (headerOptions[e].key) {
                    case 'avaliacao':
                      let avaliaNomes = '';
                      sub.avaliacao?.forEach((e: any, i: number) => {
                        const toPush = `${e.nome} - ${e.valor}`;
                        sub.avaliacao?.length && sub.avaliacao?.length - 1 === i ? avaliaNomes += toPush : avaliaNomes += `${toPush}, `
                      })
                      return thisRow.push(avaliaNomes);
                    case 'list':
                      let lista = '';
                      if (sub.tipo === 'list')
                        sub.selecionados?.forEach((e: any, i: number) => {
                          const toPush = `${e.texto} |`;
                          sub.selecionados?.length && sub.selecionados?.length - 1 === i ? lista += toPush : lista += `${toPush}, `
                        })
                      return thisRow.push(lista);
                    case "unique":
                      let unico = [];
                      if (sub.tipo === 'unique') {
                        if (sub.selecionados !== null && sub.selecionados !== undefined && sub.selecao) {
                          unico = sub.selecao?.find((_ele: any, index: number) => {
                            return sub.selecionados ? sub.selecionados[index] : false
                          })
                        }
                      }
                      return sub.selecao && thisRow.push(String(unico));
                    case 'multiple':
                      let multiplo = [];
                      if (sub.tipo === "multiple") {
                        if (sub?.selecionados !== null && sub?.selecionados !== undefined && sub.selecao) {
                          multiplo = sub.selecao?.filter((_ele: any, index: number) => {
                            return sub?.selecionados ? sub?.selecionados[index] : false
                          })
                        }
                      }
                      return sub?.selecao && thisRow.push(String(multiplo));
                    default: return thisRow.push(sub[headerOptions[e].key as unknown as keyof typeof sub] as string)
                  }
                }
              })
              totalData.push(thisRow);
            }
          })
        })
      })
    } else
      form?.itens?.forEach(item => {
        item?.subitens?.forEach(sub => {
          if (sub.checked) {
            const thisRow: string[] = [];
            headers.length && headers?.forEach(e => {
              if (headerOptions[e].where === 'form') {
                if (headerOptions[e].key === 'inicio' || headerOptions[e].key === 'fim') {
                  const data = form[headerOptions[e].key as unknown as keyof typeof form];
                  const newValue = data ? new Date(data as number)?.toLocaleString('pt-BR') : '';
                  thisRow.push(newValue);
                } else if (headerOptions[e].key === 'equipes') {
                  let teamNames = '';
                  Object?.keys(form.equipes)?.forEach((e, i) => {
                    const equipe = form.equipes[e].nome;
                    Object?.keys(form.equipes).length - 1 === i ? teamNames += equipe : teamNames += `${equipe}, `
                  });
                  thisRow.push(teamNames);
                } else thisRow.push(form[headerOptions[e].key as unknown as keyof typeof form] as string)
              } else if (headerOptions[e].where === 'item') {
                thisRow.push(item[headerOptions[e].key as unknown as keyof typeof item] as string)
              } else {
                switch (headerOptions[e].key) {
                  case 'avaliacao':
                    let avaliaNomes = '';
                    sub.avaliacao?.forEach((e, i) => {
                      const toPush = `${e.nome} - ${e.valor}`;
                      sub.avaliacao?.length && sub.avaliacao?.length - 1 === i ? avaliaNomes += toPush : avaliaNomes += `${toPush}, `
                    })
                    return thisRow.push(avaliaNomes);
                  case 'list':
                    let lista = '';
                    if (sub.tipo === 'list')
                      sub.selecionados?.forEach((e, i) => {
                        const toPush = `${e.texto} |`;
                        sub.selecionados?.length && sub.selecionados?.length - 1 === i ? lista += toPush : lista += `${toPush}, `
                      })
                    return thisRow.push(lista);
                  case "unique":
                    let unico = [];
                    if (sub.tipo === 'unique') {
                      if (sub.selecionados !== null && sub.selecionados !== undefined && sub.selecao) {
                        unico = sub.selecao?.find((_ele, index) => {
                          return sub.selecionados ? sub.selecionados[index] : false
                        })
                      }
                    }
                    return sub.selecao && thisRow.push(String(unico));
                  case 'multiple':
                    let multiplo = [];
                    if (sub.tipo === "multiple") {
                      if (sub.selecionados !== null && sub.selecionados !== undefined && sub.selecao) {
                        multiplo = sub.selecao?.filter((_ele, index) => {
                          return sub.selecionados ? sub.selecionados[index] : false
                        })
                      }
                    }
                    return sub.selecao && thisRow.push(String(multiplo));
                  default: return thisRow.push(sub[headerOptions[e].key as unknown as keyof typeof sub] as string)
                }
              }
            })
            totalData.push(thisRow);
          }
        })
      })
    setCsvData(totalData);
    return;
  }, [form, headerOptions])

  useEffect(() => {
    if (firstRender && headerOptions) {
      prapareCSVDATA();
      setFirstRender(false);
    }
  }, [firstRender, setFirstRender, prapareCSVDATA])

  return (
    <div className={`modal ${openModal ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onKeyDown={escape} onClick={() => setOpenModal && setOpenModal(false)} />
      <div className="modal-card" style={{ minWidth: '750px', width: '50%' }}>
        <header className="modal-card-head">
          <div>
            <GenericHeader>
              {t('csv.title')}
            </GenericHeader>
          </div>
          <button className="delete is-danger" aria-label="close" onClick={() => setOpenModal(false)} />
        </header>
        <div className="modal-card-body" style={{ overflowX: 'hidden', padding: '2em' }}>
          <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 5, sm: 8, md: 10 }} sx={{ marginBottom: '10px' }}>
            {Object?.keys(headerOptions)?.map((e: string, i: number) => {
              return headerOptions[e] ? (
                <Grid item xs={2} sm={3} md={3} key={i}>
                  <FormControlLabel
                    control={<Checkbox {...headerOptions[e]}
                      checked={headerOptions[e].active} />}
                    label={e}
                    onChange={() => {
                      trackEventMatomo('Modal de CSV', 'click', 'tag', 'remove tag')
                      setHeaderOptions((prev) => ({
                        ...prev,
                        [e]: {
                          ...headerOptions[e],
                          active: !headerOptions[e].active
                        }
                      }));
                      prapareCSVDATA()
                    }}
                  />
                </Grid>
              ) : <></>
            })}
          </Grid>
          <div>
            <CSVLink filename={form.formulario} data={csvData}>
              <button
                className='button is-small is-info'
                onClick={() => {
                  trackEventMatomo('Modal de CSV', 'click', 'button', 'fecha modal')
                  setOpenModal(false)
                }}>
                <RiFileExcel2Fill />&nbsp;
                {t('Reports.generateCSV')}
              </button>
            </CSVLink>
          </div>
        </div>
      </div>
    </div>
  )
}
