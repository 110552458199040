import { Accordion, AccordionSummary, TextField, Tooltip } from '@material-ui/core';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCopy } from 'react-icons/ai';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AddBtn, ColumnsItem, DivButtonContainerItem, DivColumnsItem, DivColumnsItemOne4, SpanColumnsItem, SpanWeight, TableContainer, WeightInput } from './FormStyle';
import { RiDeleteBin5Line } from 'react-icons/ri';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FormSubItem from './FormSubItem';
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { ChecklistType, ItemChecklist } from '../../service/Checklists';
import { UserContext, identifierTypesList } from '../../context/UserContext';
import './styles.css'
import { trackEventMatomo } from '../../helpers/matomo';

function FormItem({
  item,
  indexItem,
  setEditingForm,
  form,
  isEditing,
  expandedItem,
  handleChangeItem,
  setSubModalOpen,
  subModalOpen,
  selectedIdentifica
}:
  {
    form: ChecklistType,
    item: ItemChecklist,
    indexItem: number,
    formId: string,
    setEditingForm: Function,
    setLoadingItem: Function,
    isEditing: boolean,
    expandedItem: string | boolean,
    handleChangeItem: Function,
    setSubModalOpen: Function,
    subModalOpen: boolean,
    selectedIdentifica: string | undefined
  }) {

  const { t } = useTranslation('translation');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [ocorrenciaMultipla, setOcorrenciaMultipla] = useState(item.ocorrenciaMultipla ? item.ocorrenciaMultipla : false)

  const { identifiersByForm, setIdentifiersByForm } = useContext(UserContext);

  const onClickaddBtn = () => {
    const newSubitem = {
      avaliacao: [{ nome: '1', valor: 0 }, { nome: '2', valor: form.valorMaxScoring || 100 }],
      id: item?.subitens.length,
      itemid: item.id,
      nome: 'Subitem',
      tipo: 'avaliacao',
      peso: 1,
      threshold: -1
    }
    form.itens[indexItem].subitens = [...form.itens[indexItem].subitens, newSubitem];
    trackEventMatomo('Gestão de formulários', 'click', 'button', 'Adiciona subitem')
    setEditingForm({ ...form })
  }

  const handleDragEnd = (result: DropResult) => {
    trackEventMatomo('Gestão de formulários', 'click', 'drag n drop', 'muda posição subitem')
    if (!result.destination) return;
    const list = Array.from(item.subitens)
    const [reordered] = list.splice(result.source.index, 1)
    if (result.destination)
      list.splice(result.destination.index, 0, reordered)
    setEditingForm({ ...form, item: item.subitens })
    form.itens.forEach(it => it.subitens.forEach(sub => {
      if (sub.help && sub.help.foto) {
        sub.help.newPhoto = true;
      }
    }))
    const obj = { ...form }
    obj.itens[indexItem].subitens = list
    setEditingForm(obj)
  }

  const duplicateItem = (item: ItemChecklist) => {
    trackEventMatomo('Gestão de formulários', 'click', 'button', 'duplica item')
    localStorage.setItem('item', JSON.stringify(item))
    const recoveredItem = JSON.parse(localStorage.getItem('item') || '')
    if (recoveredItem) {
      recoveredItem.nome = `${recoveredItem.nome} ${t('copy')}`
      recoveredItem.id = `${form.id}|${form.versao}|${form.itens.length}`
      recoveredItem?.subitens?.forEach((ele: any) => delete ele.help)
      setEditingForm({
        ...form,
        itens: [...form.itens, recoveredItem]
      })
    }
  };

  const removeItem = () => {
    trackEventMatomo('Gestão de formulários', 'click', 'button', 'remove item')
    setRemoving(true);
    if (item.subitens.some(sub => identifierTypesList.includes(sub.tipo))) {
      const listIdentiToRemove: string[] = [];
      item.subitens.forEach((sub, index) => {
        if (identifierTypesList.includes(sub.tipo)) {
          listIdentiToRemove.push(`${form.id}|${form.versao}|${indexItem}|${index}`);
        }
      })
      identifiersByForm.forEach(e => {
        if (e.formId === form.id) {
          e.identifiersList = e.identifiersList.filter(iden => !listIdentiToRemove.includes(iden.value))
        }
      })
      setIdentifiersByForm([...identifiersByForm])
    }
    setEditingForm({
      ...form,
      itens: form.itens.filter((_e, i) => i !== indexItem)
    })
    setConfirmationModal(false);
    setRemoving(false);
  }

  const TextInput = () => {
    const InputNameRef = useRef<HTMLInputElement>(null);
    const [name, setName] = useState(item.nome);
    const onChangeInput =
      (e: React.ChangeEvent<HTMLInputElement>) => {
        trackEventMatomo('Gestão de formulários', 'click', 'input', 'edita nome item')
        setName(e.target.value);
        item.nome = e.target.value;
      }

    return (
      <TextField
        className='inputStyle'
        label={`${t('name')} item`}
        type="text"
        onChange={onChangeInput}
        value={name}
        disabled={!isEditing}
        ref={InputNameRef}
        style={{ margin: 0, color: 'black' }}
      />
    );
  }
  const MultipleTextInput = () => {
    const InputMultipleNameRef = useRef<HTMLInputElement>(null);
    const [name, setName] = useState(item.nomeOcorrencia);
    const onChangeInput =
      (e: React.ChangeEvent<HTMLInputElement>) => {
        trackEventMatomo('Gestão de formulários', 'click', 'input', 'edita nome item dinamico')
        setName(e.target.value);
        item.nomeOcorrencia = e.target.value;
      }

    return (
      <TextField
        className='inputStyle'
        label={`${t('name')} ${t('multipleOccurrence')}`}
        type="text"
        onChange={onChangeInput}
        value={name}
        disabled={!isEditing}
        ref={InputMultipleNameRef}
        style={{ margin: 0, color: 'black', fontWeight: 'bolder', marginBottom: '10px' }}
      />
    );
  }

  const WeightInputChange = () => {
    const InputWeightRef = useRef<HTMLInputElement>(null);
    const [weight, setWeight] = useState<number | undefined>(item?.peso ? Number(item.peso) : 0)

    const onChangeInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        trackEventMatomo('Gestão de formulários', 'click', 'input', 'edita peso do item')
        setWeight(Number(e.target.value));
        item.peso = Number(e.target.value);
      },
      []
    );

    return (
      !isEditing ?
        <SpanWeight>
          {typeof item.peso === "number" ? item.peso : '-'}
        </SpanWeight> :
        <WeightInput
          onKeyDown={(e) => { if (e.key === '-' || e.key === '+') e.preventDefault() }}
          type="number"
          inputMode='numeric'
          pattern='/^[0-9]*$/'
          onChange={onChangeInput}
          min={0}
          value={weight === 0 ? undefined : weight}
          disabled={!isEditing}
          ref={InputWeightRef}
          style={{ textAlign: 'center' }}
        />
    );
  }

  const MultipleInputChange = () => {
    const InputMultipleRef = useRef<HTMLInputElement>(null);
    const [multipleValue, setMultipleValue] = useState<number | undefined>(item?.maxOcorrencias ? Number(item.maxOcorrencias) : 0)

    const onChangeInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        trackEventMatomo('Gestão de formulários', 'click', 'input', 'edita n/ ocorrencias item')
        setMultipleValue(Number(e.target.value));
        item.maxOcorrencias = Number(e.target.value);
      },
      [ocorrenciaMultipla]
    );

    return (
      !isEditing ?
        <>
          {typeof item.maxOcorrencias === "number" && item.maxOcorrencias > 0 ? item.maxOcorrencias : '∞'}
        </>
        :
        <>
          <WeightInput
            onKeyDown={(e) => { if (e.key === '-' || e.key === '+') e.preventDefault() }}
            type="number"
            inputMode='numeric'
            pattern='/^[0-9]*$/'
            onChange={onChangeInput}
            min={0}
            value={multipleValue === 0 ? undefined : multipleValue}
            disabled={!isEditing}
            ref={InputMultipleRef}
            style={{ textAlign: 'center' }}
          />
          {typeof multipleValue !== 'number' || multipleValue < 1 &&
            <p style={{ color: 'red', fontSize: '12px' }}>
              *caso não seja definido um valor poderão ser abertas ocorrências ilimitadas
            </p>}
        </>
    );
  }

  const handleMultiple = () => {
    setOcorrenciaMultipla(!ocorrenciaMultipla);
    item.ocorrenciaMultipla = !ocorrenciaMultipla;
    item.nomeOcorrencia = item.nome;
    if (ocorrenciaMultipla) {
      delete item.nomeOcorrencia
    }
  };

  return (
    <>
      {confirmationModal && (
        <ConfirmationModal
          yesFunction={removeItem}
          isOpen={confirmationModal}
          closeFunction={setConfirmationModal}
          removing={removing}
        />
      )}
      <Accordion
        style={{
          borderBottom: '1px #F3F3F4 solid',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column'
        }}
        expanded={expandedItem === item.id}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ paddingLeft: '10px' }}
          expandIcon={<ExpandMoreIcon
            onClick={(isExpanded) => {
              if (expandedItem === item.id) {
                handleChangeItem(!isExpanded, '')
              } else
                handleChangeItem(isExpanded, item.id)
            }}
          />}
        >
          <ColumnsItem className='columns'>
            <SpanColumnsItem
              className='column is-1'>
              {`${indexItem + 1}`}
            </SpanColumnsItem>
            <DivColumnsItem
              className='column'>
              <TextInput />
              {ocorrenciaMultipla && <MultipleTextInput />}
            </DivColumnsItem>
            <div className='column is-one-quarter'>
              <DivButtonContainerItem>
                <DivColumnsItemOne4>
                  <p style={{ fontSize: '.8em' }}>
                    {t('checklists.checklists.itemWeight')}
                  </p>
                  &nbsp;
                  <WeightInputChange />
                  <Tooltip title={'Copiar'}>
                    <button
                      className="button is-primary is-small"
                      onClick={() => {
                        trackEventMatomo('Gestão de formulários', 'click', 'button', 'duplica item')
                        duplicateItem(item)
                      }}
                      disabled={!isEditing}
                    >
                      <AiOutlineCopy size={20} />
                    </button>
                  </Tooltip>
                  <Tooltip title={'Excluir'}>
                    <button
                      disabled={(form.itens.length <= 1 || !isEditing)}
                      className="button is-danger is-small"
                      onClick={() => {
                        trackEventMatomo('Gestão de formulários', 'click', 'button', 'exclui item')
                        setConfirmationModal(true);
                      }}
                    >
                      <RiDeleteBin5Line size={20} />
                    </button>
                  </Tooltip>
                </DivColumnsItemOne4>
                <label className="checkbox">
                  <input
                    disabled={(!isEditing)}
                    onClick={() => trackEventMatomo('Gestão de formulários', 'click', 'checkbox', 'habilita ocorrencia multipla')}
                    onChange={handleMultiple}
                    checked={ocorrenciaMultipla}
                    type="checkbox"
                    style={{ marginRight: '4px' }}
                  /><span style={{ fontSize: '13px', color: 'gray', fontWeight: 'bold' }}>
                  {t('Reports.modalDetailP.multipleOccurrenceItem')}
                  </span>
                </label>
                {ocorrenciaMultipla &&
                  <label>
                    N° de ocorrências&nbsp;<MultipleInputChange />
                  </label>
                }
              </DivButtonContainerItem>
            </div>
          </ColumnsItem>
        </AccordionSummary>
        <AddBtn className="button is-success is-light" onClick={() => {
          onClickaddBtn()
          trackEventMatomo('Gestão de formulários', 'click', 'button', 'adiciona subitem')
        }} disabled={!isEditing}>
          {t('checklists.checklists.addSubitem')}
        </AddBtn>
        <TableContainer>
          <table>
            <thead>
              <tr>
                <th align="center" style={{ width: "5vw" }}>{t('checklists.checklists.subItem')}</th>
                <th align="left" style={{ width: "60%" }}>{t('name')}</th>
                <th align="center" style={{ width: "12%" }}>{t('type')}</th>
                <th align="center" style={{ width: "5%" }}>{t('weight')}</th>
                <th align="center" style={{ width: "15%" }}>{t('options')}</th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable isDropDisabled={!isEditing || subModalOpen} droppableId="subItem">
                {(provided) => (
                  <tbody className="subitems" {...provided.droppableProps} ref={provided.innerRef}>
                    {item.subitens?.map((sub, ind) => {
                      return (
                        <Draggable key={String(sub.id)} draggableId={String(sub.id)} index={ind} isDragDisabled={!isEditing || subModalOpen}>
                          {(provided) => (
                            <tr key={ind} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <FormSubItem
                                selectedIdentifica={selectedIdentifica}
                                subitem={sub}
                                index={ind}
                                indexItem={indexItem}
                                isEditing={isEditing}
                                setEditingForm={setEditingForm}
                                form={form}
                                item={item}
                                setSubModalOpen={setSubModalOpen}
                              />
                            </tr>
                          )}
                        </Draggable>)
                    })}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </TableContainer>
      </Accordion>
    </>
  )
}

export default FormItem