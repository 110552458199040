
import React, { useState, createContext, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CheckToken from "../service/CheckToken";
import Login from "../service/Login";
import { IStore } from "./interfaces/IStore.";
import { IUser } from "./interfaces/IUser";
import Companies from "../service/Companies";
import { BroadcastChannel } from 'broadcast-channel';
import { UserContext } from "./UserContext";
import Auth from "../service/Auth";
import AttToken from "../helpers/attToken";
import { useTranslation } from "react-i18next";

const initialState: IStore = {
  token: '',
  saveLoginToken: () => { },
  ticking: false,
  setTicking: () => { },
}

const loginChannel = new BroadcastChannel('login');

export const AuthContext = createContext<IStore>(initialState);
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { setHierarchyLabel, setFirebaseToken, saveUserData } = useContext(UserContext)
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [ticking, setTicking] = useState(true);
  const [count, setCount] = useState(0);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const companiesService = new Companies();

  const authValidator = useCallback(async (timer: NodeJS.Timeout) => {
    const checkToken = new CheckToken();
    const loginData = localStorage.getItem('loginData');
    if ((count === 3600) && loginData) {
      setCount(0);
      const authorized = await checkToken.validateToken(JSON.parse(loginData).userToken);
      if (!authorized) {
        toast.warn(`${t('loggingOutDueToInactivity')}`);
        console.log('Deslogando por inatividade!');
        localStorage.clear();
        navigate('/');
      } else {
        console.log('token ainda válido');
      }
    }
  }, [count, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => ticking && setCount(count + 1), 1e3);
    authValidator(timer);
    return () => clearTimeout(timer);
  }, [count, ticking, authValidator]);


  const login = new Login()

  const saveLoginToken = async (userObj: IUser) => {
    const loginData = await login.getLoginInfo('/loginByToken', userObj)
    if (loginData) {
      setToken(loginData.token);
      return loginData;
    } else return false;
  }

  const store = {
    token,
    saveLoginToken,
    ticking,
    setTicking,
  }

  return (
    <AuthContext.Provider value={store}>
      <ToastContainer />
      {children}
    </AuthContext.Provider>
  );
}
