import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { GenericHeader } from '../../../StyledComponents/Modal/generic'
import { useTranslation } from 'react-i18next'
import AttToken from '../../../helpers/attToken'
import ServiceTypes from '../../../service/ServiceTypes'
import { toast } from 'react-toastify'
import queryClient from '../../../service/query'
import { useMutation } from '@tanstack/react-query'
import { Card, TextField } from '@material-ui/core'
import { AxiosError } from 'axios'
import { trackEventMatomo } from '../../../helpers/matomo'

type newServiceType = {
  nome: string,
  descricao: string
}

export default function ModalServiceType({
  open,
  setOpen,
  typeData,
  setTypeData,
  isEditing,
  setIsEditing,
}:
  {
    open: Boolean,
    setOpen: Function,
    typeData: {
      nome: string,
      tipoId: string,
      descricao?: string,
      dataCriacao: number,
    },
    setTypeData: Function,
    isEditing: boolean,
    setIsEditing: Function
  }) {

  const { t } = useTranslation('translation');

  const escape = useCallback((e: any): void => {
    if (e.key === 'Escape' && setOpen) {
      setOpen(false);
    }
  }, [setOpen])

  useEffect(() => {
    document.addEventListener('keydown', escape, true)
  }, [escape])

  const serviceType = useMemo(() => new ServiceTypes(), []);

  const [newService, setNewService] = useState<newServiceType>({
    nome: '',
    descricao: '',
  });

  const { mutate: newServiceType } = useMutation({
    mutationKey: ['newServiceType'],
    mutationFn: async () => {
      toast.warn(`${t('Services.ServiceModal.creatingNewServiceType')}`)
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa, tipoNome: newService?.nome, descricao: newService?.descricao };
        await serviceType.createServiceType(body);
      }
    },
    onError: () => {
      toast.error(`${t('Services.ServiceModal.errorCreatingNewService')}`);
      return console.log(AxiosError);
    },
    onSuccess: () => {
      setNewService({
        nome: '',
        descricao: '',
      });
      queryClient.resetQueries(['getServices']);
      toast.success(`${t('Services.ServiceModal.newServiceCreated')}`);
    }
  })

  const { mutate } = useMutation({
    mutationKey: ['editServiceType'],
    mutationFn: async () => {
      toast.warn(`${t('Services.ServiceModal.savingChange')}`)
      const token = await AttToken();
      if (token) {
        const body = {
          ...token, empresa: token.userEmpresa, tipo: {
            nome: typeData.nome,
            tipoId: typeData.tipoId,
            dataCriacao: typeData.dataCriacao,
            equipes: [],
            forms: [],
            clientes: [],
            locais: [],
            conjuntos: [],
            unidades: [],
            descricao: typeData.descricao
          }
        };
        await serviceType.editServiceType(body);
      }
    },
    onSuccess: () => {
      toast.success(`${t('Services.servicesManagement.changeName')}`);
      queryClient.resetQueries(['getServices'])
    }
  })

  return (
    <Card>
      <div className={`modal ${open ? "modal is-active" : "modal"}`}>
        <div
          className="modal-background"
          onKeyDown={escape}
          onClick={() => {
            trackEventMatomo('Modal associação tipo de serviço', 'click', 'div', 'fecha modal')
            setOpen(false)
          }} />
        <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
            <GenericHeader>
              {isEditing ? `${t('Services.servicesManagement.editName')}` : `${t('notifications.ticket.criarTipoServico')}`}
            </GenericHeader>
          </header>
          <div className="modal-card-body">
            <TextField
              onClick={() => trackEventMatomo('Modal associação tipo de serviço', 'click', 'textfiel', 'edita valor')}
              style={{ marginBottom: '10px' }}
              label={t('name') + ':'}
              fullWidth
              value={isEditing ? typeData.nome : newService?.nome}
              onChange={isEditing ?
                (e) => setTypeData({ ...typeData, nome: e.target.value }) :
                (e) => setNewService({ ...newService, nome: e.target.value })}
            />
            <TextField
              onClick={() => trackEventMatomo('Modal associação tipo de serviço', 'click', 'textfiel', 'edita valor')}
              multiline
              rows={2}
              maxRows={4}
              label={t('Services.servicesManagement.description')}
              fullWidth
              value={isEditing ? typeData?.descricao : newService?.descricao}
              onChange={isEditing ?
                (e) => setTypeData({ ...typeData, descricao: e.target.value }) :
                (e) => setNewService({ ...newService, descricao: e.target.value })}
            />
          </div>
          <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
            <button className='button is-success is-small'
              onClick={() => {
                trackEventMatomo('Modal associação tipo de serviço', 'click', 'button', 'salva')
                setOpen(false)
                isEditing ? mutate() : newServiceType()
              }}
            >{t('save')}</button>
            <button
              className='button is-warning is-small'
              onClick={() => {
                trackEventMatomo('Modal associação tipo de serviço', 'click', 'button', 'cancela')
                setOpen(false)
                setIsEditing(false)
              }}
            >{t('cancel')}</button>
          </div>
        </div>
      </div>
    </Card>
  )
}
