import React, { useRef, useState } from 'react'
import NovaNotificacao from '../../../../service/NovaNotificacao';
import AttToken from '../../../../helpers/attToken';
import { useQuery } from '@tanstack/react-query';
import { Accordion, Skeleton } from '@mui/material';
import { AccordionSummary } from '@material-ui/core';
import { Label } from '../../../Notifications/style/SingleNotificationStyle';
import { TagContainer, TagSpan } from '../../../../StyledComponents/Modal/generic';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer } from './FormModalStyle';
import Checklists, { ChecklistType, notifiConfigType } from '../../../../service/Checklists';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { trackEventMatomo } from '../../../../helpers/matomo';

const newNotfiService = new NovaNotificacao();
const checklistService = new Checklists();

type props = {
  form: ChecklistType,
  setEditModal: Function
}

type returnType = {
  tipoId: string,
  nome: string,
  notifis: { nome: string, id: string }[]
}

type propsNotifis = {
  notifiConfig: notifiConfigType[],
  setNotifiConfig: React.Dispatch<React.SetStateAction<notifiConfigType[]>>,
  findNotifiNome: Function,
  obj: returnType,
  t: Function,
  expanded: string | boolean,
  handleChange: Function,
}

const NotifisByType = ({ notifiConfig, setNotifiConfig, findNotifiNome, obj, t, expanded, handleChange }: propsNotifis) => {
  const [selectedNotifis, setSelectedNotifis] = useState<notifiConfigType[]>(
    obj.notifis.filter(noti => notifiConfig.some(conf => conf.id === noti.id))
      .map(e => {
        const notifiEm = notifiConfig.find(el => el.id === e.id)?.notifiEm || 'sempre'
        return { id: e.id, notifiEm }
      })
  );

  const selectRef = useRef<HTMLSelectElement>(null);

  return (
    <>
      <Accordion expanded={expanded === obj.tipoId} style={{ padding: "0px 5px 10px 5px", width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={(isExpanded) => {
              if (expanded === obj.tipoId) {
                handleChange(!isExpanded, '')
              } else
                handleChange(isExpanded, obj.tipoId)
            }} />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          Notificacoes do tipo -&nbsp; <b>{obj.nome}</b>
        </AccordionSummary>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px'
          }}
        >
          {selectedNotifis.length > 0 && selectedNotifis.map((ele, i: number) => (
            <div
              style={{
                padding: '8px',
                margin: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                backgroundColor: '#D3D3D3',
                borderRadius: '5px'
              }}
              key={ele.id}
            >
              <span style={{ marginRight: 'auto' }}>{findNotifiNome(ele.id)}</span>
              <Label style={{ marginLeft: 'auto', marginRight: '5px' }}>
                Notificar em:
              </Label>
              <select
                onChange={(e) => {
                  selectedNotifis[i].notifiEm = e.target.value
                  notifiConfig.forEach((conf) => {
                    if (conf.id === ele.id) {
                      conf.notifiEm = e.target.value
                      setNotifiConfig([...notifiConfig])
                    }
                  })
                  setSelectedNotifis(selectedNotifis);
                }}
                className='select is-small'
                defaultValue={ele.notifiEm || 'sempre'}
                style={{ marginRight: '5px' }}
              >
                <option value='sempre'>{t('checklists.formModal.always')}</option>
                <option value='naoConformidade'>{t('checklists.formModal.inNonConformity')}</option>
              </select>
              <button
                className='button is-small is-danger'
                onClick={() => {
                  setSelectedNotifis(prev => prev ? prev.filter(el => el.id !== ele.id) : [])
                  setNotifiConfig(prev => prev ? prev.filter(el => el.id !== ele.id) : [])
                }}
              >
                <AiOutlineClose />
              </button>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <select
            ref={selectRef}
            onChange={(e) => {
              const info = obj.notifis.find(notifi => notifi.id === e.target.value);
              if (info) {
                const thisNotifi: notifiConfigType = {
                  id: info.id,
                  notifiEm: 'sempre'
                }
                setNotifiConfig((prev) => prev ? [...prev, thisNotifi] : [thisNotifi]);
                setSelectedNotifis(prev => prev ? [...prev, thisNotifi] : [thisNotifi]);
                console.log(e.target.value)
                if (selectRef.current) {
                  selectRef.current.value = '';
                }
              }
            }}
            className='select is-fullwidth'
            defaultValue=''
            style={{ marginRight: '10px' }}
          >
            <option value='' disabled>{t('select')}</option>
            {obj.notifis.map((elem) => (
              <option key={elem.id} disabled={selectedNotifis.some(confId => confId.id === elem.id)} value={elem.id}>{elem.nome}</option>
            ))}
          </select>
          <button
            className='button is-small is-info'
            disabled={obj.notifis.every(notifis => notifiConfig.some(conf => conf.id === notifis.id))}
            onClick={() => {
              const all: notifiConfigType[] = obj
                .notifis
                .filter(el => !notifiConfig.some(conf => conf.id === el.id))
                .map(e => ({ id: e.id, notifiEm: 'sempre' }));

              if (all.length) {
                setNotifiConfig((prev) => prev ? [...prev, ...all] : all);
                setSelectedNotifis(prev => prev ? [...prev, ...all] : all);
              }
            }}
          >
            {t('checklists.formModal.addAll')}
          </button>
        </div>
      </Accordion>


    </>
  )
}

export default function NotifiConfig({ form, setEditModal }: props) {
  const [notifiConfig, setNotifiConfig] = useState<notifiConfigType[]>(form.notifiConfig || []);
  const [selectedServicesTypes, setSelectedServicesTypes] = useState<returnType[]>([]);
  const [loading, setLoading] = useState(false);

  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
    trackEventMatomo('modal config form', 'click', 'acordeon', 'abre/fecha')
  };

  const { t } = useTranslation('translation');

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['NOTIFICATIONS_BY_SERVICE'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result: returnType[] = await newNotfiService.getAllByTypes(body);
        const selecteds: returnType[] = [];
        result.forEach(objConfig => {
          if (form.notifiConfig && form.notifiConfig.some(config => objConfig.notifis.some(notifi => notifi.id === config.id))) {
            selecteds.push(objConfig);
          }
        })
        if (selecteds && selecteds.length) {
          setSelectedServicesTypes(selecteds)
        }
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const findNotifiNome = (id: string) => {
    const thisServiceNotifis = data?.find(obj => obj.notifis.some(notifi => notifi.id === id))?.notifis;
    if (thisServiceNotifis && thisServiceNotifis.length) {
      return thisServiceNotifis.find(ele => ele.id === id)?.nome || 'Nome não encontrado';
    } else { return 'Nome não encontrado' }
  }

  return (
    <div style={{ marginBottom: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {
        loading || isLoading || isRefetching ?
          <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Skeleton width={'60%'} height={40} />
              <Skeleton height={20} width={'30%'} />
              <Skeleton width={'100%'} height={60} />
            </div>
          </> :
          <div className='modal-card-body' style={{ width: '100%' }}>
            <div className='hero' style={{ marginBottom: 10 }}>
              <h1 className='subtitle'>{t('checklists.formModal.serviceTypesNotifiedByForm')}</h1>
            </div>
            <div className='columns'>
              <div className='column'>
                <Label>
                {t('checklists.formModal.selectServiceType')}
                </Label>
                <TagContainer>
                  {selectedServicesTypes.length > 0 && selectedServicesTypes.map((ele, i: number) => (
                    <TagSpan key={`FORM_INDEX${i}`}
                      onClick={() => {
                        trackEventMatomo('modal config form', 'click', 'tag', 'remove valor')
                        setNotifiConfig(prev => prev ? prev.filter(conf => !ele.notifis.some(noti => noti.id === conf.id)) : [])
                        setSelectedServicesTypes(prev => prev ? prev.filter(el => el.tipoId !== ele.tipoId) : [])
                      }}>
                      {ele.nome}
                      <AiOutlineClose />
                    </TagSpan>
                  ))}
                </TagContainer>
                <select
                  onClick={() => trackEventMatomo('modal config form', 'click', 'select', 'seleciona serviço')}
                  onChange={(e) => {
                    const thisObj = data?.find(el => el.tipoId === e.target.value);
                    if (thisObj) {
                      setSelectedServicesTypes((prev) => prev ? [...prev, thisObj] : [thisObj])
                      e.target.value = '';
                    }
                  }}
                  className='select is-fullwidth'
                  defaultValue=''>
                  <option value='' disabled>{t('select')}</option>
                  {data && data?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })?.map((elem) => (
                    <option disabled={selectedServicesTypes.some(e => e.tipoId === elem.tipoId)} value={elem.tipoId}>{elem.nome}</option>
                  ))}
                </select>
              </div>
            </div>
            {selectedServicesTypes.length > 0 && selectedServicesTypes.map(obj => (
              <div className='column' key={obj.tipoId}>
                <NotifisByType
                  expanded={expanded}
                  handleChange={handleChange}
                  notifiConfig={notifiConfig}
                  setNotifiConfig={setNotifiConfig}
                  findNotifiNome={findNotifiNome}
                  obj={obj}
                  t={t}
                />
              </div>
            ))}
          </div>
      }
      <ButtonsContainer>
        <button
          disabled={isLoading || loading || isRefetching}
          className='button is-success is-small'
          onClick={async () => {
            setLoading(true);
            form.notifiConfig = notifiConfig;
            const token = await AttToken();
            if (token) {
              await toast.promise(
                checklistService.updateChecklist(token, form),
                {
                  pending: `${t('checklists.formModal.savingConfiguration')}`,
                  success: `${t('checklists.formModal.configurationSaved')}`,
                  error: `${t('checklists.formModal.errorSaving')}`
                }
              )
            }
            setLoading(false);
            refetch()
            trackEventMatomo('modal config form', 'click', 'button', 'salva')
          }}>
          {t('save')}
        </button>
        <button
          className='button is-light is-small'
          onClick={() => {
            trackEventMatomo('modal config form', 'click', 'button', 'cancela')
            setEditModal(-1)
          }}>
          {t('close')}
        </button>
      </ButtonsContainer>
    </div>
  )
}