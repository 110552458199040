import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ChecklistType } from '../../../../service/Checklists';
import { GenericHeader } from '../../../../StyledComponents/Modal/generic';
import { TabStyle } from '../../../TabsStyle/TabsStyle';
import AssociationTab from './AssociationTab';
import { TabDiv } from './FormModalStyle';
import GeneralTab from './GeneralTab';
import RangeScoringTab from './RangeScoringTab';
import SubItemTab from './SubItemTab';
import FillingConfig from './FillingConfig';
import FormChamadosConfig from './FormChamadosConfig';
import NotifiConfig from './NotifiConfig';
import { UserContext } from '../../../../context/UserContext';
import { trackEventMatomo } from '../../../../helpers/matomo';
import QrCodesList from './QrCodesList';

type props = {
  editModal: number,
  setEditModal: Function,
  form: ChecklistType,
  formIndex: number,
  typeExists: boolean,
  refetchChecklists: Function
}

export default function FormModal({ editModal, setEditModal, form, formIndex, typeExists, refetchChecklists }: props) {
  const { t } = useTranslation('translation');

  const { userData } = useContext(UserContext);

  const escape = useCallback((e: any): void => {
    if (e.key === 'Escape') {
      setEditModal(-1);
    }
  }, [setEditModal])

  useEffect(() => {
    document.addEventListener('keydown', escape, true)
  }, [escape])

  const mapNames = {
    association: t('checklists.formModal.associationTab'),
    filling: t('formDetails.preenchimento_web'),
    general: t('checklists.formModal.generalTab'),
    subitems: t('checklists.formModal.subitemsTab'),
    rangeScoring: t('checklists.formModal.rangeScoringTab'),
    configChamados: t('formDetails.configChamados.configChamados'),
    configNotifi: userData.migrado ? t('checklists.formModal.notificationSettings') : t('checklists.formModal.notificationSettings2'),
    qrCodesList: `${t('checklists.Modal.preFilledQRCodeList')}`
  }

  const [isTab, setIsTab] = useState({
    isActiveObject: form.preenchimentoDeslogado ? 'filling' : 'association',
    object: form.preenchimentoDeslogado ? !userData.migrado ? [
      'filling',
      'general',
      'subitems',
      'configChamados',
      'configNotifi',
      'rangeScoring'
      ] : [
        'filling',
        'configChamados',
        'configNotifi',
        'rangeScoring'
      ] : !userData.migrado ? [
        'association',
        'general',
        'subitems',
        'configChamados',
        'configNotifi',
        'rangeScoring'
      ] : [
        'association',
        'general',
        'configChamados',
        'configNotifi',
        'rangeScoring',
        'qrCodesList'
      ]
  });

  const toggleClass = (index: number) => {
    setIsTab({ ...isTab, isActiveObject: isTab.object[index] })
    trackEventMatomo('modal config form', 'click', 'tab', 'troca de aba')
  }

  const toggleActive = (index: number) => {
    if (isTab.object[index] === isTab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <>
      <div
        className={`modal ${editModal === formIndex ? "modal is-active" : "modal"}`}>
        <div className="modal-background" onKeyDown={(e) => escape(e)} onClick={() => setEditModal(-1)} />
        <div
          className="modal-card"
          onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
            <GenericHeader>
              {t('formDetails.configuracoesDeForm')}
            </GenericHeader>
          </header>
          <div className="modal-card-body">
            <TabDiv className="tabs is-boxed">
              <ul>
                {isTab.object.map((el, index) => (
                  <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
                    <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
                  </li>
                ))}
              </ul>
            </TabDiv>
            {isTab.isActiveObject === 'association' && (
              <AssociationTab
                form={form}
                setEditModal={setEditModal}
                typeExists={typeExists}
                refetchChecklists={refetchChecklists} />
            )}
            {isTab.isActiveObject === 'filling' && (
              <FillingConfig
                form={form}
                setEditModal={setEditModal}
                formWeb={form.preenchimentoDeslogado}
                formId={form.id} />
            )}
            {isTab.isActiveObject === 'general' && (
              <GeneralTab
                setEditModal={setEditModal}
                formId={form.id}
                form={form}
              />
            )}
            {isTab.isActiveObject === 'subitems' && (
              <SubItemTab
                setEditModal={setEditModal}
                form={form} />
            )}
            {isTab.isActiveObject === 'configChamados' && (
              <FormChamadosConfig
                setEditModal={setEditModal}
                form={form} />
            )}
            {isTab.isActiveObject === 'configNotifi' && (
              <NotifiConfig
                setEditModal={setEditModal}
                form={form} />
            )}
            {isTab.isActiveObject === 'rangeScoring' && (
              <RangeScoringTab
                setEditModal={setEditModal}
                form={form} />
            )}
            {isTab.isActiveObject === 'qrCodesList' && (
              <QrCodesList
                setEditModal={setEditModal}
                form={form} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}