import '../styles.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useContext } from 'react';
import { FormContext } from '../../../context/FormContext';
import { useTranslation } from 'react-i18next';

export default function RemoveQrCOde({
  open,
  setOpen,
  setState,
  component,
  onSuccess,
  setRemoveQrCode,
}: {
  open: boolean,
  setOpen: Function,
  setState: Function,
  component: string,
  onSuccess?: Function,
  setRemoveQrCode?: Function,
}) {
  const { setModeloQrCode, qrCodeInfo, modeloQrCode, setQrCodeInfo } = useContext(FormContext)
  const { t } = useTranslation('translation');
  return (
    <div className={`${open ? "modal is-active" : "modal"}`} style={{ zIndex: 999 }}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <section className="modal-card-body modalQrCode">
          {component === `${t('checklists.Modal.subitem')}` ? `${t('checklists.Modal.subitemHasQRCode')}`
            : `${t('checklists.Modal.disablePreviousQRCodes')}`}
          <div className='modalQrCodeButtons'>
            <CheckCircleIcon
              onClick={component === 'subitem' ? () => {
                if (qrCodeInfo) {
                  setQrCodeInfo(null)
                }
                if (modeloQrCode && qrCodeInfo) {
                  delete modeloQrCode[Object.keys(qrCodeInfo)[0]]
                  setModeloQrCode(modeloQrCode)
                }
                setState(false)
                setOpen(false)
              } : () => {
                if (onSuccess) {
                  onSuccess()
                }
              }}
              style={{ fontSize: '40px' }}
              color='success' />
            <CancelIcon onClick={() => {
              setOpen(false)
              if (setRemoveQrCode) {
                setRemoveQrCode(false)
              }
            }} style={{ fontSize: '40px' }} color='error' />
          </div>
        </section>
      </div>
    </div>
  )
}
