import styled from "styled-components";

export const ButtonsQR = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
`

export const TitleQR = styled.h1`
  font-size: 1.3em;
  color: black;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
`

export const QRCodeDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
`

export const ContentContainer = styled.div`
  margin: '0px';
`

export const MainContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const QrCodeWithButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

export const AnswersContainer = styled.div`
  margin: '10px';
  width: 100%;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`

export const SubitemParagraph = styled.p`
  font-weight: bold;
  align-self: center;
  font-size: 1.2em;
`

export const AnswerParagraph = styled.p`
  font-style: italic;
  align-self: center;
  font-size: 0.9em;
  margin-left: 10px;
`
