import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ChecklistType } from '../../../../service/Checklists';
import QrCode, { GetListTypes, ModeloQRCode } from '../../../../service/QrCode';
import AttToken from '../../../../helpers/attToken';
import { Skeleton } from '@mui/material';
import RenderQrCode from '../../../Reports/RenderQrCode';
import { ButtonsContainer, ContentContainerFormModal, QrCodeListContainer, TitleAccordionQRCode, TitleQrCodeList } from './FormModalStyle';
import { useTranslation } from 'react-i18next';
import { trackEventMatomo } from '../../../../helpers/matomo';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type props = {
  form: ChecklistType,
  setEditModal: Function
}

export default function QrCodesList({ form, setEditModal } : props) {
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [listQrCodes, setListQrCodes] = useState<GetListTypes[]>();

  const { t } = useTranslation('translation');

  const qrCodeService = useMemo(() => new QrCode(), []);

  const fetchQrCodes = useCallback(async () => {
    setIsLoadingList(true);
    const token = await AttToken();
    if (token) {
      const list = await qrCodeService.getList({...token, formId: form.id});
      if (list && list.length) {
        setListQrCodes(list)
      }
    }
    setIsLoadingList(false);
  }, [form.id, qrCodeService]);

  useEffect(() => {
      if (isLoadingList) {
        fetchQrCodes();
      }
    }, [fetchQrCodes, isLoadingList]);

  if (!form.modeloQrCode) {
    return (
      <ContentContainerFormModal>
        <span className='tag is-warning'>{t('checklists.Modal.qrCodeNotConfigured')}</span>
        <p>{t('checklists.Modal.followCorrectQRCodeSetup')}</p>
        <ButtonsContainer>
          <button className='button is-light is-small' disabled={isLoadingList} onClick={() => {
            trackEventMatomo('modal config form', 'click', 'button', 'cancela')
            setEditModal(-1)
          }}>
            {t('close')}
          </button>
      </ButtonsContainer>
      </ContentContainerFormModal>
    )
  }

  if (isLoadingList) {
    return (
      <ContentContainerFormModal>
        <Skeleton />
        <ButtonsContainer>
          <button className='button is-light is-small' disabled={isLoadingList} onClick={() => {
            trackEventMatomo('modal config form', 'click', 'button', 'cancela')
            setEditModal(-1)
          }}>
            {t('close')}
          </button>
      </ButtonsContainer>
      </ContentContainerFormModal>
    )
  }

  return (
    <ContentContainerFormModal>
      {listQrCodes && listQrCodes.length ? (
        <QrCodeListContainer>
          <TitleQrCodeList>
          {t('checklists.Modal.qrCodesOrganizedBy')} "{listQrCodes[0].data.nome.split(':')[0]}"
          </TitleQrCodeList>
          {listQrCodes.map(qrCodeObj => (
            <Accordion style={{ marginBottom: '10px', backgroundColor: '#F3F3F4' }} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <TitleAccordionQRCode>{qrCodeObj.data.id}</TitleAccordionQRCode>
              </AccordionSummary>
              <AccordionDetails>
                <RenderQrCode
                  qrCodeData={qrCodeObj.deepLink}
                  renderBody={qrCodeObj.data}
                  modeloQrCode={form.modeloQrCode as ModeloQRCode}
                  hideSpan
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </QrCodeListContainer>
      ) : (
        <div>
          <span className='tag is-warning'>{t('Filling.fillSubitens.noPreFilledQRCodeFound')}</span>
          <p>{t('Filling.fillSubitens.howToCreatePreFilledQRCode')}</p>
        </div>
      )}
      <footer className="modal-card-foot">
        <ButtonsContainer className='buttons'>
          <button className='button is-light is-small' disabled={isLoadingList} onClick={() => {
            trackEventMatomo('modal config form', 'click', 'button', 'cancela')
            setEditModal(-1)
          }}>
            {t('close')}
          </button>
        </ButtonsContainer> 
      </footer>
    </ContentContainerFormModal>
  )
}
