import { useQuery } from '@tanstack/react-query';
import ReportsTable from './ReportsTable'
import AttToken from '../../helpers/attToken';
import { useMemo } from 'react';
import Reports, { filterDataType } from '../../service/Reports';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ReportsComponent() {
  const { t } = useTranslation('translation');
  const reportsService = useMemo(() => new Reports(), []);
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Reports'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await reportsService.getData(token)
        return response
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  })

  if (isLoading) {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Skeleton width={'100%'} height={'400px'} style={{ marginTop: '-5em' }} />
        </div>
      </>
    )
  }

  if (isError) {
    return <span>{t('Reports.reportsTable.errorFetchingData')}</span>
  }

  return (
    <div>
      <ReportsTable data={data.filterData as filterDataType} />
    </div>
  )
}