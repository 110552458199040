import { MainContainerMulti } from './Multiple/MultipleStyle'
import { Container } from './Unique/UniqueStyle'
import { SubItemProps } from './Avaliacao/Avaliacao'
import SubItemComentario from './SubItemComentario'
import { useEffect, useState } from 'react'
import { trackEventMatomo } from '../../../../helpers/matomo'
import { useTranslation } from 'react-i18next'


const Hour = ({ subItem, indexItem, onChange }: SubItemProps) => {
  const [ocorrencia, setOcorrencia] = useState(subItem.padrao ? subItem.padrao : '')
  const { t } = useTranslation('translation');

  const borderCheckerComent = () => {
    const comentario = subItem?.ocorrencia === '' || !subItem?.ocorrencia;
    if (subItem?.obrigatoriedade?.preenchimento && comentario) return '0.3em red solid'
    if (!subItem?.obrigatoriedade?.preenchimento && comentario) return '0.3em #2196f3 solid'
    else return '0.3em #4caf50 solid'
  }

  useEffect(() => {
    if (subItem.padrao) {
      subItem.ocorrencia = subItem.padrao
    }
  }, [subItem])

  useEffect(() => {
    onChange && onChange()
  }, [ocorrencia])


  return (
    <MainContainerMulti>
      <Container>
        <input
          onClick={() => trackEventMatomo('hora', 'click', 'input', 'edita valor')}
          type='time'
          style={{ borderBottom: `${borderCheckerComent()}`, marginTop: '0.5em', width: 'fit-content' }}
          value={ocorrencia}
          onChange={(e) => {
            setOcorrencia(e.target.value)
            subItem.ocorrencia = e.target.value
          }}
          className="input"
          placeholder={t('Filling.fillSubitens.tapToType')} />
        {subItem.ocorrencia && subItem.comentario &&
          <SubItemComentario subItem={subItem} indexItem={indexItem} onChange={onChange} />}
      </Container>
    </MainContainerMulti>
  )
}

export default Hour;