import React, { useContext, useEffect, useState } from 'react'
import { GenericHeader } from '../../StyledComponents/Modal/generic'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'
import AttToken from '../../helpers/attToken'
import Checklists from '../../service/Checklists'
import FormFilling from './FormFilling'
import Clients from '../../service/Clients'
import { toast } from 'react-toastify'
import { Skeleton } from '@mui/material'
import Anonimo from '../../service/Anonimo'
import CheckMandatory from './CheckMandatorys'
import { trackEventMatomo } from '../../helpers/matomo'
import queryClient from '../../service/query'
import { UserContext } from '../../context/UserContext'
import { SpanColored } from './FormFillingStyle'
import AutocompleteSApi from '../AutocompleteSApi/AutocompleteSApi'

const clientsService = new Clients();
const serviceAnonimo = new Anonimo();

type props = {
  formId: string | null,
  setFormId: Function,
  open: boolean,
  setOpen: Function
}
const checkListService = new Checklists();

export default function FormFillingModal({ formId, setFormId, open, setOpen }: props) {
  const [selectedCliente, setSelectedCliente] = useState<string | null>(null);
  const [selectedLocal, setSelectedLocal] = useState<string | null>(null);
  const [selectedConjunto, setSelectedConjunto] = useState<string | null>(null);
  const [selectedUnidade, setSelectedUnidade] = useState<string | null>(null);
  const { hierarchyLabel } = useContext(UserContext)


  const INICIO = Date.now();

  const { t } = useTranslation('translation');

  const { data: formData } = useQuery({
    queryKey: ['FORM_FOR_FILLING'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await checkListService.getChecklistForWebFilling(token, formId as string, false)
        return response.formToFilling;
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { data: hierarchyList, isLoading: isLoadingHierarchy, isFetching: fetchingHierarchy } = useQuery({
    queryKey: ['GET_HIERARCHY_LIST'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await clientsService.getListForWebForm(token, formId as string);
        return response;
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { mutate: executaLogado } = useMutation({
    mutationKey: ['EXECUTA-ANONIMO'],
    mutationFn: async () => {
      const response = await toast.promise(async () => {
        const token = await AttToken()
        if (token && selectedCliente && selectedLocal && selectedConjunto && selectedUnidade) {
          const hierarquia = {
            cliente: selectedCliente,
            local: selectedLocal,
            conjunto: selectedConjunto,
            unidade: selectedUnidade,
          }
          const response = await serviceAnonimo.executaChecklistLogado(token, hierarquia, { ...formData, inicio: INICIO })
          return response
        }
      }, {
        success: t('Filling.formExecutedSuccessfully'),
        pending: t('Filling.executingForm'),
        error: t('Filling.formExecutionFailed')
      })
      return response
    },
    onSuccess: () => {
      setFormId(null)
    }
  })

  useEffect(() => {
    if (selectedCliente === undefined) {
      setSelectedLocal(null)
      setSelectedConjunto(null)
      setSelectedUnidade(null)
    } if (selectedLocal === undefined) {
      setSelectedConjunto(null)
      setSelectedUnidade(null)
    } if (selectedConjunto === undefined) {
      setSelectedUnidade(null)
    }
  }, [selectedCliente, selectedLocal, selectedConjunto, selectedUnidade])

  return (
    <div className={`modal ${open ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onClick={() => setOpen(false)} />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>
            {t('Filling.fillForm')}
          </GenericHeader>
        </header>
        <div className="modal-card-body" style={{ justifyContent: 'space-between' }}>
          {isLoadingHierarchy || fetchingHierarchy ?
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Skeleton variant='text' width={240} />
              </div>
              <Skeleton variant='rounded' height={40} />
              <Skeleton variant='rounded' height={40} sx={{ marginTop: '10px' }} />
            </div> :
            <>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span>
                {t('Filling.fillSubitens.selectGeographicHierarchy')}
                </span>
                <SpanColored
                  style={{
                    backgroundColor: !selectedUnidade ? '#F6D265' : '#31AD21',
                    color: !selectedUnidade ? 'black' : 'white'
                  }}
                >
                  {!hierarchyList
                    ? `${t('Filling.fillSubitens.formNotAssociatedWithHierarchy')}`
                    : !selectedUnidade
                      ? `${t('Filling.fillSubitens.formExecutionRequiresHierarchy')}`
                      : `${t('Filling.fillSubitens.hierarchySelected')}`}
                </SpanColored>
              </div>
              {hierarchyList && <AutocompleteSApi
                setValue={setSelectedCliente}
                arrayObj={hierarchyList}
                noOptionsLabel={`${t('Filling.fillSubitens.without')} ${hierarchyLabel.cliente} ${t('Filling.fillSubitens.associated')}`}
                label={hierarchyLabel.cliente}
                loadingText={`Buscando ${hierarchyLabel.cliente}...`}
              />}
            </>
          }
          {selectedCliente &&
            <AutocompleteSApi
              setValue={setSelectedLocal}
              arrayObj={hierarchyList?.find((ele: { nome: string, id: string }) => ele.id === selectedCliente)?.locaisList}
              noOptionsLabel={`${t('Filling.fillSubitens.without')} ${hierarchyLabel.local} ${t('Filling.fillSubitens.associated')}`}
              label={hierarchyLabel.local}
              loadingText={`${t('Filling.fillSubitens.searching')} ${hierarchyLabel.local}...`}

            />}
          {selectedCliente &&
            selectedLocal &&
            <AutocompleteSApi
              setValue={setSelectedConjunto}
              arrayObj={hierarchyList
                ?.find((ele: { nome: string, id: string }) => ele.id === selectedCliente)?.locaisList
                ?.find((el: { nome: string, id: string }) => el?.id === selectedLocal)?.conjuntosList}
              noOptionsLabel={`${t('Filling.fillSubitens.without')} ${hierarchyLabel.conjunto} ${t('Filling.fillSubitens.associated')}`}
              label={hierarchyLabel.conjunto}
              loadingText={`${t('Filling.fillSubitens.searching')} ${hierarchyLabel.conjunto}...`}
            />}
          {selectedCliente
            && selectedLocal
            && selectedConjunto &&
            <AutocompleteSApi
              setValue={setSelectedUnidade}
              arrayObj={hierarchyList
                ?.find((ele: { nome: string, id: string }) => ele.id === selectedCliente)?.locaisList
                ?.find((el: { nome: string, id: string }) => el?.id === selectedLocal)?.conjuntosList
                ?.find((elem: { nome: string, id: string }) => elem.id === selectedConjunto).unidadesList}
              noOptionsLabel={`${t('Filling.fillSubitens.without')} ${hierarchyLabel.unidade} ${t('Filling.fillSubitens.associated')}`}
              label={hierarchyLabel.unidade}
              loadingText={`${t('Filling.fillSubitens.searching')} ${hierarchyLabel.unidade}...`}
            />}
          {selectedUnidade && formData &&
            <div style={{ width: '100%' }}>
              <FormFilling form={formData} />
            </div>}
        </div>
        <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            disabled={!selectedUnidade}
            type='button'
            className="button is-success is-small"
            onClick={() => {
              trackEventMatomo('Filling.formFillingModal', 'click', 'button', 'salva')
              const result = CheckMandatory(formData, t);
              if (!result.success) {
                if (result.chamado) toast.warn(`${t('Filling.formFilling.openTicketSubitem')} "${result.subItem}"${t('Filling.formFilling.insideItem')}"${result.item}"`)
                else if (result.texto) {
                  toast.warn(result.texto)
                }
                else if (result.item === 'chamado') toast.warn(`${t('Filling.openTicketToCompleteForm')}`)
                else toast.warn(`${t('Filling.fillSubitens.subitem')} "${result.subItem}${t('Filling.notFilledWithinItem')}${result.item}"`)
              } else {
                setOpen(false)
                executaLogado()
                localStorage.removeItem('inicio')
                queryClient.removeQueries(['FORM_FOR_FILLING'])
                queryClient.removeQueries(['GET_HIERARCHY_LIST'])
                setFormId(null)
              }
            }}
          >
            {t('save')}
          </button>
          <button
            type="button"
            onClick={() => {
              setOpen(false)
              setFormId(null)
              queryClient.removeQueries(['FORM_FOR_FILLING'])
              queryClient.removeQueries(['GET_HIERARCHY_LIST'])              
            }}
            className="button is-small is-warning"
          >
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}
