import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import AttToken from '../../../../helpers/attToken';
import { ChecklistType } from '../../../../service/Checklists';
import ServiceTypes, { types } from '../../../../service/ServiceTypes';
import Loading from '../../../Loading/Loading';
import Clients, { ClientsWithChildren } from '../../../../service/Clients';
import { Accordion, AccordionSummary, TextField } from '@material-ui/core'
import { Label } from '../../../../StyledComponents/Input/generic';
import Checklists from '../../../../service/Checklists';
import { ButtonsContainer } from './FormModalStyle';
import { WarningSpan } from '../../FormStyle';
import { UserContext } from '../../../../context/UserContext';
import Select from '../../../Skeletons/Select';
import { Autocomplete } from '@mui/material';
import { trackEventMatomo } from '../../../../helpers/matomo';

type props = {
  refetchChecklists: Function
  form: ChecklistType,
  setEditModal: Function,
  typeExists: boolean
}

const serviceType = new ServiceTypes();
const CustomerService = new Clients();
const ChecklistService = new Checklists();

export default function AssociationTab({ form, setEditModal, typeExists, refetchChecklists }: props) {
  const { t } = useTranslation('translation');

  const { setChanged, hierarchyLabel, userData } = useContext(UserContext);

  const [tipoServico, setTipoServico] = useState(typeExists ? form.tipoServico || '' : '');
  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
    trackEventMatomo('modal config form', 'click', 'button', 'abre/fecha acordeon')
  };

  const { mutate: unassociateType, isLoading: isLoadingUn } = useMutation({
    mutationKey: ['TipoServico'],
    mutationFn: async () => {
      const data = await AttToken();
      if (data) {
        delete form.conjuntosAssociados;
        const teams = await ChecklistService.unassociateType({ ...data, formId: form.id, typeId: !typeExists ? form.tipoServico : tipoServico });
        setTipoServico('');
        refetchChecklists();
        setEditModal(-1);
        return teams
      }
    }
  });

  const { mutate: changeChecklist, isLoading: isLoadingChange } = useMutation({
    mutationKey: ['checklist'],
    mutationFn: async (objToSend: ChecklistType) => {
      const data = await AttToken();
      if (data) {
        const teams = await ChecklistService.updateChecklist(data, objToSend);
        setTipoServico('');
        return teams
      }
    }
  });

  const { data: ClientesChildren, isLoading: isLoadingClientesChildren, refetch, isRefetching } = useQuery({
    queryKey: ['ClientesChildren'],
    queryFn: async () => {
      const data = await AttToken();
      if (data && typeExists && form.tipoServico) {
        if (!typeExists) return [];
        const teams = await CustomerService.getAllWithSets(data, form.tipoServico);
        return teams
      } else throw new Error('aaaa')
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { data: services, isLoading } = useQuery({
    queryKey: ['services'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceType.getAllServices(body);
        return result
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return isLoadingChange ? <Loading /> : (
    <div style={{ marginBottom: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      {(isLoading || isLoadingUn) ? <Loading /> :
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} className='columns'>
          <>
            <Autocomplete
              onClick={() => trackEventMatomo('modal config form', 'click', 'select', 'seleciona serviço')}
              disabled={form.tipoServico?.length > 0}
              className='column'
              value={tipoServico ? {
                value: tipoServico,
                label: services?.find((ele: types) => ele.tipoId === tipoServico)?.nome
              } : null}
              id="combo-box-demo"
              sx={{ marginTop: '10px' }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              filterSelectedOptions
              options={
                services
                  ?.sort((a: types, b: types) => { return a.nome > b.nome ? 1 : - 1 })
                  ?.map((ele: types) => ({
                    label: ele.nome,
                    value: ele.tipoId,
                  }))}
              onChange={(event: any, newValue: { label: string, value: string } | null) => {
                if (newValue) {
                  setTipoServico(newValue?.value)
                  form.tipoServico = newValue.value
                  refetch()
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              renderInput={(params) => <TextField {...params} label={t('formDetails.Association.Tipo_de_servico_do_formulario')} />}
            />
            <div className='column'>
              {((tipoServico || !typeExists) && userData.formMigrado) &&
                <button
                  className='button is-danger is-small'
                  onClick={() => {
                    trackEventMatomo('modal config form', 'click', 'button', 'desassocia serviço')
                    unassociateType();
                  }}
                >
                  {t('formDetails.Association.desassociar')}
                </button>
              }
            </div>
          </>
        </div>
      }
      {
        !typeExists &&
        <WarningSpan style={{ backgroundColor: 'darkred', color: 'white' }}>
          {t('formDetails.Association.O_tipo_de_serviço_que_estava')}
        </WarningSpan>
      }
      {tipoServico === '' || isLoadingUn ? <h1>{t('formDetails.Association.Por_favor_selecione_serviço')}</h1> : <>
        <h1>{t('formDetails.Association.Associação_de_conjuntos_clientes')}</h1>
        <Accordion style={{ width: '100%', marginTop: '5px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            {hierarchyLabel?.cliente}
          </AccordionSummary>
          {isLoadingClientesChildren || isRefetching || !ClientesChildren ? <Select /> : (
            ClientesChildren?.map((e: ClientsWithChildren, i: number) => (
              e &&
              <AccordeonPlaces
                expanded={expanded}
                handleChange={handleChange}
                client={e}
                key={e.id}
                cnjsAssociados={form.conjuntosAssociados || []}
                form={form}
              />
            ))
          )}
          {(!isLoadingClientesChildren && !isRefetching) && ClientesChildren.length === 0 &&
            <WarningSpan>
              {t('formDetails.Association.Voce_nao_possui_nenhuma_estrutura')}
            </WarningSpan>
          }
        </Accordion>
      </>}
      <ButtonsContainer>
        <button className='button is-success is-small' onClick={() => {
          localStorage.setItem('changedHirarquia', 'true');
          setChanged(true);
          const objToSend: ChecklistType = {
            ...form,
            tipoServico
          }
          refetchChecklists();
          changeChecklist(objToSend);
          setEditModal(-1);
          trackEventMatomo('modal config form', 'click', 'button', 'salva')
        }}>{t('save')}</button>
        <button
          className='button is-light is-small'
          onClick={() => {
            setEditModal(-1)
            trackEventMatomo('modal config form', 'click', 'button', 'cancela')
          }}>
          {t('close')}</button>
      </ButtonsContainer>
    </div>
  )
}

type AccordeonPlacesParams = {
  client: ClientsWithChildren;
  cnjsAssociados: string[];
  form: ChecklistType,
  expanded: string | boolean,
  handleChange: Function,
}

const AccordeonPlaces = ({ client, form, expanded, handleChange }: AccordeonPlacesParams) => {
  const [subExpanded, setSubExpanded] = useState(-1);

  const [cnjsArr, setCnjsArr] = useState<string[]>(form.conjuntosAssociados ? form.conjuntosAssociados || [] : []);
  const { t } = useTranslation('translation');

  const { userData } = useContext(UserContext);

  const allSetsFromClient: string[] = [];
  client.locaisList.forEach((local) => {
    local.conjuntosList.forEach((cnj) => allSetsFromClient.push(cnj.id))
  })

  return (
    <div style={{ width: '100%' }} key={client.id}>
      <Accordion style={{ marginBottom: '10px', width: '100%' }} expanded={expanded === client.id} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={(isExpanded) => {
              if (expanded === client.id) {
                handleChange(!isExpanded, '')
              } else
                handleChange(isExpanded, client.id)
            }}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Label className='checkbox'>
            <input
              onClick={() => trackEventMatomo('modal config form', 'click', 'input', 'seleciona conjunto')}
              type='checkbox'
              style={{ marginLeft: '10px', marginRight: '10px' }}
              disabled={!userData.formMigrado && allSetsFromClient.every(set => cnjsArr.includes(set))}
              checked={allSetsFromClient.every(set => cnjsArr.includes(set))}
              onChange={(event) => {
                const cnjs: string[] = [];
                if (event.target.checked) {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                      cnjs.push(cnj.id)
                    })
                  })
                  if (form.conjuntosAssociados) form.conjuntosAssociados = [...form.conjuntosAssociados, ...cnjs];
                  else form.conjuntosAssociados = cnjs;
                } else {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev?.filter(el => el !== cnj.id))
                      cnjs.push(cnj.id)
                    })
                  })
                  if (form.conjuntosAssociados) form.conjuntosAssociados = form.conjuntosAssociados?.filter(set => !cnjs.includes(set));
                  else form.conjuntosAssociados = [];
                }
              }}
            />
            <span style={{ fontWeight: 'bold', color: 'black' }}>{client.nome}</span>
          </Label>
        </AccordionSummary>
        <h2 style={{ marginLeft: '10px', fontSize: '0.8xem' }}>Locais:</h2>
        {client.locaisList && client.locaisList?.map((e, i) => {
          const allSetsFromPlace: string[] = e.conjuntosList?.map((cnj) => cnj.id)
          return (
            <div key={i}>
              <Accordion style={{ backgroundColor: '#e5e5e5', borderRadius: '0', margin: '10px' }} expanded={subExpanded === i} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  key={i}
                  onClick={() => trackEventMatomo('modal config form', 'click', 'acordeon', 'abre/fecha acordeon')}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  IconButtonProps={{
                    onClick: () => subExpanded === i ? setSubExpanded(-1) : setSubExpanded(i)
                  }}
                >
                  <Label className='checkbox'>
                    <input
                      type='checkbox'
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      disabled={!userData.formMigrado && allSetsFromPlace.every(set => cnjsArr.includes(set))}
                      checked={allSetsFromPlace.every(set => cnjsArr.includes(set))}
                      onClick={() => trackEventMatomo('modal config form', 'click', 'input', 'seleciona conjunto')}
                      onChange={(event) => {
                        const cnjs: string[] = [];
                        if (event.target.checked) {
                          e.conjuntosList.forEach((cnj) => {
                            setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                            cnjs.push(cnj.id)
                          })
                          if (form.conjuntosAssociados) form.conjuntosAssociados = [...form.conjuntosAssociados, ...cnjs];
                          else form.conjuntosAssociados = cnjs;
                        } else {
                          e.conjuntosList.forEach((cnj) => {
                            setCnjsArr((prev) => prev?.filter(el => el !== cnj.id))
                            cnjs.push(cnj.id)
                          })
                          if (form.conjuntosAssociados) form.conjuntosAssociados = form.conjuntosAssociados?.filter(set => !cnjs.includes(set));
                          else form.conjuntosAssociados = [];
                        }
                      }}
                    />
                    <span style={{ fontWeight: 'bold', color: 'black' }}>{e.nome}</span>
                  </Label>
                </AccordionSummary>
                <h2 style={{ marginLeft: '10px', fontSize: '0.6em' }}>{t('teamManage.sets')}</h2>
                {e.conjuntosList && e.conjuntosList?.map((el, ind) => {
                  return (
                    <Label className='checkbox' key={ind}>
                      <input
                        onClick={() => trackEventMatomo('modal config form', 'click', 'input', 'seleciona associados')}
                        disabled={!userData.formMigrado && cnjsArr.includes(el.id)}
                        type='checkbox'
                        style={{ marginLeft: '25px', marginRight: '10px' }}
                        checked={cnjsArr.includes(el.id)}
                        onChange={(event) => {
                          const cnjs: string[] = [];
                          if (event.target.checked) {
                            setCnjsArr((prev) => prev ? [...prev, el.id] : [el.id])
                            cnjs.push(el.id)
                            if (form.conjuntosAssociados) form.conjuntosAssociados = [...form.conjuntosAssociados, ...cnjs];
                            else form.conjuntosAssociados = cnjs;
                          } else {
                            setCnjsArr((prev) => prev?.filter(ele => ele !== el.id))
                            cnjs.push(el.id)
                            if (form.conjuntosAssociados) form.conjuntosAssociados = form.conjuntosAssociados?.filter(set => !cnjs.includes(set));
                            else form.conjuntosAssociados = [];
                          }
                        }}
                      />
                      {el.nome}
                    </Label>
                  )
                })}
              </Accordion>
            </div>
          )
        })}
      </Accordion >
    </div >
  )
}
