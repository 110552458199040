import { useTranslation } from "react-i18next"
import { trackEventMatomo } from "../../helpers/matomo"

export default function ModalDelete({
  openDelete,
  setOpenDelete,
  mutate,
  user,
  name
}: {
  openDelete: boolean,
  setOpenDelete: Function,
  mutate: Function,
  user: string,
  name: string
}) {
  const { t } = useTranslation('translation')
  return (
    <div className={`modal ${!!openDelete ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p style={{ textAlign: 'center' }} className="modal-card-title">{t('users.doYouWantToRemoveUser')} <b>{name}</b>?</p>
        </header>
        <section style={{ display: 'flex', justifyContent: 'space-around' }} className="modal-card-body">
          <button
            onClick={() => {
              trackEventMatomo('modal deleção de usuário', 'click', ' button', 'salva')
              mutate(user)
            }}
            className="button is-danger">
            {t('users.remove')}
          </button>
          <button
            className="button"
            onClick={() => {
              trackEventMatomo('modal deleção de usuário', 'click', ' button', 'cancela')
              setOpenDelete()
            }}>
            {t('users.cancel')}
          </button>
        </section>
        <footer className="modal-card-foot">
        </footer>
      </div>
    </div>
  )
}
