import React, { useState } from 'react'
import PlanoDeAcao, { planoDeAcaoType, taskType } from '../../../service/PlanoDeAcao';
import { PlanoDatailContainer, SpanBelongs, Title } from '../style/stylesPlano';
import { Label } from '../../Notifications/style/SingleNotificationStyle';
import AttToken from '../../../helpers/attToken';
import Loading from '../../Loading/Loading';
import queryClient from '../../../service/query';
import { toast } from 'react-toastify';
import getEndOfToday from '../../../helpers/getEndOfToday';
import ReactDatePicker from 'react-datepicker';
import UserSelector from '../../UserSelector';
import { BiArrowFromBottom, BiArrowFromTop, BiPlus, BiTrash } from 'react-icons/bi';
import { Tooltip } from '@material-ui/core';
import { trackEventMatomo } from '../../../helpers/matomo';
import { useTranslation } from 'react-i18next';

type props = {
  plano: planoDeAcaoType,
  setPlano: Function,
  restarting: boolean
}

type FormPaProps = {
  plano: planoDeAcaoType,
  setPlano: Function,
  restarting: boolean,
}

type TaskCompProps = {
  index: number,
  task: taskType
  setPlano: Function,
  plano: planoDeAcaoType,
  restarting: boolean
}

const planoService = new PlanoDeAcao();

const TaskComp = ({ task, index, setPlano, plano, restarting }: TaskCompProps) => {
  const { t } = useTranslation('translation');
  const handleAddTaskResponsible = (uid: string) => {
    task.responsavel = uid;
    setPlano({ ...plano });
  }

  return (
    <div key={index} className='naoConfs'>
      <div className={task.status === 'aprovado' || (task.status !== 'pendente' && restarting) ? 'tasksDisabled' : 'tasks'}>
        <Label style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ width: '25%' }}>Nome da tarefa:&nbsp;</span>
          {task.status === 'aprovado' || (task.status !== 'pendente' && restarting) ? (
            <span>{task.nome}</span>
          ) : (
            <input
              className='input is-fullwidth'
              type='text'
              value={task.nome}
              onChange={(e) => {
                task.nome = e.target.value;
                setPlano({ ...plano });
              }}
              required
            />
          )}
        </Label>
        <div style={{ display: 'flex', alignItems: 'center', color: 'gray', fontSize: '12px', fontWeight: '600', textAlign: 'left', margin: '5px 0', }}>
          <span style={{ width: '25%' }}>{t('ActionPlan.Modal.responsible')}&nbsp;</span>
          {task.status !== 'pendente' ? (
            <span>{task.responsavel}</span>
          ) : (
            <UserSelector
              callback={handleAddTaskResponsible}
              userLabel={t('ActionPlan.Modal.selectTaskResponsible')}
              defaultSelected={task.responsavel || null}
            />
          )}
        </div>
        <Label style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ width: '25%' }}>{t('ActionPlan.Modal.performTaskBy')}&nbsp;</span>
          {task.status !== 'pendente' ? (
            <span>{new Date(task.dataLimite).toLocaleDateString('pt-BR')}</span>
          ) : (
            <ReactDatePicker
              showMonthDropdown
              showYearDropdown
              portalId="root-portal"
              locale="pt"
              dateFormat='dd/MM/yyyy'
              wrapperClassName="inputDateStyle"
              className='input'
              minDate={getEndOfToday().endDate}
              todayButton="Hoje"
              maxDate={plano.dataLimite ? new Date(plano.dataLimite) : null}
              onKeyDown={(e) => e.preventDefault()}
              selected={task.dataLimite ? new Date(task.dataLimite) : new Date()}
              timeIntervals={1}
              onChange={(date: Date) => {
                task.dataLimite = new Date(date).getTime()
                setPlano({ ...plano });
              }}
              required
            />
          )}
        </Label>
        <Label style={{ display: 'flex', alignItems: 'center' }} className='checkbox'>
          <span style={{ width: '27%' }}>{t('ActionPlan.Modal.evidenceRequired')}&nbsp;</span>
          {task.status === 'aprovado' || (task.status !== 'pendente' && restarting) ? (
            <span>{`${task.obrigatorioEvidencia ? `${t('ActionPlan.Modal.yes')}` : `${t('ActionPlan.Modal.no')}`}`}</span>
          ) : (
            <input
              className='checkbox'
              type='checkbox'
              checked={task.obrigatorioEvidencia}
              onChange={(e) => {
                task.obrigatorioEvidencia = e.target.checked;
                setPlano({ ...plano });
              }}
            />
          )}
        </Label>
        <Label style={{ display: 'flex', alignItems: 'center' }} className='checkbox'>
          {task.status === 'aprovado' || (task.status !== 'pendente' && restarting) ? (
            <>
              <span style={{ width: '27%' }}>Detalhes da Tarefa:&nbsp;</span>
              <span>{task.detalhes || `${t('ActionPlan.Modal.noDetailsProvided')}`}</span>
            </>
          ) : (
            <textarea
              className='textarea has-fixed-size'
              onChange={(e) => {
                task.detalhes = e.target.value;
                setPlano({ ...plano });
              }}
              placeholder={t('ActionPlan.Modal.detailTaskOptional')}
              maxLength={1000}
            />
          )}
        </Label>
        {task.status === 'aprovado' && (
          <span className='tag is-success'>{t('ActionPlan.Modal.taskAlreadyCompleted')}</span>
        )}
        {task.status === 'pendente' && (
          <button
            disabled={plano.tasks.length <= 1}
            onClick={() => {
              const newTasks = plano.tasks.filter((el, ind) => ind !== index);
              plano.tasks = newTasks;
              setPlano({ ...plano });
            }}
            className='button is-danger'
          >
            <BiTrash />
          </button>
        )}
      </div>
    </div>
  )
}

const FormPA = ({ plano, setPlano, restarting }: FormPaProps,) => {
  const [inverteOrdem, setInverteOrdem] = useState(true);
  const { t } = useTranslation('translation');

  const addTask = () => {
    const defaultTask: taskType = {
      nome: 'Nova Tarefa',
      status: 'pendente',
      responsavel: '',
      obrigatorioEvidencia: false,
      detalhes: '',
      dataLimite: getEndOfToday().endDate.getTime(),
    }
    if (!plano.tasks || !plano.tasks.length) {
      plano.tasks = [defaultTask]
    } else { plano.tasks.push(defaultTask) }
    setPlano({ ...plano });
  }

  return (
    <PlanoDatailContainer>
      <Title>{t('ActionPlan.Modal.planOptions')}</Title>
      <Label style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ width: '25%' }}>{t('ActionPlan.Modal.actionPlanName')}&nbsp;</span>
        <input
          onClick={() => trackEventMatomo('modal edita PA', 'click', 'input', 'edita valor')}
          className='input is-fullwidth'
          type="text"
          value={plano.nome}
          onChange={(e) => {
            plano.nome = e.target.value;
            setPlano({ ...plano });
          }}
          required
        />
      </Label>
      <div style={{ display: 'flex', alignItems: 'center', color: 'gray', fontSize: '12px', fontWeight: '600', textAlign: 'left', margin: '5px 0', }}>
        <span style={{ width: '25%' }}>{t('ActionPlan.Modal.actionPlanResponsible')}&nbsp;</span>
        <span>{plano.responsavel}</span>
      </div>
      <Label style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ width: '25%' }}>{t('ActionPlan.Modal.finishPlanBy')}&nbsp;</span>
        <span>{new Date(plano.dataLimite).toLocaleDateString('pt-BR')}</span>
      </Label>
      <Title>{t('ActionPlan.Modal.nonConformities')}</Title>
      <div className='naoConfs'>
        {plano.naoConformidades.map((sub, index) => (
          <span key={index} style={{ wordBreak: 'break-all', maxWidth: '95%' }}>{index + 1} - {sub.nome}</span>
        ))}
      </div>
      <Title>{t('ActionPlan.Modal.defineTasksForThisPlan')}</Title>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-around', marginTop: '15px' }}>
        <Tooltip title={t('ActionPlan.Modal.addTask')} style={{ width: '25%' }}>
          <button
            type='button'
            className='button is-success is-small'
            onClick={() => {
              trackEventMatomo('modal edita PA', 'click', 'button', 'salva')
              addTask()
            }}
          >
            <BiPlus />
          </button>
        </Tooltip>
        <Tooltip title={`${t('ActionPlan.Modal.viewTasks')} ${inverteOrdem ? `${t('ActionPlan.Modal.oldestFirst')}` : `${t('ActionPlan.Modal.newestFirst')}`} ${t('ActionPlan.Modal.creationOrder')}`} style={{ width: '25%' }}>
          <button
            onClick={() => {
              trackEventMatomo('modal edita PA', 'click', 'button', 'altera ordem')
              setInverteOrdem(!inverteOrdem)
            }}
            className='button is-small is-info'
            type='button'
          >
            {inverteOrdem ? <BiArrowFromBottom /> : <BiArrowFromTop />}
          </button>
        </Tooltip>
      </div>
      {(!plano.tasks || !plano.tasks.length) && <SpanBelongs>{t('ActionPlan.Modal.noTasksCreatedYet')}</SpanBelongs>}
      <div style={{ display: 'flex', flexDirection: inverteOrdem ? 'column-reverse' : 'column' }}>
        {plano.tasks && plano.tasks.map((task, index) => (
          <TaskComp
            task={task}
            index={index}
            plano={plano}
            setPlano={setPlano}
            restarting={restarting}
          />
        ))}
      </div>
    </PlanoDatailContainer>
  )
}

export default function ModalEditPlan({
  plano,
  setPlano,
  restarting
}: props) {
  const [sending, setSending] = useState(false);
  const { t } = useTranslation('translation');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSending(true);
    await toast.promise(async () => {
      try {
        const token = await AttToken();
        if (token) {
          await planoService.editPlan(token, plano, restarting);
          if (restarting) {
            queryClient.resetQueries(['GET_ANALISES_PENDING']);
            queryClient.resetQueries(['GET_ANALISES_FINALIZADAS']);
            queryClient.resetQueries(['GET_MALSUCEDIDOS']);
          } else {
            queryClient.resetQueries(['GET_PLANOS_ACAO']);
          }
          return true;
        } else {
          throw new Error()
        }
      } catch (err) {
        return err
      }
    }, {
      pending: t('ActionPlan.Modal.editingPlan'),
      success: t('ActionPlan.Modal.planEdited'),
      error: t('ActionPlan.Modal.error')
    })
    setPlano(null);
    setSending(false);
  }

  return (
    <div className={`modal ${plano ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{`${t('ActionPlan.Modal.editing')} ${plano.nome}`}</p>
          <button
            onClick={() => {
              trackEventMatomo('modal edita PA', 'click', 'button', 'deleta PA')
              setPlano(null)
            }}
            className="delete"
            aria-label="fechar"
            type='button'
            disabled={sending}
          />
        </header>
        <section className="modal-card-body">
          {!sending ? (
            <form onSubmit={(e) => {
              trackEventMatomo('modal edita PA', 'click', 'form', 'salva PA')
              handleSubmit(e)
            }}>
              <FormPA
                plano={plano}
                setPlano={setPlano}
                restarting={restarting}
              />
              {plano.tasks.every(task => task.status === 'aprovado') ? (
                <span className='tag is-warning'>{t('ActionPlan.Modal.addAtLeastOneNewTask')}</span>
              ) : (
                <button
                  className='button is-success is-fullwidth'
                  disabled={!plano.tasks || !plano.tasks.length}
                  type='submit'
                >
                  {`${t('ActionPlan.Modal.finishEditing')} ${plano.nome}`}
                </button>
              )}

            </form>
          ) : (
            <div>
              <h1>{t('ActionPlan.Modal.editingPlan2')}</h1>
              <Loading />
            </div>
          )}
        </section>
      </div>
    </div>
  )
}
