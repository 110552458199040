import { memo } from "react"
import { trackEventMatomo } from "../../../helpers/matomo"
import { Tooltip } from "@material-ui/core"
import { FaFileDownload } from "react-icons/fa";

function ModalImagePreview({
  image,
  open,
  setOpen,
}: {
  image: string,
  open: Boolean,
  setOpen: Function,
}) {

  return (
    <div className={`modal ${open ? "is-active" : ""}`} style={{ zIndex: 999 }}>
      <div className="modal-background" onClick={() => {
        trackEventMatomo('Modal de imagem', 'click', 'modal', 'fecha')
        setOpen()
      }} />
      <div className="modal-card">
        <header className="modal-card-head">
          <button onClick={() => {
            trackEventMatomo('componente de imagem', 'click', 'button', 'download')
            const handleDownload = async () => {
              const a = document.createElement("a");
              a.href = await image;
              a.download = "axyma.png";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
            handleDownload()
          }
          }
            className="button is-small is-warning">Download&nbsp;<FaFileDownload /></button>
          <button onClick={() => {
            setOpen(!open)
            trackEventMatomo('Modal de imagem', 'click', 'modal', 'fecha')
          }}
            className="delete"
            aria-label={'Fechar'}
            style={{ backgroundColor: 'red' }} />
        </header>
        <div className="modal-card-body">
          <img style={{ maxHeight: '75svh'}} src={image} alt={image} />
        </div>
      </div>
    </div>
  )
}

export default memo(ModalImagePreview)