import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { AuthBodyType } from "./types";
import { ChamadoChecklist, chamadoConfigTypeSubitem } from "../components/Filling/Types";
import { linkType } from "../components/Checklists/Modals/FormModal/FillingConfig";
import { ticketConfigType } from "../components/Checklists/Modals/FormModal/FormChamadosConfig";
import { ModeloQRCode } from "./QrCode";

export type formToSend = {
  editavel?: {
    cancela?: boolean;
    clone?: boolean;
  };
  id: string;
  nome?: string;
  typeId?: string;
};

export type itemTypeToSend = {
  formId: string;
  itemIndex: number;
  item: {
    nome?: string;
    peso?: number;
  };
};

export type NewFormType = {
  nome: string;
  peso: number;
  subitens: [
    {
      id: number;
      nome: string;
      tipo: string;
      peso: number;
      threshold: number;
      avaliacao: {
        nome: string;
        valor: number;
      }[];
    }
  ];
};

export type conjuntoAssociadoTree = {
  checked: boolean;
  id: string;
  locaisList: {
    checked: boolean;
    id: string;
    conjuntosList: {
      checked: boolean;
      id: string;
    }[];
  }[];
};

export type notifiConfigType = {
  id: string;
  notifiEm: string;
};

export type ChecklistType = {
  valorMaxScoring?: number;
  ticketConfig?: ticketConfigType;
  dataAlteracao: number;
  dataCadastro: number;
  link: linkType[];
  editavel: {
    cancela?: boolean;
    clone?: boolean;
    mandatory: boolean;
  };
  empresa: string;
  id: string;
  identificadorLista?: string;
  nome: string;
  status: boolean;
  userNome: string;
  versao: number;
  typeId: string;
  tipoServico: string;
  itens: ItemChecklist[];
  equipesExcecao?: string[] | null;
  conjuntosAssociados?: string[];
  preenchimentoWeb?: boolean;
  preenchimentoDeslogado: boolean;
  abreChamado: {
    durante: boolean;
    apos: boolean;
  };
  range?: {
    [index: number]: string;
  };
  notifiConfig?: notifiConfigType[];
  modeloQrCode?: ModeloQRCode
};

export type OptionsType = {
  chamadoObrigatorio: string;
  limiteChamado: number;
  nome: string;
  tipo: string;
  tipoChamado: number[];
};

export type ocorrenciaType = {
  id: number;
  itemId: string;
  nome: string;
  subitens: SubitemChecklist[];
};

export type ItemChecklist = {
  nomeOcorrencia?: string;
  ocorrenciaMultipla?: boolean;
  ocorrencias?: ocorrenciaType[];
  maxOcorrencias?: number | null;
  id: string;
  nome: string;
  peso?: number;
  subitens: ChecklistSubitem[];
  score?: number;
  scorePorcentagem?: string;
};

type NotificacaoModelo = {
  id: number | null;
  notificar: string | null;
};

export type ChecklistHelpType = {
  choose?: string;
  comentario?: string;
  video?: string;
  foto?: string;
  newPhoto?: boolean;
};

export type TipoSelecaoType = "user" | "local" | "cliente" | "conjunto" | "unidade" | "default";

export interface ChecklistSubitem {
  geraQrCode?: boolean;
  abrePlano?: boolean;
  score?: number;
  ticketConfig?: ticketConfigType;
  nota?: number;
  id: number;
  nome: string;
  itemid: string;
  itemid_old?: string | null;
  new_id?: string | null;
  avaliacao?: any[] | null;
  checked?: boolean | null;
  comentario?: boolean | null;
  foto?: boolean | null;
  galeriaFoto?: boolean | null;
  grupoNotificacao?: number | null;
  hideImg?: boolean | null;
  hideComent?: boolean | null;
  help?: ChecklistHelpType | null;
  minNumberVal?: number | null;
  maxNumberVal?: number | null;
  noDecimalNumber?: boolean | null;
  qtdeExataDecimalInput?: number | null;
  obrigatoriedade?: {
    img?: boolean | null;
    ocorrencia?: boolean | null;
    preenchimento?: boolean;
    conforme?: {
      img?: boolean;
      ocorrencia?: boolean;
    };
    naoConforme?: {
      img?: boolean;
      ocorrencia?: boolean;
    };
    NA: {
      img?: boolean | null;
      ocorrencia?: boolean | null;
    } | null;
  } | null;
  padrao?: any | null;
  padraoMultiple?: any[] | null;
  padraoLista?: string[] | null;
  peso?: number | null;
  selecao?: any[] | null;
  selecionados?: any[] | null;
  threshold: number;
  tipo: string;
  tipoChamado?: number | null;
  tipoNotificacao?: NotificacaoModelo | null;
  assinatura?: string;
  chamadosConfig?: chamadoConfigTypeSubitem;
  chamados?: ChamadoChecklist[];
  tipoSelecao?: TipoSelecaoType;
  ocorrencia?: string;
  img?: string[];
  scorePorcentagem?: string;
  obs?: string;
}

export type SubitemChecklist = {
  chamados?: ChamadoChecklist[];
  chamadosConfig?: chamadoConfigTypeSubitem;
  checked?: boolean;
  comentario: boolean;
  foto: boolean;
  galeriaFoto: boolean;
  id: string;
  itemid: string;
  maxNumberVal: number;
  minNumberVal: number;
  new_id: string;
  nome: string;
  obrigatoriedade: {
    NA: {
      img: boolean;
      ocorrencia: boolean;
    };
    img: boolean;
    ocorrencia: boolean;
    preenchimento: boolean;
  };
  padrao: string;
  tipo: string;
};

export type simpleFormType = {
  id: string;
  nome: string;
  conjs: string[];
  equipes: string[];
};

class Checklists {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/checklists`,
  });

  getAllChecklists = async (body: AuthBodyType & { empresa: string; itensPerPage: number; pageNumber: number; filter: string | null }) => {
    try {
      const data: AxiosResponse = await this.api.post("/getList", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  createChecklist = async (body: AuthBodyType, nome: string, itemData: NewFormType[], preenchimentoWeb: boolean, preenchimentoDeslogado: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/newForm", {
        ...body,
        nome,
        itemData,
        preenchimentoWeb,
        preenchimentoDeslogado,
      });
      return data.data.forms;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  deleteChecklist = async (body: AuthBodyType & { form: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/deleteForm", {
        ...body,
      });
      return data.data.forms;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  updateChecklist = async (body: AuthBodyType, form: ChecklistType, modeloQrCode?: ModeloQRCode, deleteQrCodes?: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/changeForm", {
        ...body,
        form,
        modeloQrCode,
        deleteQrCodes,
      });
      return data.data.forms;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  copyChecklist = async (
    body: AuthBodyType & {
      formId: string;
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/duplicaForm", {
        ...body,
      });
      return data.data.forms;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  unassociateType = async (
    body: AuthBodyType & {
      formId: string;
      typeId: string;
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/desassociar", {
        ...body,
      });
      return data.data.forms;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  getSimpleForm = async (body: AuthBodyType, planoDeAcao?: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/getChecklistSimples", {
        ...body,
        planoDeAcao,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  getFormConfigData = async (body: AuthBodyType, formulario: string, cliente: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/getClienteFormulario", {
        ...body,
        formulario,
        cliente,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  editConfigForm = async (
    body: AuthBodyType,
    formulario: string,
    cliente: string,
    clienteFormulario: {
      chamadoObrigatorio: boolean;
      cliente: string;
      delegacao: boolean;
      distanciaLimite: number | null;
      duracaoValidade: number;
      empresa: string;
      formulario: string;
      gravaPOS: boolean;
      id: string;
      limiteChamado: number;
      qrcode: boolean;
      tipoChamado: number[];
      tipoValidade: string | null;
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/editClienteFormulario", {
        ...body,
        formulario,
        cliente,
        clienteFormulario,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  getOptionsBySubitem = async (body: AuthBodyType, formulario: string, cliente: string, subId: string | null, subIndex: number, itemid: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/getOptionsBySubitem", {
        ...body,
        formulario,
        cliente,
        subId,
        subIndex,
        itemid,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  editOptionsForSubitem = async (
    body: AuthBodyType,
    formulario: string,
    cliente: string,
    subId: string | null,
    subIndex: number,
    itemid: string,
    options: OptionsType
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/editOptionsForSubitem", {
        ...body,
        formulario,
        cliente,
        subId,
        subIndex,
        itemid,
        options,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  getPreenchimentoWeb = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getPreenchimentoWeb", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  getChecklistForWebFilling = async (token: AuthBodyType | null, formId: string, deslogado: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/getChecklistForWebFilling", {
        ...token,
        formId,
        deslogado,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  changeRange = async (token: AuthBodyType, itemRange: { index: number; name: string; range: number }[] | null, checked: boolean, formId: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/changeRange", {
        ...token,
        itemRange,
        checked,
        formId,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };

  getSimples = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getChecklistWebSimples", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.request?.status);
        return error.response?.status;
      }
    }
  };
}

export default Checklists;
