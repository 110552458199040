import { TextField, Tooltip } from '@material-ui/core'
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChecklistReportType,
  equipeReport
} from '../../../service/Reports';
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import ReactToPrint from 'react-to-print';
import { AiFillSetting } from 'react-icons/ai';
import { Label } from '../../../StyledComponents/Input/generic';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../../helpers/attToken';
import PrintService, { printTemplate } from '../../../service/PrintTemplate';
import { PrintTemplateType } from '../../Checklists/Types';
import ModalAprovation from '../../Aprovations/Modal/ModalAprovation';
import PrintIcon from '@mui/icons-material/Print';
import TicketListReportComponent from '../TicketListReportComponent';
import DataDetail from './DataDetail';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { CiLink } from "react-icons/ci";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";
import Reports from '../../../service/Reports';
import { Autocomplete, Skeleton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ModalExportCSV from './ModalExportCSV';
import '../styles.css'
import SettingsDivComp from '../SettingsDiv';
import TicketsListComponent from '../TicketsListComponent';
import { trackEventMatomo } from '../../../helpers/matomo';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ModalQRCode from './ModalQRCode';

type props = {
  selected?: ChecklistReportType,
  selectedObj?: ChecklistReportType[],
  openModal?: boolean,
  setOpenModal?: Function,
  commingFromFilled?: boolean,
  numberOfTicktes?: number,
  isLoadingLink?: boolean,
  inicio?: number,
  onClose?: () => void
}

export type addTicketType = {
  itemIndex?: number | boolean,
  subItemIndex?: number | boolean,
  active?: boolean
}

export type campoOcultosType = {
  [key: string]: string
}

const printTemplateService = new PrintService();

function ModalDetailPrint({ selected, openModal, setOpenModal, isLoadingLink, selectedObj, onClose }: props) {
  const reportsService = new Reports();

  const { t } = useTranslation('translation');
  const [showSettings, setShowSettings] = useState(false);
  const [showHeaders, setShowHeaders] = useState(true);
  const [showNotSelected, setShowNotSelected] = useState<boolean>(true);
  const [showScoring, setShowScoring] = useState(false);

  const [openAprovation, setOpenAprovation] = useState(false);
  const [aprove, setAprove] = useState('');
  const [showHideFields, setShowHideFields] = useState(false);
  const [showLink, setShowLink] = useState(selected?.hasLink || (Array.isArray(selected) && selected.length === 1 && selected[0].hasLink) ? true : false);
  const [openModalCSV, setOpenModalCSV] = useState(false);
  const [hideHierarchy, setHideHierarchy] = useState({
    cliente: true,
    local: true,
    conjunto: true,
    unidade: true
  });
  const [hideEquipe, setHideEquipe] = useState(true)
  const [hideUser, setHideUser] = useState(true)
  const [hideIdent, setHideIdent] = useState(true)
  const [hideAprov, setHideAprov] = useState(true)
  const [hideDate, setHideDate] = useState({
    inicio: true,
    fim: true,
  })
  const [hideNAplica, setHideNAplica] = useState(true);

  const { data, isLoading } = useQuery({
    queryKey: ['GET_PRINT_TEMPLATE'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await printTemplateService.getAllTemplates(token)
        return response.printTemplates;
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true
  })

  const [printTemplate, setPrintTemplate] = useState<PrintTemplateType>({} as PrintTemplateType);
  const [openModalQrCode, setOpenModalQrCode] = useState(false);

  useEffect(() => {
    if (data && !isLoading) {
      const print = data?.find((elem: PrintTemplateType) => elem.dafaultTemplate)
      if (print) setPrintTemplate(print)
    }
  }, [data, isLoading]);

  const componentRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const escape = useCallback((e: any): void => {
    if (e.key === 'Escape' && openModal) {
      setOpenModal && setOpenModal(false);
    }
  }, [openModal, setOpenModal])

  useEffect(() => {
    document.addEventListener('keydown', escape, true)
  }, [escape])

  const findTeams = (team: equipeReport) => {
    let names: string[][] = [];
    if (team) {
      names = Object.keys(team)?.map((e) => ([
        team[e].nome
      ]))
    }
    return names;
  }

  const camposOcultos = (val: campoOcultosType) => {
    return Object.keys(val)?.map((key) => (
      <>
        <p>{`${key}: ${val[key]}`}</p>
      </>
    ))
  }

  const copyToClipboard = () => {
    let copyText = ''
    if (linkRef.current) copyText = linkRef.current.value
    copy(copyText);
    alert(`${t('Reports.modalDetailP.linkCopiedToClipboard')}`);
  }

  const linkRef = useRef<HTMLInputElement>(null)

  const { mutate: linkLogado, isLoading: loadingLinkLogado, data: logado } = useMutation({
    mutationKey: ['GET_LINK'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        setShowLink(false);
        if (selected && Array.isArray(selected)) {
          const response = await reportsService.getLink(token, String(selected[0].username), null, Number(selected[0].inicio), String(process.env.REACT_APP_PROJECT_NAME))
          if (selected && selected.hasLink) {
            selected.hasLink = '';
          }
          setShowLink(true)
          return response.message
        } else {
          const response = await reportsService.getLink(token, String(selected?.username), null, Number(selected?.inicio), String(process.env.REACT_APP_PROJECT_NAME))
          if (selected && selected.hasLink) {
            selected.hasLink = '';
          }
          setShowLink(true)
          return response.message
        }
      }
    }
  })

  const { mutate: linkDeslogado, isLoading: loadingLinkSDeslogado, data: deslogado } = useMutation({
    mutationKey: ['GET_LINK'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        setShowLink(false);
        const response = await reportsService.getLink(token, null, String(selected?.id), Number(selected?.inicio), String(process.env.REACT_APP_PROJECT_NAME))
        if (selected && selected.hasLink) {
          selected.hasLink = '';
        }
        setShowLink(true)
        return response.message
      }
    }
  })

  const findIdentificador: () => { nome: string, resposta: string } = () => {
    const identifiData = { nome: "", resposta: "" };
    if (!selected?.identificadorLista) {
      return identifiData
    }
    const splitedIdentifi = selected.identificadorLista.split('|');
    const indexItem = Number(splitedIdentifi[2]);
    const indexSub = Number(splitedIdentifi[3]);
    if (!isNaN(indexItem) && !isNaN(indexSub)) {
      const respostaIdentifi = selected.itens[indexItem].subitens[indexSub].ocorrencia;
      const nomeIdentifi = selected.itens[indexItem].subitens[indexSub].nome;
      identifiData.nome = nomeIdentifi;
      identifiData.resposta = respostaIdentifi || "";
      return identifiData
    } else return identifiData
  }

  if (!selected) return <p>{t('Reports.modalDetailP.noResponses')}</p>

  return (
    <>
      {openAprovation &&
        <ModalAprovation
          openAprovation={openAprovation}
          setOpenAprovation={setOpenAprovation}
          aprove={aprove}
          setAprove={setAprove}
          checklist={Number(selected.inicio)}
          usuarioExecutor={selected.userName}
          setOpenModal={setOpenModal}
          selected={selected}
        />}
      {openModalCSV &&
        <ModalExportCSV
          openModal={openModalCSV}
          setOpenModal={setOpenModalCSV}
          form={selected}
        />
      }
      {openModalQrCode &&
        <ModalQRCode
          openModal={openModalQrCode}
          setOpenModal={setOpenModalQrCode}
          identificador={findIdentificador()}
          id={selected?.idForm || ''}
          selected={selected}
        />
      }
      {!openAprovation && !openModalCSV && !openModalQrCode &&
        <div className={`modal ${openModal ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={escape} onClick={() => setOpenModal && setOpenModal(false)} />
          <div className="modal-card" style={{ width: `${window.innerWidth <= 760 ? '100%' : '800px'}`}}>
            <header className="modal-card-head headerStyle">
              <div>
                <GenericHeader>
                  {t('Filling.visualizacaoForm')}
                </GenericHeader>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {window.location.pathname !== '/aprovacao' &&
                  <>
                    <ReactToPrint
                      pageStyle={'@page { size: auto; dpi: 1, display: block, height: window.innerHeight}'}
                      trigger={() =>
                        <button onClick={(e) => {
                          e.preventDefault()
                          trackEventMatomo('Modal de visualização de form', 'click', 'button', 'imprime')
                        }}
                          disabled={isLoading}
                          className='button is-success is-small'>
                          <PrintIcon />
                          {t('checklists.printConfig.Imprimir')}
                        </button>}
                      content={() => componentRef.current}
                    />
                    <button className='button is-small is-info' onClick={() => {
                      trackEventMatomo('Modal de visualização de form', 'click', 'button', 'abre modal csv')
                      setOpenModalCSV(true)
                    }}>
                      <RiFileExcel2Fill />&nbsp;
                      {t('Reports.modalDetailP.generateCSV')}
                    </button>
                    {window.location.pathname.split('/')[1] !== 'detalhe-checklist' &&
                      <button
                        onClick={location.pathname === '/relatorios' ? () => {
                          trackEventMatomo('Modal de visualização de form', 'click', 'button', 'gera link')
                          linkLogado()
                        } : () => {
                          trackEventMatomo('Modal de visualização de form', 'click', 'button', 'gera link')
                          linkDeslogado()
                        }}
                        className='button is-small'
                        disabled={loadingLinkSDeslogado || loadingLinkLogado || (Array.isArray(selected) && selected.length > 1)}
                      >
                        <CiLink />&nbsp;
                        {Array.isArray(selected) && selected.length > 1 ? t('Reports.modalDetailP.linkDisabledForMultipleChecklists') : showLink || loadingLinkSDeslogado || loadingLinkLogado ? t('Reports.modalDetailP.generateNewLink') : t('Reports.modalDetailP.generateLink')}
                      </button>
                    }
                    {!Array.isArray(selected) && window.location.pathname.split('/')[1] === 'relatorios' && (
                      <button
                        style={{ marginTop: '10px' }}
                        onClick={() => setOpenModalQrCode(true)}
                        className='button is-small is-black'
                        disabled={!findIdentificador().nome || !findIdentificador().resposta}
                      >
                        <QrCode2Icon />&nbsp;
                        {t('Reports.managePreFilledQRCodes')}
                      </button>
                    )}
                  </>
                }
                <Tooltip title={t('options')}>
                  <button
                    className={showSettings ? 'button is-dark is-small' : 'button is-warning is-small'}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSettings(!showSettings)
                      trackEventMatomo('Modal de visualização de form', 'click', 'button', 'configurações')
                    }}
                  >
                    {t('options')}&nbsp;<AiFillSetting />
                  </button>
                </Tooltip>
                {window.location.pathname.split('/')[1] !== 'detalhe-checklist' && <button
                  className='button is-danger is-small'
                  onClick={() => {
                    onClose && onClose()
                    setOpenModal && setOpenModal(false);
                    trackEventMatomo('Modal de visualização de form', 'click', 'button', 'fecha')
                  }}
                >
                  {t('close')}
                </button>}
              </div>
            </header>
            <div className="modal-card-body" style={{padding: '2em'}}>
              {isLoadingLink ? (
                <>
                  <Skeleton variant='rounded' height={40} sx={{ marginBottom: '10px' }} />
                  <Skeleton variant='rounded' height={40} sx={{ marginBottom: '10px' }} />
                  <Skeleton variant='rounded' height={180} />

                </>
              ) : <>
                {
                  <>
                    {(showLink || loadingLinkSDeslogado || loadingLinkLogado) &&
                      <>
                        <Label>Link</Label>
                        <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
                          {!selected?.hasLink && (loadingLinkLogado || loadingLinkSDeslogado) ? <Skeleton style={{ marginTop: '-.7em' }} height={50} width={'100%'} /> :
                            <input
                              ref={linkRef}
                              disabled
                              value={selected?.hasLink || (Array.isArray(selected) && selected?.length === 1 && selected[0].hasLink)
                                ? `${process.env.REACT_APP_AXYMA_FRONT_URL}${'/detalhe-checklist'}/${Array.isArray(selected)
                                  ? selected[0]?.hasLink : selected.hasLink}/${Array.isArray(selected)
                                    ? selected[0]?.username : selected.username}`
                                : location.pathname === '/relatorios' ? logado : deslogado}
                              className='input is-small is-fullwidth'
                            />
                          }
                          <Tooltip title={`${t('checklists.subitemModal.preenchimento.copiar')}`}>
                            <button
                              disabled={!selected?.hasLink && (loadingLinkLogado || loadingLinkSDeslogado)}
                              onClick={() => {
                                copyToClipboard()
                                trackEventMatomo('Modal de visualização de form', 'click', 'button', 'copia link')
                              }}
                              className='button is-small is-success'>
                              <ContentCopyIcon />
                            </button>
                          </Tooltip>
                        </div>
                      </>
                    }
                    {window.location.pathname.split('/')[1] !== 'detalhe-checklist' &&
                      <div style={{ paddingBottom: '7px', marginBottom: '5px' }}>
                        {isLoading ? <Skeleton variant='rounded' height={40} /> :
                          <Autocomplete
                            value={printTemplate ? {
                              value: printTemplate?.id as string || '',
                              label: printTemplate.name || ''
                            } : undefined}
                            disabled={!data}
                            id="combo-box-demo"
                            sx={{ margin: '5px 0px 5px 0px', width: '100%' }}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            filterSelectedOptions
                            disableClearable
                            onClick={() => trackEventMatomo('Modal de visualização de form', 'click', 'select', 'seleciona valor')}
                            options={
                              data
                                ?.filter((elem: PrintTemplateType) => elem.status === true)
                                ?.sort((a: PrintTemplateType, b: PrintTemplateType) => {
                                  return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : - 1
                                })
                                ?.filter((e: printTemplate) => e.status === true)
                                ?.map((ele: any) => ({
                                  label: ele.name,
                                  value: ele.id,
                                }))}
                            onChange={(event: any, newValue: { label: string, value: string } | null) => {
                              const getTemplate = data?.find((el: printTemplate) => el.id === newValue?.value)
                              setPrintTemplate(getTemplate)
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.value}>
                                <span>{option.label}</span>
                              </li>)}
                            renderInput={(params) => <TextField {...params} label={t('checklists.printConfig.Selecione_modelo')} />}
                          />
                        }
                      </div>
                    }
                  </>
                }
                {showSettings && (
                  <>
                    <SettingsDivComp
                      camposOcultos={selected.camposOcultos}
                      showNotSelected={showNotSelected}
                      setShowNotSelected={setShowNotSelected}
                      showScoring={showScoring}
                      setShowScoring={setShowScoring}
                      showHideFields={showHideFields}
                      setShowHideFields={setShowHideFields}
                      showHeaders={showHeaders}
                      setShowHeaders={setShowHeaders}
                      hideHierarchy={hideHierarchy}
                      setHideHierarchy={setHideHierarchy}
                      hideEquipe={hideEquipe}
                      setHideEquipe={setHideEquipe}
                      hideUser={hideUser}
                      setHideUser={setHideUser}
                      hideIdent={hideIdent}
                      setHideIdent={setHideIdent}
                      hideAprov={hideAprov}
                      setHideAprov={setHideAprov}
                      hideDate={hideDate}
                      setHideDate={setHideDate}
                      hideNAplica={hideNAplica}
                      setHideNAplica={setHideNAplica}

                    />
                  </>
                )}
                {
                  (selected.chamadosConfig ||
                    (
                      !selected.chamadosConfig &&
                      selected.chamados &&
                      selected.chamados.length
                    )) && (
                    <div style={{ borderBottom: '1px solid gray', borderTop: '1px solid gray', padding: '7px' }}>
                      {selected.chamadosConfig &&
                        <TicketListReportComponent
                          addingTickets={{
                            itemIndex: false,
                            subItemIndex: false,
                            active: true
                          }}
                          chamadosObj={selected.chamados}
                          chamadosConfig={selected.chamadosConfig}
                          formId={selected.idForm ? selected.idForm : selected.id || '-'}
                          inicio={Number(selected.inicio)}
                          commingFromSub={false}
                          chamadosConfigSub={undefined}
                          selected={selected}
                        />
                      }
                      {
                        !selected.chamadosConfig &&
                        selected.chamados &&
                        selected.chamados.length &&
                        <TicketsListComponent
                          chamadosObj={selected.chamados}
                          chamados={selected.chamados}
                          chamadosConfig={undefined}
                        />
                      }
                    </div>
                  )
                }
                {window.location.pathname === '/aprovacao' &&
                  selected.status === 'pendente' &&
                  <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '.5em' }}>
                    <button className='button is-small is-success'
                      onClick={() => {
                        setOpenAprovation(true);
                        setAprove('aprovado')
                      }}>
                      {t('checklists.printConfig.Aprovar')}
                    </button>
                    <button
                      className='button is-small is-danger'
                      onClick={() => {
                        setOpenAprovation(true);
                        setAprove('reprovado')
                      }}
                    >{t('checklists.printConfig.Reprovar')}
                    </button>
                  </div>}
                <div style={{ backgroundColor: '#ffffff'}} ref={componentRef}>
                  {selectedObj?.length ? selectedObj?.map((elem) =>
                    <DataDetail
                      printTemplate={printTemplate}
                      selected={elem}
                      showHeaders={showHeaders}
                      showScoring={showScoring}
                      findTeams={findTeams}
                      showHideFields={showHideFields}
                      camposOcultos={camposOcultos}
                      hideHierarchy={hideHierarchy}
                      hideEquipe={hideEquipe}
                      hideUser={hideUser}
                      hideIdent={hideIdent}
                      hideAprov={hideAprov}
                      hideDate={hideDate}
                      hideNAplica={hideNAplica}
                      showNotSelected={showNotSelected}
                    />) :
                    <DataDetail
                      printTemplate={printTemplate}
                      selected={selected}
                      showHeaders={showHeaders}
                      showScoring={showScoring}
                      findTeams={findTeams}
                      showHideFields={showHideFields}
                      camposOcultos={camposOcultos}
                      hideHierarchy={hideHierarchy}
                      hideEquipe={hideEquipe}
                      hideUser={hideUser}
                      hideIdent={hideIdent}
                      hideAprov={hideAprov}
                      hideDate={hideDate}
                      hideNAplica={hideNAplica}
                      showNotSelected={showNotSelected}
                    />}
                </div>
              </>}
            </div>
          </div >
        </div >
      }
    </>
  )
}

export default memo(ModalDetailPrint)