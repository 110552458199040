import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";

export type GetListTypes = {
  data: CreateUpdateBody,
  deepLink: string
}

export type ModeloQRCode = { //Concat de `${IndexItem}||${IndexSubitem}`
  [posicao: string]: {
    nome: string, //nome do subitem
    tipo: string, //tipo do subitem
    posicao: string //Concat de `${IndexItem}||${IndexSubitem}`
  }
}

//Arquivos para funcionalidades de tipos de serviço
export type RespostasModeloType = {
  [id: string]: { // ID será a concatenação do index do Item + "||" + index do subitem ex: "0||10"
    subitemId: number // Index do Subitem,
    itemId: number // Index do item,
    tipo: string // tipo do subitem,
    ocorrencia?: string | null,
    nota?: number | null,
    selecionados?: boolean[] | string[] | null,
    comentario?: string | null,
    foto?: string[] | null
  }
}

export type CreateUpdateBody = {
  nome: string,
  id: string,
  modeloId: string,
  respostasModelo: RespostasModeloType
  status?: boolean
}

export default class QrCode {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/qrCode`,
  });

  createUpdateQrCode = async (body: AuthBodyType & CreateUpdateBody) => {
    try {
      const data: AxiosResponse = await this.api.post("/updateQrCode", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  getById = async (body: AuthBodyType & { formId: string, identificador: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/getById", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  getList = async (body: AuthBodyType & { formId: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/getListByModel", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };
}
