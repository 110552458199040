import { TFunction } from "react-i18next";
import { ChamadoChecklist, FormToFilling } from "./Types";

type returnType = {
  success: boolean;
  item: string | undefined;
  subItem: string | undefined;
  chamado?: boolean;
  texto?: string;
  ocorrencia?: string;
  itemId?: string;
  subItemId?: number | null;
};

const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export default function CheckMandatory(checklist: FormToFilling, t: TFunction<"translation", undefined>): returnType {
  let result: returnType = {
    success: true,
    item: "",
    subItem: "",
    chamado: false,
    texto: "",
    itemId: "",
    subItemId: null,
  };
  for (const element of checklist.itens) {
    if (element.ocorrenciaMultipla && element.ocorrencias && element.ocorrencias?.length) {
      element.ocorrencias.filter((oco) => !oco.deleted);
      for (const oco of element.ocorrencias) {
        for (const el of oco.subitens) {
          if (el.chamadosConfig) {
            if (el.chamadosConfig.chamadoObrigatorio === "sempre" && (!el.chamados || el.chamados?.length === 0)) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                chamado: true,
                texto: "",
                itemId: element.id,
                subItemId: null,
              };
              break;
            }
          }
          if (el.chamados) {
            if (el.chamados.some((elem) => !elem.textChamado || !elem.textChamado?.length)) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `${t('Filling.checkMandatory.ticketWithMissingMessageInItem')} "${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (el.chamados.some((elem) => !elem.dateChamado || elem.dateChamado === undefined)) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `${t('Filling.checkMandatory.ticketWithoutDateInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            break;
          }
          if (el.tipo !== "avaliacao" && el.tipo !== "email") {
            if (
              el.ocorrencia ||
              el.assinatura ||
              el?.selecionados?.some((e) => e === true) ||
              el?.selecionados?.some((e) => {
                const element: { texto: string } = e as {
                  texto: string;
                };
                return element?.texto?.length;
              })
            ) {
              if ((!el.img || el.img?.length === 0) && el?.obrigatoriedade?.img) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
                break;
              }
            }
          }
          if (el.tipo !== "avaliacao" && el.tipo !== "email") {
            if (
              el.ocorrencia ||
              el.assinatura ||
              el?.selecionados?.some((e) => e === true) ||
              el?.selecionados?.some((e) => {
                const element: { texto?: string } = e as {
                  texto?: string;
                };
                return element?.texto?.length;
              })
            ) {
              if ((!el.obs || el.obs?.length <= 0) && el?.obrigatoriedade?.ocorrencia) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
                break;
              }
            }
          }
          switch (el.tipo) {
            case "data":
            case "hora":
              if (el.obrigatoriedade?.preenchimento && !el.ocorrencia) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              if (el.ocorrencia) {
                el.checked = true;
              }
              break;
            case "textoCurto":
            case "textoLongo":
              if (el.obrigatoriedade?.preenchimento && !el.ocorrencia?.length) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              if (el.ocorrencia?.length > 0) {
                el.checked = true;
              }
              break;
            case "list":
              if (el.selecionados) {
                if (el.obrigatoriedade?.preenchimento || (el?.selecionados?.length > 1 && el.selecionados?.some((e: any) => !e?.texto?.length))) {
                  el?.selecionados?.some((e) => {
                    const element: { texto: string } = e as { texto: string };
                    return element?.texto === "";
                  });
                  return {
                    success: false,
                    item: element.nome,
                    subItem: el.nome,
                    itemId: el.itemid,
                    subItemId: el.id,
                  };
                }
              }
              if (el.selecionados) {
                el?.selecionados?.some((e) => {
                  const element: { texto: string } = e as { texto: string };
                  const elementNotChecked = element?.texto?.length <= 0;
                  if (!elementNotChecked) {
                    el.checked = true;
                  }
                  return elementNotChecked;
                });
              }
              break;
            case "assinatura":
              if (el.obrigatoriedade?.preenchimento) {
                if (!el.assinatura || el.assinatura?.length === 0) {
                  return {
                    success: false,
                    item: element.nome,
                    subItem: el.nome,
                    itemId: el.itemid,
                    subItemId: el.id,
                  };
                }
                el.checked = true;
              } else if (el.assinatura && el.assinatura?.length > 0) {
                el.checked = true;
              }
              break;
            case "email":
              if (el?.ocorrencia?.length > 0 && !regexEmail.test(el.ocorrencia)) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              if (el.obrigatoriedade?.preenchimento && !el?.ocorrencia?.length) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  texto: `${t('Filling.checkMandatory.mandatoryFieldInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              if (el.ocorrencia !== "" && regexEmail.test(el.ocorrencia)) {
                el.checked = true;
              }
              break;
            case "cpfcnpj":
              if (el?.ocorrencia?.length > 0 && ![14, 18].includes(el.ocorrencia?.length)) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              if ([14, 18].includes(el.ocorrencia?.length)) {
                el.checked = true;
              }
              break;
            case "rg":
              if (el?.ocorrencia?.length > 0 && el.ocorrencia?.length !== 13) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              break;
            case "placa":
              if (el?.ocorrencia?.length > 0 && el.ocorrencia?.length !== 8) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              if (el.ocorrencia?.length === 8) {
                el.checked = true;
              }
              break;
            case "telefone":
              if (el?.ocorrencia?.length > 0 && el.ocorrencia?.length < 12) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              if (el.ocorrencia?.length >= 12) {
                el.checked = true;
              }
              break;
            case "cep":
              if (el?.ocorrencia?.length > 0 && el.ocorrencia?.length < 9) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              if (el.ocorrencia?.length === 9) {
                el.checked = true;
              }
              break;
            case "numero":
              if (el?.ocorrencia?.length > Number(el.maxNumberVal) || el?.ocorrencia?.length < Number(el.minNumberVal)) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              break;
            case "multiple":
            case "unique":
              if (el.obrigatoriedade?.preenchimento && (!el?.selecionados?.length || !el?.selecionados.some((e) => e === true))) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              if (el.selecionados && el.selecionados?.length > 0 && el.selecionados.some((e) => e)) {
                el.checked = true;
              }
              break;
            case "avaliacao":
              if (typeof el.nota !== "number" && el.obrigatoriedade?.preenchimento) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              if (typeof el.nota === "number") {
                el.checked = true;
                break;
              }
              let status: "conf" | "Nconf" | "NA" | "" = "";
              if ((el.nota || el.nota === 0) && (el.threshold || el.threshold === 0)) {
                if (el.nota === -1) status = "NA";
                else if (el.nota <= el.threshold) status = "Nconf";
                else status = "conf";
              }
              switch (status) {
                case "NA":
                  if (el.obrigatoriedade?.NA?.img && !el?.img?.length)
                    return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                  if (el.obrigatoriedade?.NA?.ocorrencia && !el?.ocorrencia?.length)
                    return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                  break;
                case "Nconf":
                  if (el.obrigatoriedade?.naoConforme?.img && !el.img?.length)
                    return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                  if (el.obrigatoriedade?.naoConforme?.ocorrencia && !el.ocorrencia?.length)
                    return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                  break;
                case "conf":
                  if (el.obrigatoriedade?.conforme?.img && !el.img?.length)
                    return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                  if (el.obrigatoriedade?.conforme?.ocorrencia && !el.ocorrencia?.length)
                    return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
        }
      }
    } else {
      for (const el of element?.subitens) {
        if (el.chamadosConfig) {
          if (el.chamadosConfig.chamadoObrigatorio === "sempre" && (!el.chamados || el.chamados?.length === 0)) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              chamado: true,
              texto: "",
              itemId: el.itemid,
              subItemId: el.id,
            };
            break;
          }
        }
        if (el.chamados) {
          if (el.chamados.some((elem) => !elem.textChamado || !elem.textChamado?.length)) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `${t('Filling.checkMandatory.ticketWithMissingMessageInItem')} "${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
              itemId: el.itemid,
              subItemId: el.id,
            };
          }
          if (el.chamados.some((elem) => !elem.dateChamado || elem.dateChamado === undefined)) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `${t('Filling.checkMandatory.ticketWithoutDateInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
              itemId: el.itemid,
              subItemId: el.id,
            };
          }
          break;
        }
        if (el.tipo !== "avaliacao" && el.tipo !== "email") {
          if (
            el.ocorrencia ||
            el.assinatura ||
            el?.selecionados?.some((e) => e === true) ||
            el?.selecionados?.some((e) => {
              const element: { texto: string } = e as {
                texto: string;
              };
              return element?.texto?.length;
            })
          ) {
            if ((!el.img || el.img?.length === 0) && el?.obrigatoriedade?.img) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                itemId: el.itemid,
                subItemId: el.id,
              };
              break;
            }
          }
        }
        if (el.tipo !== "avaliacao" && el.tipo !== "email") {
          if (
            el.ocorrencia ||
            el.assinatura ||
            el?.selecionados?.some((e) => e === true) ||
            el?.selecionados?.some((e) => {
              const element: { texto?: string } = e as {
                texto?: string;
              };
              return element?.texto?.length;
            })
          ) {
            if ((!el.obs || el.obs?.length <= 0) && el?.obrigatoriedade?.ocorrencia) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                itemId: el.itemid,
                subItemId: el.id,
              };
              break;
            }
          }
        }
        switch (el.tipo) {
          case "data":
          case "hora":
            if (el.obrigatoriedade?.preenchimento && !el.ocorrencia) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (el.ocorrencia) {
              el.checked = true;
            }
            break;
          case "textoCurto":
          case "textoLongo":
            if (el.obrigatoriedade?.preenchimento && !el.ocorrencia?.length) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (el.ocorrencia?.length > 0) {
              el.checked = true;
            }
            break;
          case "list":
            if (el.selecionados) {
              if (el.obrigatoriedade?.preenchimento || (el?.selecionados?.length > 1 && el.selecionados?.some((e: any) => !e?.texto?.length))) {
                el?.selecionados?.some((e) => {
                  const element: { texto: string } = e as { texto: string };
                  return element?.texto === "";
                });
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
            }
            if (el.selecionados) {
              el?.selecionados?.some((e) => {
                const element: { texto: string } = e as { texto: string };
                const elementNotChecked = element?.texto?.length <= 0;
                if (!elementNotChecked) {
                  el.checked = true;
                }
                return elementNotChecked;
              });
            }
            break;
          case "assinatura":
            if (el.obrigatoriedade?.preenchimento) {
              if (!el.assinatura || el.assinatura?.length === 0) {
                return {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  itemId: el.itemid,
                  subItemId: el.id,
                };
              }
              el.checked = true;
            } else if (el.assinatura && el.assinatura?.length > 0) {
              el.checked = true;
            }
            break;
          case "email":
            if (el?.ocorrencia?.length > 0 && !regexEmail.test(el.ocorrencia)) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (el.obrigatoriedade?.preenchimento && !el?.ocorrencia?.length) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `${t('Filling.checkMandatory.mandatoryFieldInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (el.ocorrencia !== "" && regexEmail.test(el.ocorrencia)) {
              el.checked = true;
            }
            break;
          case "cpfcnpj":
            if (el?.ocorrencia?.length > 0 && ![14, 18].includes(el.ocorrencia?.length)) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if ([14, 18].includes(el.ocorrencia?.length)) {
              el.checked = true;
            }
            break;
          case "rg":
            if (el?.ocorrencia?.length > 0 && el.ocorrencia?.length !== 13) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            break;
          case "placa":
            if (el?.ocorrencia?.length > 0 && el.ocorrencia?.length !== 8) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (el.ocorrencia?.length === 8) {
              el.checked = true;
            }
            break;
          case "telefone":
            if (el?.ocorrencia?.length > 0 && el.ocorrencia.length < 12) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (el.ocorrencia?.length >= 12) {
              el.checked = true;
            }
            break;
          case "cep":
            if (el?.ocorrencia?.length > 0 && el.ocorrencia?.length < 9) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (el.ocorrencia?.length === 9) {
              el.checked = true;
            }
            break;
          case "numero":
            if (el.obrigatoriedade?.preenchimento && !el.ocorrencia) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `item "${element.nome}", subitem "${el.nome}" sem preenchimento`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if ((el?.ocorrencia > Number(el.maxNumberVal)) || (el?.ocorrencia < Number(el.minNumberVal))) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `${t('Filling.checkMandatory.incorrectValueInItem')}${element.nome}${t('Filling.checkMandatory.subItem')}${el.nome}"`,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (el.ocorrencia) {
              el.checked = true;
            }
            break;
          case "multiple":
          case "unique":
            if (el.obrigatoriedade?.preenchimento && (!el?.selecionados?.length || !el?.selecionados.some((e) => e === true))) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (el.selecionados && el.selecionados?.length > 0 && el.selecionados.some((e) => e)) {
              el.checked = true;
            }
            break;
          case "avaliacao":
            if (typeof el.nota !== "number" && el.obrigatoriedade?.preenchimento) {
              return {
                success: false,
                item: element.nome,
                subItem: el.nome,
                itemId: el.itemid,
                subItemId: el.id,
              };
            }
            if (typeof el.nota === "number") {
              el.checked = true;
              break;
            }
            let status: "conf" | "Nconf" | "NA" | "" = "";
            if ((el.nota || el.nota === 0) && (el.threshold || el.threshold === 0)) {
              if (el.nota === -1) status = "NA";
              else if (el.nota <= el.threshold) status = "Nconf";
              else status = "conf";
            }
            switch (status) {
              case "NA":
                if (el.obrigatoriedade?.NA?.img && !el?.img?.length)
                  return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                if (el.obrigatoriedade?.NA?.ocorrencia && !el?.ocorrencia?.length)
                  return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                break;
              case "Nconf":
                if (el.obrigatoriedade?.naoConforme?.img && !el.img?.length)
                  return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                if (el.obrigatoriedade?.naoConforme?.ocorrencia && !el.ocorrencia?.length)
                  return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                break;
              case "conf":
                if (el.obrigatoriedade?.conforme?.img && !el.img?.length)
                  return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                if (el.obrigatoriedade?.conforme?.ocorrencia && !el.ocorrencia?.length)
                  return { success: false, item: element.nome, subItem: el.nome, itemId: el.itemid, subItemId: el.id };
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
    }
  }
  if (checklist.chamadosConfig) {
    if (checklist.chamadosConfig.chamadoObrigatorio && (!checklist.chamados || !checklist.chamados?.length)) {
      return {
        success: false,
        item: "chamado",
        subItem: "",
      };
    }
    if (checklist.chamados && checklist?.chamados.some((elem: ChamadoChecklist) => !elem.textChamado || !elem.textChamado?.length)) {
      return {
        success: false,
        item: "",
        subItem: "",
        texto: `${t('Filling.checkMandatory.formTicketWithMissingMessage')}`,
      };
    }
    if (checklist.chamados && checklist?.chamados.some((elem: ChamadoChecklist) => !elem.dateChamado || elem.dateChamado === undefined)) {
      return {
        success: false,
        item: "",
        subItem: "",
        texto: `${t('Filling.checkMandatory.mandatoryDateInFormTicket')}`,
      };
    }
  }
  return result;
}
