import { useContext, useEffect } from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import { UserContext } from '../context/UserContext';
import ChangeAlert from '../components/ChangeAlert/ChangeAlert';
import Header from '../components/Header/Header';
import { HeaderText } from '../StyledComponents/pages/pages';
import PlanoDeAcaoTabs from '../components/PlanoDeAcao/PlanoDeAcaoTabs';
import Footer from '../components/Footer/Footer';
import { Container, SubContainer } from './Styles';
import { trackEventMatomoVisit } from '../helpers/matomo';
import { useTranslation } from 'react-i18next';

export default function PlanoDeAcao() {
  const { changed } = useContext(UserContext);
  const { t } = useTranslation('translation');
  useEffect(() => {
    trackEventMatomoVisit('Plano de ação')
  }, [])

  return (
    <Container>
      <Sidebar />
      <SubContainer>
        {changed && <ChangeAlert />}
        <Header />
        <HeaderText style={{ textAlign: 'center' }}>{t('ActionPlan.actionPlans')}</HeaderText>
        <div style={{
          marginBottom: '50px',
          padding: `${window.innerWidth < 800 ? '0px 2% 0px 2%' : '0px 10% 0px 10%'}`,
        }}>
          <PlanoDeAcaoTabs />
        </div>
        <Footer />
      </SubContainer>
    </Container>
  )
}
