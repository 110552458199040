import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Style from './style/PasswordStyle';
import { UserContext } from '../../context/UserContext';
import callAssociateAll from '../../helpers/callAssociateAll';
import Companies from '../../service/Companies';
import { toast } from 'react-toastify';
import AttToken from '../../helpers/attToken';
import IUserData from '../../interface/IUserData';
import { trackEventMatomo } from '../../helpers/matomo';

export default function AdmData() {
  const { t } = useTranslation('translation');
  const { changed, setChanged, acessoPlanoDeAcao, refetchAccessPlan } = useContext(UserContext);
  const [disable, setDisable] = useState(false);
  const [userData, setUserData] = useState<IUserData>()
  const companieService = useMemo(() => new Companies(), []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userDataLocalStr = localStorage.getItem('userDataLocal');
    if (userDataLocalStr) {
      const userDataLocal: IUserData = JSON.parse(userDataLocalStr);
      setUserData(userDataLocal);
    }
  }, []);

  const migraDesmigra = async (migra: boolean) => {
    setLoading(true);
    toast.warn(`${t('settingsPage.AdmData.changingUsageOfTicketsAndNotifications')}`)
    const token = await AttToken();
    if (token) {
      await companieService.ligaDesligaMigracao(token, migra);
      setLoading(false);
      toast.success(`${t('settingsPage.AdmData.ticketsAndNotificationsChanged')}`)
    }
  }

  const changeAccessPlan = async (planoDeAcao: boolean) => {
    setLoading(true);
    await toast.promise(async () => {
      const token = await AttToken();
      if (token) {
        const message: { message: string } = await companieService.concedeRetiraAcessoPlanoDeAcao(token, planoDeAcao);
        await refetchAccessPlan();
        return message
      } else {
        throw new Error('Erro')
      }
    }, {
      pending: `${planoDeAcao ? t('settingsPage.AdmData.grantingAccess') : t('settingsPage.AdmData.denyingAccess')}`,
      success: `${planoDeAcao ? t('settingsPage.AdmData.actionPlansAccessGranted') : t('settingsPage.AdmData.actionPlansAccessDenied')}`,
      error: t('settingsPage.AdmData.failedToGrantAccess')
    })
    setLoading(false);
  }

  return (
    <div style={{ width: '100', display: 'flex', justifyContent: 'center' }}>
      <Style.ProfileContainer>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <button
            style={{ marginRight: '2em' }}
            className="button is-success is-small"
            onClick={async () => {
              trackEventMatomo('tela de configurações', 'click', 'button', 'atualiza dados')
              setDisable(true)
              const token = await AttToken();
              if (token) {
                toast.warn(`${t('atualizando')}`);
                try {
                  await companieService.atualizar(token);
                  toast.success(`${t('settingsPage.AdmData.dataUpdated')}`);
                } catch (err) {
                  console.log(err)
                }
              }
              setDisable(false);
            }}
            disabled={disable}
          >
            {t('settingsPage.data.att')}
          </button>
          <span style={{ marginTop: '10px' }}>{t('settingsPage.data.alert')}</span>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            paddingTop: '10px',
            borderTop: '1px solid lightgray',
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
          <button
            style={{ marginRight: '2em', alignSelf: 'center' }}
            className="button is-success is-small"
            onClick={() => {
              trackEventMatomo('tela de configurações', 'click', 'button', 'associa serviços')
              localStorage.setItem('changedHirarquia', 'false')
              setChanged(false)
              callAssociateAll()
            }}
            disabled={!changed}
          >
            {t('settingsPage.data.Associate')}
          </button>
          {changed ? (
            <span style={{ marginTop: '10px' }}>
              {t('settingsPage.data.pressBtn')}
            </span>
          ) : (<span style={{ marginTop: '10px' }}>{t('settingsPage.data.NenhumaMudanca')}</span>)}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            paddingTop: '10px',
            borderTop: '1px solid lightgray',
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
          {process.env.REACT_APP_PROJECT_NAME !== 'Axyma Forms' && <>
            {userData?.migrado
              ? <button
                style={{ marginRight: '2em', alignSelf: 'center' }}
                className="button is-success is-small"
                disabled={loading}
                onClick={() => {
                  trackEventMatomo('tela de configurações', 'click', 'button', 'migra chamados')
                  setUserData({ ...userData, migrado: false })
                  localStorage.setItem('userDataLocal', JSON.stringify({ ...userData, migrado: false }))
                  migraDesmigra(false)
                }}
              >
                {t('settingsPage.AdmData.returnToLegacyTicketsAndNotifications')}
              </button>
              : userData && <button
                style={{ marginRight: '2em', alignSelf: 'center' }}
                className="button is-success is-small"
                disabled={loading}
                onClick={() => {
                  trackEventMatomo('tela de configurações', 'click', 'button', 'migra chamados')
                  setUserData({ ...userData, migrado: true })
                  localStorage.setItem('userDataLocal', JSON.stringify({ ...userData, migrado: true }))
                  migraDesmigra(true)
                }}
              >
                {t('settingsPage.AdmData.useTicketsAndNotifications2_0')}
              </button>}
          </>}

          {process.env.REACT_APP_PROJECT_NAME !== 'Axyma Forms' && <>
            {acessoPlanoDeAcao
              ? <button
                style={{ marginRight: '2em', alignSelf: 'center' }}
                className="button is-success is-small"
                disabled={loading}
                onClick={() => {
                  trackEventMatomo('tela de configurações', 'click', 'button', 'acesso planos de ação')
                  changeAccessPlan(false)
                }}
              >
                {t('settingsPage.AdmData.denyActionPlanAccessForThisCompany_HOMOLOnly')}
              </button> : userData && <button
                style={{ marginRight: '2em', alignSelf: 'center' }}
                className="button is-success is-small"
                disabled={loading}
                onClick={() => {
                  trackEventMatomo('tela de configurações', 'click', 'button', 'acesso planos de ação')
                  changeAccessPlan(true)
                }}
              >
                {t('settingsPage.AdmData.grantActionPlanAccessForThisCompany_HOMOLOnly')}
              </button>}
          </>}
        </div>
      </Style.ProfileContainer>
    </div>
  )
}