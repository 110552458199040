import { useTranslation } from "react-i18next"
import { trackEventMatomo } from "../../../../../helpers/matomo"

type props = {
  cancel: boolean,
  setCancel: Function,
  setOpenModal: Function,
  clear: Function
}

export default function ModalCancel({ cancel, setCancel, setOpenModal, clear }: props) {
const { t } = useTranslation('translation');
  return (
    <div className={`${cancel ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="modal-card">
        <section style={{ height: '10%', width: '50%' }} className="modal-card-body">
          <b>
          {t('Filling.fillSubitens.attention')}<br />
          </b>
          {t('Filling.fillSubitens.cancelSignatureConfirmation')}
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', color: '#A400CF', fontWeight: '700', height: '50%' }}>
            <span onClick={() => {
              trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'cancelar')
              setCancel(false)
            }} >{t('Filling.fillSubitens.no')}</span><span onClick={() => {
              trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'salvar')
              setCancel(true); setOpenModal(false); clear()
            }}>{t('Filling.fillSubitens.yes')}</span>
          </div>
        </section>
      </div>
    </div>
  )
}
