import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Box, Container, InputLogin, SmallText, UnderLogoText } from '../components/FormLogin/FormLoginStyle';
import Logo from "../assets/images/logo-login.png";
import Loading from '../components/Loading/Loading';
import Login from '../service/Login';
import AxymaLogo from '../assets/images/Axyma_branco.svg';
import { RiErrorWarningFill } from 'react-icons/ri';
import { trackEventMatomoVisit } from '../helpers/matomo';

const loginService = new Login();

export default function Recuperacao() {
  const [loading, setLoading] = useState(false);
  const [inputType, setInputType] = useState("password");
  const toggleEye = () => inputType === "password" ? setInputType("text") : setInputType("password")
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState<string>('');

  useEffect(() => {
    trackEventMatomoVisit('Recuperação de senha')
  }, []) 

  const validatePassword = () => {
    if (password !== validPassword) {
      return (
        <>
          <span style={{ 
            color: 'white', 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            margin:0,
            padding:0,
            alignItems:'center'
            }}>{t('users.userRegister.passwordWarning')}<RiErrorWarningFill /></span>
        </>
      )
    } else return (<div style={{height:'22px'}}></div>)
  }

  const { t } = useTranslation('translation');

  const { uid } = useParams();

  const now = new Date();

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const toastId = toast.loading(`${t('loginScreen.Recuperacao.resettingPassword')}`, { isLoading: true });
    setLoading(true);

    const result = await loginService.resetPassword({ uid: uid as string, password });
    if (!result) {
      toast.error(`${t('loginScreen.Recuperacao.errorChangingPassword')}`)
    } else if (result.success) {
      toast.success(`${t('loginScreen.Recuperacao.passwordChangedSuccessfully')}`);
      setPassword('');
      navigate('/login');
    } else {
      toast.error(result.message)
    }
    setLoading(false);
    toast.dismiss(toastId);
    return result;
  }

  return loading ? (<div style={{
    backgroundColor: '#3a1c64',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  }}>
    <img src={AxymaLogo} alt="logo axyma branco" width={'40%'} />
    <Loading />
  </div>) : (
    <Container className="container is-fluid">
      <ToastContainer />
      <Box className="box">
        <div className="img">
          <img alt="Axyma" src={Logo} />
        </div>
        <UnderLogoText>Alterar Senha</UnderLogoText>
        <form onSubmit={handleSubmit} autoComplete="off" style={{ width: '100%' }}>
          {inputType === "password" ? (
            <AiOutlineEye onClick={toggleEye} className="toggle" />
          ) : (
            <AiOutlineEyeInvisible onClick={toggleEye} className="toggle" />
          )}
          <InputLogin
            required
            className="input is-small"
            type={inputType}
            placeholder={t('loginScreen.password')}
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value.trim())}
          />
          <input
            autoComplete='new-password'
            type={inputType}
            className="input is-small is-fullwidth" value={validPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValidPassword(e.target.value.trim())}
            required
          />
          {password.length > 0 &&
            validatePassword()}
          <button
            disabled={loading || (password !== validPassword)}
            style={{ marginTop: "5px" }}
            type='submit'
            className='button is-small is-fullwidth'
          >
            {t('loginScreen.Recuperacao.change')}
          </button>
        </form>
        <SmallText>Axyma @ {now.getFullYear()}</SmallText>
      </Box>
    </Container>
  )
}
